
import React, { useState } from 'react';
import Navbar from '../components/Home/Navbar/Navbar';
import Footer from '../components/Home/footer/Footer';

const FAQ = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const faqData = [
    { question: 'What is Starskills?', answer: 'Starskills is a platform for developing skills with ease and serenity.' },
    { question: 'How do I get started?', answer: 'You can get started by signing up for a free account on our website.' },
    // Add more FAQ items as needed
  ];

  const filteredFaq = faqData.filter((faq) =>
    faq.question.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleQuestionClick = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <>
    <Navbar/>
    <div className="max-w-6xl mx-auto mt-32 mb-28 my-8">
      <div className="mb-4">
        <h1 className="text-4xl text-center font-semibold mb-4">FAQ</h1>
        <p className="text-gray-600 text-center" >Have a question? Check out our frequently asked questions.</p>
      </div>

      <div className="mb-6">
        <input
          type="text"
          className="w-full p-2 py-2 border border-gray-500 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600"
          placeholder="Search for a question..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {filteredFaq.length === 0 && <p>No matching questions found.</p>}

      {filteredFaq.map((faq, index) => (
        <div key={index} className="mb-4">
          <div
            className="cursor-pointer flex justify-between items-center p-2 border border-gray-300 rounded-md"
            onClick={() => handleQuestionClick(index)}
          >
            <h2 className="text-xl ">{faq.question}</h2>
            {selectedQuestion === index ? '-' : '+'}
          </div>
          {selectedQuestion === index && <p className="mt-2">{faq.answer}</p>}
        </div>
        
      ))}
   
   </div>
    <Footer/>
    </> 
)};

export default FAQ;
