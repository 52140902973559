import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faRuler,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-tailwind/react";

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
const StepOne = ({ onNextStep, onSelectedEvaluation, onHasAutoEvaluation }) => {
  const [selectedEvaluation, setSelectedEvaluation] = useState("");
  const [isAutoEvaluation, setIsAutoEvaluation] = useState(false);

  const handleOptionChange = (option) => {
    setSelectedEvaluation(option);
  };

  const handleCheckboxChange = () => {
    const newValue = !isAutoEvaluation;
    setIsAutoEvaluation(newValue);
    onHasAutoEvaluation(newValue);
  };
  const handleNextStep = () => {
    onSelectedEvaluation(selectedEvaluation);
    onHasAutoEvaluation(isAutoEvaluation);
    onNextStep();
  };

  return (
    <div>
      <div>
        <div className="text-center text-black font-bold mb-4">
          1- Choisir le type D'evaluation{" "}
          <span className="text-red-500">*</span>
        </div>

        <div className="flex flex-wrap gap-8 justify-center">
          <div
            onClick={() => handleOptionChange("observation")}
            className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-orange-400 active:scale-95 ${
              selectedEvaluation === "observation"
                ? "border-orange-500 bg-orange-400"
                : "bg-white"
            }`}
            style={{ width: "200px", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faEye} size="2x" />
            <p className="text-black text-center font-semibold mt-2 sm:mt-4">
              observation
            </p>
          </div>
          <div
            onClick={() => handleOptionChange("echange")}
            className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-purple-400 active:scale-95 ${
              selectedEvaluation === "echange"
                ? "border-purple-400 bg-purple-400"
                : "bg-white"
            }`}
            style={{ width: "200px", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faExchangeAlt} size="2x" />
            <p className="text-black text-center font-semibold mt-2 sm:mt-4">
              Echange
            </p>
          </div>
          <div
            onClick={() => handleOptionChange("mesure")}
            className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-green-400 active:scale-95 ${
              selectedEvaluation === "mesure"
                ? "border-green-400 bg-green-400"
                : "bg-white"
            }`}
            style={{ width: "200px", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faRuler} size="2x" />
            <p className="text-black text-center font-semibold mt-2 sm:mt-4">
              Mesure
            </p>
          </div>
        </div>
        <div className="text-center text-black border-t border-2 border-t-black pt-4 font-bold mt-8 mb-4">
          {" "}
          2- Inclure Auto Evaluation ?
        </div>
        <div className="flex items-center text-center justify-center  ">
          <input
            type="checkbox"
            className="text-xl"
            id="checkbox"
            AutoEvalution={isAutoEvaluation}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="checkbox" className="ml-2 text-xl">
            Auto Evaluation
          </label>
        </div>
        <div className="flex justify-center mt-8">
          <Button
            color="gray"
            disabled={!selectedEvaluation}
            onClick={handleNextStep}
          >
            Suivant
            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
