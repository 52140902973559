import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import controlImage from "../../../../../../assets/das/control.png";
import logoImage from "../../../../../../assets/images/STARSKILLS 01111.png";
import menuData from "./sidebar_menu_items";
import avatar from "../../../../../../assets/avatars/avatar12.png";
import AuthContext from "../../../../../../context/AuthContext";
import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const TeacherSidebar = ({
  open,
  setOpen,
  selectedMenuItem,
  setSelectedMenuItem,
  openSubMenu,
  setOpenSubMenu,
  niveau,
  isCompagneManager,
  user,
}) => {
  const menus = menuData(niveau);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const { auth, logout } = useContext(AuthContext);

  useEffect(() => {
    if (auth) {
      setFirstName(auth.first_name);
      setLastName(auth.last_name);
    }
  }, [auth]);

  const filteredMenus = menus.filter(
    (menu) => !(!isCompagneManager() && menu.id === 3)
  );

  const handleMenuClick = (menuId, hasSubmenu) => {
    setSelectedMenuItem(menuId);
    if (hasSubmenu) {
      setOpenSubMenu(openSubMenu === menuId ? null : menuId);
    } else {
      setOpenSubMenu(null);
    }
  };

  return (
    <div
      className={`${
        open ? "w-50" : "w-20"
      } bg-white  h-auto p-1 shadow-lg pt-8 relative duration-300`}
    >
      <img
        src={controlImage}
        className={`absolute cursor-pointer -right-3 top-9 w-7 border-gray-400
        border-2 rounded-full ${!open && "rotate-180"}`}
        onClick={() => setOpen(!open)}
        alt="Control"
      />
      <div className="flex flex-col items-center mb-4">
        <img
          src={logoImage}
          className={`cursor-pointer duration 50 ${
            open ? "w-16 h-16" : "w-10 h-10"
          }`}
          alt="Logo"
        />
        <Link
          to="/"
          className={`text-black origin-left font-medium text-xl duration-50 ${
            !open && "scale-0"
          }`}
        ></Link>
      </div>

      <div className="flex flex-col border-t-2 pt-2 items-center">
        <img
          src={avatar}
          className={`rounded-full ${open ? "w-14 h-14" : "w-10 h-10"}`}
          alt="User Avatar"
        />
        <p
          className={`text-light-blue-600  origin-left font-bold text-base duration-50  ${
            !open && "scale-0"
          }`}
        >
          {firstName} {lastName}
        </p>
      </div>
      <button className="bg-orange-500 text-white p-2 rounded-lg w-full my-4 hover:bg-blue-600">
        <FontAwesomeIcon icon={faPlusCircle} className="mx-2" />
        Lancer Starskills
      </button>
      <ul className="border-t-2	">
        {filteredMenus.map((menu) => (
          <Link to={`/teacher_dashboard/${menu.path}`} key={menu.id}>
            <Fragment>
              <li
                className={`flex items-center p-2 py-3 cursor-pointer ${
                  selectedMenuItem === menu.id
                    ? "text-blue-500 "
                    : "hover:bg-gray-200 text-gray-700 font-normal"
                } text-sm mt-4 font-semibold`}
                onClick={() => handleMenuClick(menu.id, !!menu.submenu)}
              >
                <span className="flex items-center gap-2">
                  <FontAwesomeIcon
                    icon={menu.icon}
                    className={`w-5 h-5 ${
                      selectedMenuItem === menu.id
                        ? "text-blue-500"
                        : "text-gray-500"
                    }`}
                  />
                  <span
                    className={`${!open && "hidden"} origin-left duration-200`}
                  >
                    {menu.title}
                  </span>
                  {menu.submenu && <ChevronDownIcon className="w-5 h-5" />}
                </span>
              </li>
              {menu.submenu && menu.id === openSubMenu && (
                <ul className="ml-4 mt-2 text-sm overflow-y-auto max-h-64">
                  {menu.submenu.map((submenuItem) => (
                    <NavLink
                      exact
                      to={
                        menu.title === "Mes évaluations"
                          ? `/teacher_dashboard/${submenuItem.path}`
                          : `/teacher_dashboard/${submenuItem.path}/${submenuItem.id}`
                      }
                      key={submenuItem.id}
                      className={({ isActive }) =>
                        isActive
                          ? "text-blue-600 bg-gray-300 font-bold"
                          : "bg-white text-gray-600"
                      }
                    >
                      <li
                        className={`p-2 list-inside list-disc hover:bg-gray-300 ${
                          !open && "hidden"
                        }`}
                      >
                        {submenuItem.title}
                      </li>
                    </NavLink>
                  ))}
                </ul>
              )}
            </Fragment>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default TeacherSidebar;
