import React, { useState } from "react";
import realiser0 from "../../../assets/images/realiser0.svg";
import realiser1 from "../../../assets/images/realiser1.svg";
import realiser2 from "../../../assets/images/realiser2.svg";
import realiser3 from "../../../assets/images/realiser3.svg";
import right_img from "../../../assets/images/realiser-right1.svg";
import drowq from "../../../assets/images/drow.png";

const Realisation = () => {
  const [activeTitle, setActiveTitle] = useState(0);

  const titles = [
    "Référentiels automatisés",
    "Quiz automatisé et intégrables",
    "Quiz automatisé et intégrables",
    "Extraire des données individuelles et/ou collectives",
  ];

  const content = [
    {
      leftImage: realiser0,
      rightImage: right_img,
    },
    {
      leftImage: realiser1,
      rightImage: right_img,
    },
    {
      leftImage: realiser2,
      rightImage: right_img,
    },
    {
      leftImage: realiser3,
      rightImage: right_img,
    },
  ];

  const handleTitleClick = (index) => {
    setActiveTitle(index);
  };

  return (
    <div
      className="max-w-screen-xxl mx-auto py-16 p-4 mt-10"
      style={{
        background:
          " linear-gradient(92.22deg, #9434F5 7.43%, #110912 115.28%)",
      }}
    >
      <h3 className="text-3xl text-center ml-16 mb-8 mt-8 font-bold leading-7 text-white">
        Qu’allez réaliser – vous avec starskills ?
      </h3>
      <div className="flex flex-wrap justify-center max-w-screen-xxl mx-auto">
        {titles.map((title, index) => (
          <h2
            key={index}
            onClick={() => handleTitleClick(index)}
            className={`py-2 px-1 ml-4 border-b-2 text-sm  ${
              index === activeTitle
                ? "text-yellow-800 font-bold "
                : " text-white "
            } cursor-pointer  hover:text-yellow-800 hover:text-bold mb-2 md:mb-0 md:w-2/9 mr-2 mt-2 `}
          >
            {title}
          </h2>
        ))}
      </div>
      <div className="mt-5 flex justify-center flex-wrap sm:mt-4">
        <img
          src={drowq}
          alt="Right Image"
          className="w-1/6  lg:block hidden"
          style={{ height: "350px", width: "250px" }}
        />
        <img
          src={content[activeTitle].leftImage}
          alt="Left Image"
          className="w-1/6 mt-4 mx-8 lg:mx-6"
          style={{ height: "250px", width: "500px" }}
        />
        <img
          src={right_img}
          alt="Right Image"
          className="w-1/6 "
          style={{ height: "350px", width: "350px" }}
        />
      </div>
    </div>
  );
};

export default Realisation;
