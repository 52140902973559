import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "@mui/material/Slider";
import avatar1 from "../../../../../../assets/avatars/avatar1.png";
import avatar2 from "../../../../../../assets/avatars/avatar9.png";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import authService from "../../../../../../context/authService";

const EvaluationEchange = () => {
  const { evaluationId } = useParams();

  useEffect(() => {
    fetchEvaluation(evaluationId);
  }, [evaluationId]);

  const fetchEvaluation = async (evaluationId) => {
    try {
      const response = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation/${evaluationId}`,
        "GET"
      );
      const evaluation = response.data.List_Evaluation;
      const evsList = evaluation.join(",");
      const apiUrlEva = `https://api.starskills.fr/items/Evaluation_Student?filter={"id":{"_in":"${evsList}"}}`;
      const responseEva = await authService.makeAuthenticatedRequest(
        apiUrlEva,
        "GET"
      );
      const enrichedEvaluation = await Promise.all(responseEva.data.map(async (ev) => {
        const studentResponse = await authService.makeAuthenticatedRequest(
          `https://api.starskills.fr/users/${ev.Student_id}`,
          "GET"
        );
        const subSkillResponse = await authService.makeAuthenticatedRequest(
          `https://api.starskills.fr/items/SubSkill/${ev.SubSkill}`,
          "GET"
        );
  
        return {
          ...ev,
          first_name: studentResponse.data.first_name,
          last_name: studentResponse.data.last_name,
          SubSkill_name: subSkillResponse.data.Name
        };
      }));
  
      console.log(enrichedEvaluation);
    } catch (error) {
      console.error("Erreur lors de la récupération de la evaluation :", error);
    }
  };

  const student = {
    id: 1,
    name: "Lena Cooper",
    avatar: avatar2,
    skills: [
      { name: "Réaliser un tableau excel", mark: 70 },
      { name: "Réaliser des tableaux croisés", mark: 80 },
      { name: "Appliquer des formules", mark: 90 },
      { name: "Réaliser des tri", mark: 75 },
      { name: "Nettoyer des données pour excel", mark: 85 },
      { name: "Réaliser une régression logistique", mark: 65 },
      { name: "Interpréter une régression", mark: 80 },
      { name: "Calculer avec une ACP", mark: 75 },
    ],
  };
  const teacher = {
    name: "Johna Alexic",
    avatar: avatar1,
  };

  const marks = Array.from({ length: 11 }, (_, index) => ({
    value: index * 10,
    label: `${index * 10}`,
  }));

  const valuetext = (value) => `${value}`;

  return (
    <div className="container mx-auto text-center mt-4 ">
      <h1 className="text-2xl font-bold mb-2  text-white bg-indigo-600 inline-block px-8 py-2 rounded-3xl">
        Echange
      </h1>
      <p className="text-sm mb-4 ">
        Sentiment- d’auto efficacité, diagnostic de compétences, en démarrage de
        processus pédagogique (plusieurs élèves)
      </p>
      {/* Student's Information */}
      <div className="container mx-auto text-center mt-4 flex">
        <div className="w-1/2 mr-4">
          <div className="border bg-gray-200 p-4 mb-4">
            <div className="flex items-center mb-4">
              <img
                src={student.avatar}
                alt={student.name}
                className="w-20 h-20 rounded-full mr-4"
              />
              <h2 className="text-lg font-semibold">{student.name}</h2>
            </div>
            {student.skills.map((skill, index) => (
              <div key={index} className="flex items-center mb-4">
                <p
                  className="mr-4 text-sm bg-black text-white p-2 py-3 rounded"
                  style={{ width: "220px" }}
                >
                  {skill.name}:
                </p>
                <Slider
                  defaultValue={skill.mark}
                  getAriaValueText={valuetext}
                  aria-labelledby={`discrete-slider-${index}`}
                  valueLabelDisplay="auto"
                  step={10}
                  marks={marks}
                  disabled
                  style={{ width: 270 }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Teacher's Information */}
        <div className="w-1/2 ml-4 flex justify-end ">
          <div className="border bg-gray-200 p-4 mb-4">
            <div className="flex  justify-end items-center mb-4">
              <img
                src={teacher.avatar}
                alt={teacher.name}
                className="w-20 h-20 rounded-full mr-4"
              />
              <h2 className="text-lg font-semibold">mr(s){teacher.name}</h2>
            </div>
            {student.skills.map((skill, index) => (
              <div key={index} className="flex items-center mb-4">
                <Slider
                  defaultValue={skill.mark}
                  getAriaValueText={valuetext}
                  aria-labelledby={`discrete-slider-${index}`}
                  valueLabelDisplay="auto"
                  step={10}
                  marks={marks}
                  disabled
                  dir="rtl"
                  style={{ width: 270 }}
                />
                <p
                  className="ml-4 text-sm bg-black text-white p-2 py-3 rounded"
                  style={{ width: "220px" }}
                >
                  {skill.name}:
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-full mr-4 flex items-center">
          <FontAwesomeIcon icon={faCheck} className="mr-2" />
          Co-validation
        </button>
      </div>
    </div>
  );
};

export default EvaluationEchange;
