// sendEmail.js
import axios from "axios";

const sendEmail = async (recipientEmail, subject, textContent, htmlContent) => {
  const sendgridApiKey =
    "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";
  const url = "https://api.sendgrid.com/v3/mail/send";

  const data = {
    personalizations: [
      {
        to: [
          {
            email: recipientEmail,
          },
        ],
        subject: subject,
      },
    ],
    from: {
      email: "starskillseducationgroup@gmail.com",
      name: "Starskills",
    },
    content: [
      {
        type: "text/plain",
        value: textContent,
      },
      {
        type: "text/html",
        value: htmlContent,
      },
    ],
  };

  const config = {
    headers: {
      Authorization: `Bearer ${sendgridApiKey}`,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(url, data, config);
    console.log("Email sent successfully:", response.data);
  } catch (error) {
    console.error(
      "Error sending email:",
      error.response ? error.response.data : error.message
    );
  }
};

export default sendEmail;
