import React, { useState, useEffect } from "react";
import QuizBox from "./quizBox";

const Quize = () => {
  const [showModal, setShowModal] = useState(false);
  const [questionList, setQuestionList] = useState([]);


  useEffect(() => {
    const staticQuestionLists = [
      [
        {
          question: "What is your favorite color?",
          options: ["Red", "Green", "Blue", "Yellow"],
          correctAnswer: "Green",
        },
        {
          question: "What is the capital of France?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Paris",
        }
      ],
      [
        {
          question: "What is the capital of Germany?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Berlin",
        },
        {
          question: "What is the capital of Italy?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Rome",
        }
      ],
      [
        {
          question: "What is the capital of Spain?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Madrid",
        },
        {
          question: "What is the capital of Japan?",
          options: ["Tokyo", "Beijing", "Seoul", "Bangkok"],
          correctAnswer: "Tokyo",
        }
      ],
      [
        {
          question: "What is the capital of Spain?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Madrid",
        },
        {
          question: "What is the capital of Japan?",
          options: ["Tokyo", "Beijing", "Seoul", "Bangkok"],
          correctAnswer: "Tokyo",
        }
      ],
      [
        {
          question: "What is the capital of Spain?",
          options: ["Berlin", "Madrid", "Paris", "Rome"],
          correctAnswer: "Madrid",
        },
        {
          question: "What is the capital of Japan?",
          options: ["Tokyo", "Beijing", "Seoul", "Bangkok"],
          correctAnswer: "Tokyo",
        }
      ]
    ];

    setQuestionList(staticQuestionLists);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleAddQuestionToQuiz = (newQuestion) => {
    setQuestionList([...questionList, newQuestion]);
  };

  const renderContent = () => {
        return (
          <div>
            <h3 className="text-xl font-semibold mb-2">Quize Lists:</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {questionList.map((list, index) => (
                <div key={index} className="mb-4">
                  <div className="border rounded-md p-4 shadow-md flex flex-col justify-between h-full">
                    <div>
                      <h4 className="text-lg font-semibold mb-2">Question List {index + 1}:</h4>
                      {list.length === 0 ? (
                        <p>No questions available.</p>
                      ) : (
                        <QuizBox questionList={list} />
                      )}
                    </div>
                  
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
  };

  return (
    <div className="container mx-auto mt-4 text-center">
      <h1 className="text-2xl font-bold mb-2 text-white bg-purple-600 inline-block px-6 py-2 rounded-3xl">
        Mesure synchrone
      </h1>
      {renderContent()}
    </div>
  );
};

export default Quize;
