import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

const CompetenceItem = ({ competenceTitle, children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked) {
      const subSkillCheckboxes = document.querySelectorAll(
        ".sub-skill-checkbox"
      );
      subSkillCheckboxes.forEach((checkbox) => (checkbox.checked = true));
    } else {
      const subSkillCheckboxes = document.querySelectorAll(
        ".sub-skill-checkbox"
      );
      subSkillCheckboxes.forEach((checkbox) => (checkbox.checked = false));
    }
  }, [checked]);

  
  const handleArrowClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-200 rounded-md mb-2 w-full">
      <div className="bg-gray-100 px-4 py-2 flex items-center justify-between cursor-pointer">
        <span className="flex items-center">
          {/* Competence title */}
          <h2 className="text-lg text-black font-semibold">
            {competenceTitle}
          </h2>
        </span>
        <div className="flex items-center space-x-2">
          <FontAwesomeIcon
            icon={isOpen ? faAngleUp : faAngleDown}
            className="text-gray-800 ml-2"
            onClick={handleArrowClick}
          />
        </div>
      </div>
      <div
        className={`transition-all duration-300 overflow-hidden ${
          isOpen ? "max-h-40" : "max-h-0"
        }`}
      >
        <div className="p-2 py-3 text-black">
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              className: "sub-skill-checkbox",
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default CompetenceItem;
