import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faRuler,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";

const CreateEvaluationModal = ({ open, onClose, onRefreshPage, session }) => {
  const [selectedEvaluation, setselectedEvaluation] = useState("");
  const [isAutoEvalution, setIsAutoEvalution] = useState(false);

  const handleOptionChange = (option) => {
    setselectedEvaluation(option);
  };

  const handleCheckboxChange = () => {
    setIsAutoEvalution(!isAutoEvalution);
  };

  const handleSubmit = () => {
    if (session) {
      console.log("session ID:", session.id);
      console.log("Evaluation:", selectedEvaluation);
      console.log("Is Auto_evaluated ", isAutoEvalution);
    }
    // onRefreshPage();
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        handler={onClose}
        style={{ width: "90%", maxWidth: "900px" }}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.7, y: -100 },
        }}
      >
        <DialogHeader>Ajouter une Evaluation </DialogHeader>
        <DialogBody>
          <div className="text-center text-black text-gray-600 font-bold border-b mb-4">
            {session ? `Session id: ${session.id}` : null}
          </div>
          <div className="text-center text-black  font-bold mb-4">
            Choisir le type D'evaluation
          </div>
          <div className="flex flex-wrap gap-8 justify-center">
            <div
              onClick={() => handleOptionChange("observation")}
              className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-blue-400 active:scale-95 ${
                selectedEvaluation === "observation"
                  ? "border-blue-500 bg-blue-400"
                  : "bg-white"
              }`}
              style={{ width: "200px", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faEye} size="2x" />
              <p className="text-black text-center font-semibold mt-2 sm:mt-4">
                observation{" "}
              </p>
            </div>
            {/* Box 2 */}
            <div
              onClick={() => handleOptionChange("echange")}
              className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-orange-400 active:scale-95 ${
                selectedEvaluation === "Echange"
                  ? "border-orange-400 bg-orange-400"
                  : "bg-white"
              }`}
              style={{ width: "200px", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faExchangeAlt} size="2x" />
              <p className="text-black text-center font-semibold mt-2 sm:mt-4">
                Echange{" "}
              </p>
            </div>
            {/* Box 3 */}
            <div
              onClick={() => handleOptionChange("mesure")}
              className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-green-400 active:scale-95 ${
                selectedEvaluation === "Mesure"
                  ? "border-green-400 bg-green-400"
                  : "bg-white"
              }`}
              style={{ width: "200px", cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faRuler} size="2x" />
              <p className="text-black text-center font-semibold mt-2 sm:mt-4">
                Mesure
              </p>
            </div>
          </div>
          {/* Checkbox */}
          <div className="text-center text-black font-bold mt-8 mb-4">
            {" "}
            Inclure Auto Evaluation ?
          </div>
          <div className="flex items-center text-center justify-center  ">
            <input
              type="checkbox"
              className="text-xl"
              id="checkbox"
              AutoEvalution={isAutoEvalution}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="checkbox" className="ml-2 text-xl">
              Auto Evaluation
            </label>
          </div>
        </DialogBody>
        <DialogFooter>
          {/* Buttons for actions */}
          <Button onClick={onClose}>Annuler</Button>
          <Button color="green" className="mx-2" onClick={handleSubmit}>
            Soumettre
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default CreateEvaluationModal;
