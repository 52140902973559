import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import logo from "../../../../../../assets/images/STARSKILLS 01111.png";
import MyFont from "../../../../../../assets/fonts/Poppins-SemiBold.ttf";
import { blue } from "@mui/material/colors";

// Register the font
Font.register({
  family: "MyFont",
  src: MyFont,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    fontFamily: "MyFont",
    fontSize: 15,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    marginTop: 30,
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "justify",
  },
  logo: {
    width: 100,
    height: 100,
    alignSelf: "center",
    marginBottom: 20,
  },
  table: {
    display: "table",
    width: "100%",
    margin: "10px auto",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColSkill: {
    width: "85%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableColScore: {
    width: "15%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    textAlign: "center",
  },
  footerText: {
    margin: 0,
  },
  footerLink: {
    textDecoration: "underline",
    position: "absolute",
    fontSize: 12,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "blue",
  },
  aPlusPlus: {
    color: "purple",
  },
  aPlus: {
    color: "green",
  },
  a: {
    color: "blue",
  },
  pa: {
    color: "orange",
  },
  na: {
    color: "red",
  },
  barChartContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  barChart: {
    display: "flex",
    flexDirection: "column",
  },
  barRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  barLabel: {
    fontSize: 10,
    width: "30%",
  },
  bar: {
    height: 10,
    marginLeft: 5,
  },
  barWrapper: {
    width: "70%",
  },

  commentTitle: {
    fontSize: 18,
    marginBottom: 10,
    textAlign: "center",
  },
  commentBox: {
    height: 200,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    marginTop: 10,
  },
  signatureSection: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureBox: {
    height: 100,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    padding: 5,
    textAlign: "center",
  },
  signatureTitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    marginTop: 20,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const GenrateRapportPdf = () => {
  const dummyData = [
    { skill: "Communication", score: "A++" },
    { skill: "Leadership", score: "A+" },
    { skill: "Problem Solving", score: "A" },
    { skill: "Time Management", score: "PA" },
    { skill: "Technical Skills", score: "NA" },
    { skill: "Teamwork", score: "A++" },
    { skill: "Adaptability", score: "A+" },
    { skill: "Creativity", score: "A" },
    { skill: "Critical Thinking", score: "PA" },
    { skill: "Project Management", score: "NA" },
  ];

  const getColorStyle = (score) => {
    switch (score) {
      case "A++":
        return styles.aPlusPlus;
      case "A+":
        return styles.aPlus;
      case "A":
        return styles.a;
      case "PA":
        return styles.pa;
      case "NA":
        return styles.na;
      default:
        return {};
    }
  };

  const getBarWidth = (score) => {
    switch (score) {
      case "A++":
        return "100%";
      case "A+":
        return "80%";
      case "A":
        return "60%";
      case "PA":
        return "40%";
      case "NA":
        return "20%";
      default:
        return "0%";
    }
  };

  const getBarStyle = (score) => {
    switch (score) {
      case "A++":
        return styles.aPlusPlus;
      case "A+":
        return styles.aPlus;
      case "A":
        return styles.a;
      case "PA":
        return styles.pa;
      case "NA":
        return styles.na;
      default:
        return {};
    }
  };

  return (
    <Document>
      <Page style={styles.page}>
        <Text style={styles.title}>Starskills</Text>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.title}>Méthodologie</Text>
        <Text style={styles.text}>
          Le niveau d’acquisition est la moyenne des résultats liés à la
          combinaison des différents types d’évaluation (auto-évaluation /
          Co-évaluation/ Évaluation par sommation, évaluation par observation)
          et modalités (Mise en situation professionnelle, jeux de rôles, études
          de cas, quiz, travail en groupe, restitution, test de connaissance,
          réalisations pratiques, entretiens de développement, coaching, etc.)
          mis en œuvre tout au long du processus de formation.
        </Text>
        <Text style={styles.text}>
          La méthode s’ancre dans une approche de développement personnelle et
          de pédagogie positive au service de l’élévation de l’apprenant.
        </Text>
        <Text style={styles.text}>
          Les critères d’acquisition (niveau d’acquisition) des compétences
          ciblées :
        </Text>
        <Text style={styles.text}>
          - A++ Objectif surpassé (maîtrise admirable) (100%){"\n"}- A+ Objectif
          dépassé (maîtrise très satisfaisante) (80%){"\n"}- A Objectif atteint
          (maîtrise satisfaisante) (60%){"\n"}- PA Objectif partiellement
          atteint (maîtrise fragile) (40%){"\n"}- NA Objectif non atteint
          (maîtrise insuffisante) (20%)
        </Text>
        <Text style={styles.title}>Tableau des Compétences</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColSkill}>
              <Text style={styles.tableCell}>Compétence</Text>
            </View>
            <View style={styles.tableColScore}>
              <Text style={styles.tableCell}>Score</Text>
            </View>
          </View>
          {dummyData.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <View style={styles.tableColSkill}>
                <Text style={styles.tableCell}>{item.skill}</Text>
              </View>
              <View style={styles.tableColScore}>
                <Text style={[styles.tableCell, getColorStyle(item.score)]}>
                  {item.score}
                </Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={styles.title}>Vision graphique</Text>
        <Text style={styles.subtitle}>
          Évolution des compétences (initial / acquis)
        </Text>
        <View style={styles.barChartContainer}>
          <View style={styles.barChart}>
            {dummyData.map((item, index) => (
              <View key={index} style={styles.barRow}>
                <Text style={styles.barLabel}>{item.skill}</Text>
                <View
                  style={[
                    styles.barWrapper,
                    {
                      width: getBarWidth(item.score),
                      backgroundColor:
                        getColorStyle(item.score).color || "#000",
                    },
                  ]}
                >
                  <View
                    style={[
                      styles.bar,
                      getBarStyle(item.score),
                      { width: getBarWidth(item.score) },
                    ]}
                  />
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.commentSection}>
          <Text style={styles.commentTitle}>Commentaire de l’enseignant</Text>
          <View style={styles.commentBox} />
        </View>
        <Text style={styles.commentTitle}>
          Signatures des parties prenantes
        </Text>
        <View style={styles.signatureSection}>
          <View>
            <Text style={styles.signatureTitle}>Signature du prof.</Text>
            <View style={styles.signatureBox}></View>
          </View>
          <View>
            <Text style={styles.signatureTitle}>Signature du Dir.</Text>
            <View style={styles.signatureBox}></View>
          </View>
          <View>
            <Text style={styles.signatureTitle}>Signature d'étudiant.</Text>
            <View style={styles.signatureBox}></View>
          </View>
          <View>
            <Text style={styles.signatureTitle}>Signature resp.</Text>
            <View style={styles.signatureBox}></View>
          </View>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>
            <Text
              style={styles.footerLink}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} sur ${totalPages}`
              }
            />
          </Text>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default GenrateRapportPdf;
