import React from 'react';
import { Link } from 'react-router-dom';
import './notFoundPage.css';

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404 - Page Introuvable</h1>
      <p className="not-found-text">
      La page que vous recherchez n'existe pas ou a été déplacée..
      </p>
      <Link to="/" className="not-found-link">
      Retour à l'accueil
      </Link>
    </div>
  );
};

export default NotFoundPage;
