import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import person1 from "../../../assets/images/cool3.png";
import person2 from "../../../assets/images/cool2.png";
import person3 from "../../../assets/images/avatar3.jpg";
import person4 from "../../../assets/images/cool7.png";
const testimonials = [
  {
    quote:
      "Toutes mes équipes utilisent l'application avec efficacité. Gain de temps, facilité de suivi, des graphiques personnalisés d'évolution de compétences, identification des progressions et des lacunes de chaque élève. C'est magique!",
    author: "Hakim Durand",
    role: "Ingénieur pédagogique",
    image: person1,
  },
  {
    quote:
      "On peut désormais personnaliser et individualiser avec facilité. Nous 600 étudiants dont on maitrise l'impact précis de nos apprentissages et formation avec facilité. Je conseille l'outil pour les professionnels de l'enseignement, de la formation et l'éducation en général",
    author: "Martine Diop",
    role: "Chargé de cours université",
    image: person2,
  },
  {
    quote:
      "J'ai rarement vu un outil aussi pratique et puissant pour accompagner le développement de compétences. Je le recommande vivement",
    author: "Alicia Ziegler",
    role: "Chargée de formation",
    image: person3,
  },
  {
    quote:
      "Intuitif, facile à prendre en main. Nous appliquons l'approche par compétences avec bonheur. Bravo!",
    author: "Ghislaine Feldman",
    role: "Directrice Centre de formation",
    image: person4,
  },
];

const Testimonials = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="relative isolate overflow-hidden bg-indigo-700 px-10 py-24 py-20 lg:px-10">
      <div className="mx-auto max-w-2xl lg:max-w-4xl">
        <Slider {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <figure
                className="text-center text-xl   
                leading-8 "
              >
                <blockquote className="bg-white p-8 ">
                  <p>{testimonial.quote}</p>
                </blockquote>
                <figcaption className="mt-10">
                  <img
                    className="mx-auto h-20 w-20 rounded-full"
                    src={testimonial.image}
                    alt=""
                  />
                  <div className="mt-4 flex items-center flex-wrap justify-center space-x-3 text-base">
                    <div className="font-semibold text-lg text-white">
                      {testimonial.author}
                    </div>
                    <svg
                      viewBox="0 0 2 2"
                      width={3}
                      height={3}
                      aria-hidden="true"
                      className="fill-gray-100"
                    >
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="text-orange-800">{testimonial.role}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
