// src/components/Instituts.js
import React from "react";
import sections from "./sections";

const Instituts = () => {
  return (
    <div
      style={{
        background:
          "linear-gradient(-100.85deg, #6943BC 0.12%, #1F64BF 151.74%)",
      }}
      className="flex flex-col py-16"
    >
      {sections.map((section) => (
        <div key={section.id} className="flex flex-col py-8 mt-8">
          <h1 className="text-orange-500 text-2xl  lg:text-3xl font-semibold  text-center">
            {section.header}
          </h1>

          <main className="flex flex-1 w-full px-8 py-6 lg:px-16">
            <div className="flex flex-1 flex-col md:flex-row items-center">
              <div className="md:w-1/2 px-8 ">
                <img
                  src={section.image}
                  alt="instut"
                  style={{
                    objectFit: "cover",
                  }}
                  className="w-full border-2 border-orange-500 h-auto rounded-lg shadow-lg"
                />
              </div>
              <div className="md:w-1/2 p-0 text-center lg:text-start lg:p-4 ">
                <p className="text-white mb-4 font-semibold  text-xl lg:text-2xl">
                  {section.text}
                </p>
              </div>
            </div>
          </main>
        </div>
      ))}
    </div>
  );
};

export default Instituts;
