import React, { useContext } from "react";
import AuthContext from "../../../context/AuthContext";
import joinUsImage from "../../../assets/images/pret.png";
import { Link } from "react-router-dom";

const JoinUs = () => {
  const { auth } = useContext(AuthContext);

  const getDashboardLink = () => {
    if (auth.role === "Teacher" || auth.role === "Compagne Manager") {
      return "/teacher_dashboard";
    } else if (auth.role === "Student") {
      return `/student_dashboard/${auth.id}`;
    } else return "/signIn";
  };

  return (
    <div className="flex flex-col justify-center md:flex-row my-20">
      {/* Left Section (30%) */}
      <div className="w-full lg:block hidden md:w-2/5">
        <img
          src={joinUsImage}
          alt="Join Us"
          style={{
            height: "400px",
            width: "400px",
            filter: "drop-shadow(0 0 0.1rem purple)",
          }}
          className="w-full md:h-auto"
        />
      </div>

      {/* Right Section (70%) */}
      <div className="w-full md:w-4/5 p-4">
        <h2 className="text-3xl text-indigo-600 mb-5 pb-4 font-semibold mb-4">
          Tout est prêt pour commencer
        </h2>
        <p className="text-gray-600 pb-10 text-2xl mb-4">
          Rejoignez-nous sur Starskills et développons ensemble dès aujourd’hui
          les compétences de nos apprenants et de nos équipes !
        </p>
        <Link
          to={getDashboardLink()}
          className="inline-flex items-center px-12 py-4 border rounded-md shadow-sm text-base font-medium text-white"
          style={{
            background:
              " linear-gradient(93.64deg, #6943BC -26.03%, #D801F5 134.75%)",
            borderRadius: "32px",
          }}
        >
          Inscrivez-vous maintenant{" "}
        </Link>
      </div>
    </div>
  );
};

export default JoinUs;
