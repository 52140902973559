import React, { useState } from "react";
import pq1 from "../../../assets/images/Picture1.png";
import pq2 from "../../../assets/images/Picture2.jpg";
import pq3 from "../../../assets/images/pq3.png";
import pq4 from "../../../assets/images/Picture3.png";
import pq5 from "../../../assets/images/Picture4.png";

const Features = () => {
  const [selectedItem, setSelectedItem] = useState(0);

  const items = [
    {
      title: "Education",
      text: "Chaque enseignant, professeur ou professeur possède désormais la capacité de suivre de manière précise l’évolution des compétences",
      text2: "Starskills pour le système éducatif",
      text3:
        "Des forces et des axes d'amélioration identifier pour chaque élève pour faire évoluer chaque compétence de manière ciblée",
      imageUrl: pq1,
    },
    {
      title: "Formation Pro",
      text: "Chaque formateur donne le meilleur en mobilisant des techniques d’évaluation unifiée pour mesurer l’évolution des compétences avec facilité",
      text2: "Starskills pour la formation professionnelle",
      text3:
        "Des  formations et des apprentissages personnalisés pour la réussite et l’atteinte d’objectifs partagés par les apprenants",
      imageUrl: pq2,
    },
    {
      title: "Entreprises",
      text: "Des collaborateurs avec une méthode humaine. Un engagement amélioré, une productivité par une solution en compétences",
      text2: "Starskills pour les entreprises",
      text3: "Des équipes performantes et engagées",
      imageUrl: pq3,
    },
    {
      title: "Collectivités",
      text: "Des salariés engagés dans l’administration publique ou les collectivités territoriales par la montée en compétences accompagnée",
      text2: "Starskills pour collectivités",
      text3: "Des équipes alignées et épanouies",
      imageUrl: pq4,
    },
    {
      title: "Independant",
      text: "Consultant, coach ou conseiller, maitrisez l’impact de vos apprentissages et partager avec vos bénéficiaires la visualisation personnalisée de leur transformation",
      text2: "Starskills pour  les consultants, coachs, conseiller",
      text3: "Des coachés confiants et heureux de leur transformation",
      imageUrl: pq5,
    },
  ];

  return (
    <div className=" items-center mt-10 overflow-x-auto" id="PourQui">
      <div className="flex flex-wrap  mx-4 items-center justify-center col-span-5 space-x-1 mb-8">
        {items.map((item, index) => (
          <h2
            key={index}
            className={`text-center  mb-3 px-4 py-2 border rounded-full border-indigo-700 text-base font-semibold leading-8 cursor-pointer w-full sm:w-auto ${
              selectedItem === index ? clrGrediant : ""
            }`}
            onClick={() => setSelectedItem(index)}
            style={selectedItem === index ? clrGrediant : {}}
          >
            {item.title}
          </h2>
        ))}
      </div>

      <div className="grid max-w-lg lg:mx-20 mx-4 grid-cols-1 sm:grid-cols-1 lg:grid-cols-5 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:max-w-none">
        <div className="col-span-1 sm:col-span-2 lg:col-span-5 mt-5">
          <div className="flex justify-center flex-col md:flex-row space-x-0 md:space-x-5">
            {/* zero1 Box */}

            <div
              className="w-full md:w-1/3 rounded-3xl lg:mr-4 lg:ml-4 sm:mx-0 mb-2 center text-white p-6 flex flex-col justify-center mx-auto"
              style={{
                background:
                  " linear-gradient(128.85deg, #073381 5.02%, #6641B8 97.34%)",
                border: "6px solid orange",
                borderRadius: "23px",
              }}
            >
              {/* <img src={logo} alt="Logo" className="w-20 h-20 mt-4 mx-auto" /> */}
              <p className="text-2xl text-center font-semibold">
                {items[selectedItem].text}
              </p>
            </div>
            {/* zero2 Box */}
            <div
              className="w-full md:w-2/3 rounded-3xl lg:mr-4 lg:ml-4 lg:mb-auto mb-2 sm:mx-0 center text-black flex flex-col justify-center mx-auto"
              // style={{ border: "6px solid rgba(130, 43, 141, 1)" }}
            >
              <img
                src={items[selectedItem].imageUrl}
                alt={items[selectedItem].title}
                className="w-full h-full"
                style={{
                  border: "6px solid rgba(243, 205, 20, 1)",
                  borderRadius: "23px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid max-w-lg mx-20 grid-cols-1 lg:mx-20 sm:grid-cols-1 lg:grid-cols-5 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:gap-x-10 lg:max-w-none">
        <div className="col-span-1 sm:col-span-2 lg:col-span-5 mt-5">
          <div className="flex justify-center flex-col md:flex-row space-x-0 md:space-x-5">
            {/* First Box */}
            <div
              className="w-full md:w-1/3 rounded-3xl lg:mr-4 lg:ml-4 sm:mx-0 lg:mb-auto mb-2  center text-white p-6 flex flex-col justify-center mx-auto"
              style={{
                background:
                  " linear-gradient(97.04deg, #F3CD14 -11.5%, #D801F5 129.9%),linear-gradient(98.33deg, #F3CD14 0.35%, #D801F5 130.8%)",
                border: "6px solid rgba(130, 43, 141, 1)",
              }}
            >
              <p className="text-2xl text-center font-semibold">
                {items[selectedItem].text2}
              </p>
            </div>
            {/* Second Box */}
            <div
              className="w-full md:w-2/3 rounded-3xl center lg:mr-4 lg:ml-4 sm:mx-0 text-black p-6 flex flex-col justify-center mx-auto"
              style={{ border: "6px solid rgba(130, 43, 141, 1)" }}
            >
              <p className="text-2xl text-center font-semibold">
                {items[selectedItem].text3}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const clrGrediant = {
  background: "linear-gradient(97.04deg, #F3CD14 -11.5%, #D801F5 129.9%)",
};

export default Features;
