import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import axios from "axios";
import Navbar from "../../components/Home/Navbar/Navbar";
import auth_img from "../../assets/images/auth.png";
import authService from "../../context/authService";

export default function SignInPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { setAuth } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email || !password) {
      setError("Veuillez remplir tous les champs.");
      setLoading(false);
      return;
    }

    const requestBody = {
      email: email,
      password: password,
    };

    const requestBodyJSON = JSON.stringify(requestBody);
    const apiUrl = "https://api.starskills.fr/auth/login";

    try {
      const response = await axios.post(apiUrl, requestBodyJSON, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        try {
          const apiUrl = "https://api.starskills.fr/users/me";
          const response2 = await axios.get(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${response.data.data.access_token}`,
            },
          });

          if (response2.status === 200) {
            try {
              const apiUrl = `https://api.starskills.fr/roles/${response2.data.data.role}`;
              const response3 = await axios.get(apiUrl, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${response.data.data.access_token}`,
                },
              });

              if (response3.status === 200) {
                setAuth({
                  first_name: response2.data.data.first_name,
                  last_name: response2.data.data.last_name,
                  phone: response2.data.data.phone,
                  email: response2.data.data.email,
                  id: response2.data.data.id,
                  access_token: response.data.data.access_token,
                  refresh_token: response.data.data.refresh_token,
                  expires: response.data.data.expires,
                  role: response3.data.data.name,
                });
                authService.setAccessToken(response.data.data.access_token);
                authService.setTokenExpiration(
                  Date.now() + response.data.data.expires
                );
                authService.setRefreshToken(response.data.data.refresh_token);

                switch (response3.data.data.name) {
                  case "Student":
                    navigate(`/student_dashboard/${response2.data.data.id}`);
                    break;

                  case "Teacher":
                  case "Compagne Manager":
                    navigate("/teacher_dashboard");

                    break;
                }
              }
            } catch (err) {
              console.error(
                "Erreur lors de l envoi des données, veuillez réessayer.",
                error
              );
            }
          }
        } catch (err) {
          console.error(
            "Erreur lors de l envoi des données, veuillez réessayer.",
            error
          );
        }
      }
    } catch (err) {
      if (err.response?.status === 400) {
        setError("Email ou mot de passe incorrects.");
      } else {
        console.error(
          "Erreur lors de l envoi des données, veuillez réessayer.",
          error
        );
        setError(`Erreur lors de l envoi des données, veuillez réessayer.`);
      }
    }

    setLoading(false);
  };
  return (
    <>
      <Navbar />
      <div className="flex min-h-screen ">
        <div className="w-full lg:w-1/2">
          <div className="flex justify-center items-center h-full px-8 lg:px-8">
            <div className="sm:w-full sm:max-w-sm">
              <h2 className="mt-2 text-center mb-12 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Se Connecter
              </h2>

              <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Adresse e-mail
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      placeholder=" Adresse e-mail"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center ">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Mot de passe
                    </label>
                  </div>
                  <div className="my-2">
                    <input
                      id="password"
                      name="password"
                      placeholder="Mot de passe "
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  <div className="flex items-center justify-end">
                    <div className="text-sm">
                      <Link
                        to="#"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        {" "}
                        {/* Change anchor tag to Link */}
                        Mot de passe oublié ?
                      </Link>
                    </div>
                  </div>
                </div>

                {error && (
                  <p className="error-message text-red-500 p-3 rounded-md my-4">
                    {error}
                  </p>
                )}

                <div>
                  <div className="flex items-center justify-center">
                    <button
                      type="submit"
                      disabled={loading}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      {loading ? "Connexion en Cours..." : "Se Connecter"}
                    </button>
                  </div>
                </div>
              </form>
              <p className="mt-10 text-center text-sm text-gray-500">
                Vous n'avez pas de compte?{" "}
                <Link
                  to="/SignUp"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  S'Inscrire
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="hidden lg:flex justify-center items-center w-full lg:w-1/2 text-center">
          <img
            style={{
              filter: "drop-shadow(0 0 0.2rem blue)",
            }}
            src={auth_img}
            alt="signIn_image"
            className="w-[20rem] sm:w-[25rem] lg:w-[30rem] max-w-none "
          />
        </div>
      </div>
    </>
  );
}
