import React from 'react';
import { Bar } from 'react-chartjs-2';

const ChartView = ({ data }) => {
  const labels = [];
  const scores = [];

  data.forEach((student) => {
    labels.push(student.name);
    scores.push(student.score);
  });

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Student Scores',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        data: scores,
      },
    ],
  };

  const chartOptions = {
    indexAxis: 'x',
    scales: {
      x: {
        beginAtZero: true,
        max: 100, 
      },
    },
  };

  return (
    <div className="container pb-4 mx-auto mt-8">
      <div className="mb-16">
        <h2 className="text-xl font-semibold mb-4">Students Scores</h2>
        <div className="flex justify-center mb-4">
          <Bar data={chartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default ChartView;
