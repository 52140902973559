import React from 'react';
import image1 from '../../../../assets/images/stu1.png';
import image2 from '../../../../assets/images/stu.png';

const Message = () => {
  return (
    <div >
     
     <div className="bg-white p-6 rounded-lg shadow-md">
  <div className="flex flex-col sm:flex-row items-center justify-around my-4">
    <img src={image1} alt="Description for image 1" className="w-full sm:w-1/3 rounded mb-4 sm:mb-0 sm:mr-2" />
    <div className="bg-orange-500 text-white p-2 rounded w-full sm:w-1/3 mb-4 sm:mb-0">
      <p className="text-center">Conseils Motivation Encouragements</p>
    </div>
    <img src={image2} alt="Description for image 2" className="w-full sm:w-1/3 rounded mb-4 sm:mb-0 sm:ml-2" />
    <div className="bg-red-600 text-white p-2 rounded w-full sm:w-1/3">
      <p className="text-center">Toute l’équipe Pédagogique à tes côtés</p>
    </div>
  </div>

  <p className="text-xl font-medium text-gray-800">
    Bonjour mon ami(e) !
  </p>
  <p className="text-gray-600">
    Je t'invite à participer à la campagne d'évaluation Starskills. Clique sur le lien ci-dessous pour rejoindre la campagne immédiatement.
  </p>
  <a href="https://forms.gle/JuM5aS5awky+XfRq5" className="text-blue-500 hover:text-blue-700 visited:text-purple-500 underline">
    👉 https://forms.gle/JuM5aS5awky+XfRq5
  </a>
</div>

    </div>
  );
};

export default Message;
