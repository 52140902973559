// src/components/WhyUs.js
import React from "react";
import logo_icon from "../../../assets/images/logo.png";
import feature1 from "../../../assets/images/feature1.png";
import feature2 from "../../../assets/images/feature2.png";
import feature3 from "../../../assets/images/feature3.png";
import feature4 from "../../../assets/images/feature4.png";
import feature5 from "../../../assets/images/feature5.png";
import feature6 from "../../../assets/images/feature6.png";
import feature7 from "../../../assets/images/feature7.png";
import feature8 from "../../../assets/images/feature8.png";
import feature9 from "../../../assets/images/feature9.png";
import feature10 from "../../../assets/images/feature10.png";

const features = [
  {
    name: "Identifier, adapter ou créer vos référentiels de compétences",
    icon: feature1,
  },
  {
    name: "Visualisez, cartographier de manière dynamique la progression des compétences individuelles ou collectives",
    icon: feature2,
  },
  {
    name: "Gagner un temps Fou! Et concentrez - vous sur l’essentiel",
    icon: feature3,
  },
  {
    name: "Mobilisez rapidement des types d’évaluation pour mesurer des compétences de natures différentes",
    icon: feature4,
  },
  {
    name: "Adapter vos cours en intégrant le niveau de progression de vos apprenants avec facilité",
    icon: feature5,
  },
  {
    name: "Des feedbacks motivationnels personnalisés pour susciter l’engagement de chaque apprenant",
    icon: feature6,
  },
  {
    name: "Réaliser des campagnes d’évaluation liées à vos référentiels de compétences ",
    icon: feature7,
  },
  {
    name: "Réaliser des coachings individuels ou collectifs pour accompagner la montée en compétences",
    icon: feature8,
  },
  {
    name: "Réaliser des campagnes d’évaluation liées à vos référentiels de compétences",
    icon: feature9,
  },
  {
    name: "Bénéficier d’une méthode humaine Pour optimiser Votre processus pédagogique",
    icon: feature10,
  },
];
const WhyUs = () => {
  return (
    <div className="py-16  ">
      <div className="mx-4  lg:mx-8">
        <div className="text-center">
          <img
            src={logo_icon}
            alt="logo"
            className="h-24 text-indigo-600 mx-auto"
          />
          <p className="mt-2 font-semibold text-gray-700 text-lg leading-8 tracking-tight">
            Transformez votre approche pédagogique avec Starskills
          </p>
        </div>
        <div className="flex justify-center">
          <div className="w-full max-w-screen-xl mt-8">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5 mx-2">
              {features.map((feature) => (
                <div
                  key={feature.name}
                  className="w-full p-4   rounded-lg shadow-md"
                >
                  <img
                    src={feature.icon}
                    alt={feature.name}
                    className="h-20 w-20 mb-6  text-indigo-600 mx-auto"
                  />
                  <dt className="mt-2 text-lg text-center font-semibold leading-6 text-gray-700">
                    {feature.name}
                  </dt>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
