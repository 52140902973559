import React from "react";
import { Route, Routes } from "react-router-dom";
import EvaluationParObservation from "../evaluation/observation/EvaluationParObservation";

import EvaluationAuto from "../evaluation/auto_evaluation/EvaluationAuto";
import EvaluationEchange from "../evaluation/echange/EvaluationEchange";
import Eleves from "../eleves/Eleves";
import AddSession from "../Refs/AddSession";
import Rapports from "../rapports/Rapport_view";
import Decouvrir from "../Decouvrir";
import MyCampaigns from "../campaigns/MyCampaigns";
import Enseignants from "../Enseignants";
import RefsPage from "../Refs/RefsPage";
import RequireAuth from "../../../../Auth/RequireAuth";
import Profile from "../profile/Profile";
import CreateEvaluationPage from "../campaigns/createEvalaution/CreateEvaluationPage";
import Mesure_main from "../evaluation/main_pages/MesureMain";
import Rapports_main from "../rapports/Rapports_main";
import RapportView from "../rapports/Rapport_view";
import EvaluationLists from "../evaluation/main_pages/EvalutionsLists";
import HomeContent from "../home/homecontent";

const TeacherOrgRoutes = () => {
  const ROLES = {
    Compagne_Manager: "Compagne Manager",
    Teacher: "Teacher",
  };

  return (
    <Routes>
      <Route
        element={
          <RequireAuth allowedRoles={[ROLES.Teacher, ROLES.Compagne_Manager]} />
        }
      >
        <Route path="/" element={<HomeContent />} />
        <Route
          path="/observation/:evaluationId"
          element={<EvaluationParObservation />}
        />
        <Route path="/evaluations_list" element={<EvaluationLists />} />

        <Route
          path="/auto_evaluation/:evaluationId"
          element={<EvaluationAuto />}
        />
        <Route path="/mesure_list" element={<Mesure_main />} />
        <Route path="/echange/:evaluationId" element={<EvaluationEchange />} />
        <Route path="/mes_eleves" element={<Eleves />} />
        <Route path="/:category/:niveauId" element={<RefsPage />} />
        <Route path="/:category/add_session" element={<AddSession />} />
        <Route path="/Rapports" element={<Rapports_main />} />
        <Route path="/Rapport_view/:sessionId" element={<RapportView />} />
        <Route path="/mes_campagnes" element={<MyCampaigns />} />
        <Route
          path="/mes_campagnes/create_evaluation/:sessionId"
          element={<CreateEvaluationPage />}
        />

        <Route path="/mon-profile" element={<Profile />} />
        <Route path="/decouvrir" element={<Decouvrir />} />
      </Route>
      <Route element={<RequireAuth allowedRoles={[ROLES.Compagne_Manager]} />}>
        <Route path="/enseignants" element={<Enseignants />} />
      </Route>
    </Routes>
  );
};

export default TeacherOrgRoutes;
