import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIdBadge,
  faCalendarAlt,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import authService from "../../../../../../context/authService";

const Mesure_main = () => {
  const [allEvaluations, setAllEvaluations] = useState([]);
  const [displayedEvaluations, setDisplayedEvaluations] = useState([]);
  const [numToShow, setNumToShow] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const value = "Mesure";
      const response = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation?filter[Type]=${value}`,
        "GET"
      );

      const EvaluationDetails = await Promise.all(
        response.data.map(async (evaluation) => {
          const subSkills = await Promise.all(
            evaluation.List_SubSkills.map(async (skill) => {
              const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${skill}`;
              const subSkillInfoResponse =
                await authService.makeAuthenticatedRequest(
                  subSkillInfoUrl,
                  "GET"
                );
              return {
                name: subSkillInfoResponse.data.Name,
              };
            })
          );
          return {
            ...evaluation,
            subSkills,
          };
        })
      );
      setAllEvaluations(EvaluationDetails);
      setDisplayedEvaluations(EvaluationDetails.slice(0, numToShow));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const loadMore = () => {
    const nextEvaluations = allEvaluations.slice(0, numToShow + 10);
    setDisplayedEvaluations(nextEvaluations);
    setNumToShow(numToShow + 10);
  };

  return (
    <div className="container mx-auto mt-4">
      <h1 className="text-2xl font-bold mb-4 text-blue-600">Mes Mesures</h1>
      {allEvaluations.length === 0 ? (
        <div className="flex justify-center items-center h-64">
          <p className="text-gray-500 text-xl">Aucune évaluation disponible</p>
        </div>
      ) : (
        <>
          {displayedEvaluations.map((evaluation) => (
            <Link
              to={`/teacher_dashboard/mesure/${evaluation.id}`}
              key={evaluation.id}
              className="block w-full"
            >
              <div className="bg-gray-100 border border-gray-300 rounded-md shadow-md p-4 mb-4 transition duration-300 hover:shadow-lg">
                <div className="flex justify-between items-center mb-2">
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faIdBadge}
                      className="text-blue-600 mr-2"
                    />
                    <h2 className="text-lg font-semibold text-blue-600">
                      {`Evaluation ID : ${evaluation.id}`}
                    </h2>
                  </div>
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="text-gray-500 mr-2"
                    />
                    <p className="text-sm text-gray-500">
                      {new Date(evaluation.date_created).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon
                    icon={faTasks}
                    className="text-gray-600 mr-2"
                  />
                  <p className="font-medium text-gray-600 mr-2">
                    Compétences :
                  </p>
                  <div className="flex flex-wrap gap-x-2">
                    {evaluation.subSkills.map((skill, index) => (
                      <span
                        key={index}
                        className="bg-blue-200 text-blue-800 px-2 py-1 rounded"
                      >
                        {skill.name}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          ))}
          {numToShow < allEvaluations.length && (
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={loadMore}
            >
              Charger plus d'éléments
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Mesure_main;
