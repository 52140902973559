import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import dayjs from "dayjs";

const CalendarToggle = () => {
  const [currentDate, setCurrentDate] = useState(dayjs());

  const handlePrevDay = () => {
    setCurrentDate(currentDate.subtract(1, "day"));
  };

  const handleNextDay = () => {
    setCurrentDate(currentDate.add(1, "day"));
  };

  return (
    <div className="flex items-center bg-indigo-600 text-white rounded-3xl shadow-md px-2 py-1    space-x-2">
      <button className="px-2 py-1 " onClick={handlePrevDay}>
        <ChevronLeftIcon className="h-5 w-5" />
      </button>

      {currentDate.format("DD MMM YYYY")}

      <button className="px-2 py-1 " onClick={handleNextDay}>
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default CalendarToggle;
