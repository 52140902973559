import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

const Modal = ({
  showModal,
  closeModal,
  teacherName,
  sessionDetails,
  evaluationType,
  questionList,
  studentEmails,
  onSendEmail,
}) => {
  return (
    <Dialog open={showModal} handler={closeModal}>
      <DialogHeader>Quiz Details</DialogHeader>
      <DialogBody className="overflow-y-auto max-h-80">
        <div>
          <p><strong>Teacher Name:</strong> {teacherName}</p>
          <p><strong>Session Details:</strong> {sessionDetails}</p>
          <p><strong>Evaluation Type:</strong> {evaluationType}</p>
          <p><strong>Student Emails:</strong> {studentEmails.join(", ")}</p>
          <h2 className="text-xl font-semibold mt-4">Question List:</h2>
          {questionList && questionList.length > 0 ? (
            <ul>
              {questionList.map((question, index) => (
                <li key={index}>
                  <div>
                    <p className="font-semibold">Question: {question.question}</p>
                    <ul>
                      {question.options.map((option, i) => (
                        <li key={i}>{option}</li>
                      ))}
                    </ul>
                    <p className="font-semibold">Correct Answer: {question.correctAnswer}</p>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p>No questions added yet.</p>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button color="red" onClick={closeModal}>
          Close
        </Button>
        <Button color="green" onClick={onSendEmail}>
          Validate and Send Email
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default Modal;
