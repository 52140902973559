import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import avatar1 from "../../../../../../assets/avatars/avatar1.png";
import avatar2 from "../../../../../../assets/avatars/avatar2.png";
import avatar3 from "../../../../../../assets/avatars/avatar3.png";
import avatar4 from "../../../../../../assets/avatars/avatar4.png";
import avatar5 from "../../../../../../assets/avatars/avatar5.png";
import avatar6 from "../../../../../../assets/avatars/avatar6.png";
import SliderView from "./views/SliderView";
import ChartView from "./views/ChartView";
import authService from "../../../../../../context/authService";

const EvaluationParObservation = () => {
  const { evaluationId } = useParams();
  console.log("evaluationid:", evaluationId);
  useEffect(() => {
    fetchEvaluation(evaluationId);
  }, [evaluationId]);

  const fetchEvaluation = async (evaluationId) => {
    try {
      const response = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation/${evaluationId}`,
        "GET"
      );
      const evaluation = response.data.List_Evaluation;
      const evsList = evaluation.join(",");
      const apiUrlEva = `https://api.starskills.fr/items/Evaluation_Student?filter={"id":{"_in":"${evsList}"}}`;
      const responseEva = await authService.makeAuthenticatedRequest(
        apiUrlEva,
        "GET"
      );
      const enrichedEvaluation = await Promise.all(responseEva.data.map(async (ev) => {
        const studentResponse = await authService.makeAuthenticatedRequest(
          `https://api.starskills.fr/users/${ev.Student_id}`,
          "GET"
        );
        const subSkillResponse = await authService.makeAuthenticatedRequest(
          `https://api.starskills.fr/items/SubSkill/${ev.SubSkill}`,
          "GET"
        );
  
        return {
          ...ev,
          first_name: studentResponse.data.first_name,
          last_name: studentResponse.data.last_name,
          SubSkill_name: subSkillResponse.data.Name
        };
      }));
  
      console.log(enrichedEvaluation);
    } catch (error) {
      console.error("Erreur lors de la récupération de la evaluation :", error);
    }
  };
  const [view, setView] = useState("slider");

  const students = [
    { id: 1, name: "Riva Doe", avatar: avatar1, score: 80 },
    { id: 2, name: "Jane Smith", avatar: avatar2, score: 70 },
    { id: 3, name: "Alex Johnson", avatar: avatar3, score: 90 },
    { id: 4, name: "Mika Brown", avatar: avatar4, score: 85 },
    { id: 5, name: "David Taylor", avatar: avatar5, score: 65 },
    { id: 6, name: "Emma Wilson", avatar: avatar6, score: 75 },
  ];

  const marks = Array.from({ length: 11 }, (_, index) => ({
    value: index * 10,
    label: `${index * 10}`,
  }));

  const toggleView = (viewType) => {
    setView(viewType);
  };

  const valuetext = (value) => `${value}`;

  return (
    <div className="container mx-auto mt-4 text-center">
      <h1 className="text-2xl font-bold mb-2 text-white bg-orange-600 inline-block px-6 py-2 rounded-3xl">
        Observation
      </h1>
      <p className="text-sm mb-4 ">
        Mise en situation, résolutions de problèmes, observations de
        comportements, d’attitudes ou de qualités individuelles et/ou
        collectives
      </p>
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-4">
          Compétence Ciblée :{" "}
          <span className=" text-orange-500 font-bold mb-2">
            Ability to summarize information
          </span>
        </h2>
        {view === "slider" && (
          <SliderView students={students} marks={marks} valuetext={valuetext} />
        )}
        {view === "chart" && <ChartView data={students} />}
      </div>

      <div className="flex justify-center mt-4">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-6 rounded-full mr-4 flex items-center"
          onClick={() => toggleView("chart")}
        >
          <FontAwesomeIcon icon={faChartBar} className="mr-2" />
          Switch to Chart View
        </button>
        <button
          className="bg-indigo-800 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded-full flex items-center"
          onClick={() => toggleView("slider")}
        >
          <FontAwesomeIcon icon={faSlidersH} className="mr-2" />
          Switch to Slider View
        </button>
      </div>
    </div>
  );
};

export default EvaluationParObservation;
