import React, { useContext } from "react";
import "./usage.css";
import model1 from "../../../assets/images/model1.png";
import model2 from "../../../assets/images/model2.png";
import model3 from "../../../assets/images/model3.png";
import model4 from "../../../assets/images/model4.png";
import model5 from "../../../assets/images/model5.png";
import AuthContext from "../../../context/AuthContext";
import { Link } from "react-router-dom";

export default function Usage() {
  const { auth } = useContext(AuthContext);

  const getDashboardLink = () => {
    if (auth.role === "Teacher" || auth.role === "Compagne Manager") {
      return "/teacher_dashboard";
    } else if (auth.role === "Student") {
      return `/student_dashboard/${auth.id}`;
    } else return "/signIn";
  };

  return (
    <div
      className="container mx-auto flex flex-col items-center justify-center my-20  	 pb-8"
      id="models"
    >
      <h4 className="model-title">
        <span className="text-purple-600"> Des modèles </span>
        pour passer à l’action avec rapidité et méthode{" "}
      </h4>

      <button type="button">
        <img src={model1} alt="model1 image" />
        <img src={model2} alt="model2 image" />
        <img src={model3} alt="model3 image" />
        <img src={model4} alt="model4 image" />
        <img src={model5} alt="model5 image" />
      </button>
      <button type="button">
        <img src={model1} alt="model1 image" />
        <img src={model2} alt="model2 image" />
        <img src={model3} alt="model3 image" />
        <img src={model4} alt="model4 image" />
        <img src={model5} alt="model5 image" />
      </button>
      <div className="mt-10 text-center">
        <Link
          to={getDashboardLink()}
          className="inline-flex items-center px-12 py-4 border rounded-md shadow-sm text-base font-medium text-white"
          style={{
            background:
              " linear-gradient(93.64deg, #6943BC -26.03%, #D801F5 134.75%)",
            borderRadius: "32px",
          }}
        >
          Débuter votre Starskills campagne
        </Link>
      </div>
    </div>
  );
}
