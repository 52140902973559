import React, { useState } from "react";
import why_img from "../../../assets/images/why2.png";

const features = [
  {
    name: "Intéractivité",
    sub_title: "Intéractivité",
    description:
      "Des feedbacks interactifs  et personnalisé pour motiver et impliquer l'apprenant dans ses apprentissages",
  },
  {
    name: "ergonomique",
    sub_title: "ergonomique",
    description:
      "Une solution rapide et facile à prendre en main, Starskills sera votre allié au quotidien",
  },
  {
    name: "Branding",
    sub_title: "Branding",
    description:
      "Personnalisez vos supports avec votre logo ou les logos de vos partenaires",
  },
];

export default function ImageSection() {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);
  return (
    <div
      className="max-w-full flex justify-center mx-auto pt-10 px-6  mt-8 lg:mt-0 lg:px-8"
      style={{
        background: "linear-gradient(91.05deg, #073381 8.1%, #052027 103.06%)",
      }}
    >
      <div className="max-w-7xl grid grid-cols-1 gap-y-10 sm:gap-y-20">
        <div className="text-center">
          <ul className="flex justify-center flex-wrap text-2xl">
            {features.map((feature) => (
              <li
                key={feature.name}
                className={`cursor-pointer p-2 ${
                  feature === selectedFeature
                    ? "text-yellow-700 border-b-2 border-yellow-700"
                    : "text-white"
                }`}
                onClick={() => setSelectedFeature(feature)}
              >
                {feature.name}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex flex-col items-center justify-center lg:flex-row lg:justify-center">
          <div className="w-full lg:w-1/2 lg:pr-6">
            <h3 className="text-3xl font-semibold leading-7 text-yellow-700 text-center lg:text-left">
              {selectedFeature.name}
            </h3>

            <p className="mt-4 font-semibold text-2xl leading-7 text-white text-center lg:text-left">
              {selectedFeature.description}
            </p>
          </div>

          <div className="w-full lg:w-1/2 text-center">
            <img
              src={why_img}
              alt="why us"
              className="mx-auto rounded-xl"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
