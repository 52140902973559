import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import CompetenceItem from "./CompetenceItem";
import CreateGroupModal from "../../eleves/components/CreateGroupModal";
import authService from "../../../../../../context/authService";
import AuthContext from "../../../../../../context/AuthContext";
import {
  faPlus,
  faEdit,
  faLink,
  faHourglassHalf,
  faAngleRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddSkillDialog from "./AddSkillDialog";

const CreateSessionModal = ({
  showCreateSessionModal,
  handleModalClose,
  selectedItem,
  currentStep,
  refSkills,

  handleNextStep,
  handlePrevStep,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [mode, setMode] = useState("Edition");
  const { auth } = useContext(AuthContext);
  const [nbEval, setNbEval] = useState(0);
  const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [newSkillName, setNewSkillName] = useState("");
  const [selectedExistingSkill, setSelectedExistingSkill] = useState(null);
  const [selectedSubskills, setSelectedSubskills] = useState([]);
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [sessionTitle, setSessionTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [totalSkills, setTotalSkills] = useState(0);
  const [selectedSkills, setSelectedSkills] = useState(0);
  const [parentSkillName, setParentSkillName] = useState("");
  const [remainingSkills, setRemainingSkills] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [sessionTitleError, setSessionTitleError] = useState("");

  const handleRefreshPage = () => {
    window.location.reload();
  };

  const handleDayChange = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays((prevSelectedDays) =>
        prevSelectedDays.filter((selectedDay) => selectedDay !== day)
      );
    } else {
      setSelectedDays((prevSelectedDays) => [...prevSelectedDays, day]);
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const weekDays = [
    { name: "Lundi", value: "monday" },
    { name: "Mardi", value: "tuesday" },
    { name: "Mercredi", value: "wednesday" },
    { name: "Jeudi", value: "thursday" },
    { name: "Vendredi", value: "friday" },
    { name: "Samedi", value: "saturday" },
    { name: "Dimanche", value: "sunday" },
  ];
  useEffect(() => {
    if (!refSkills) return;

    const totalSubskills = refSkills.reduce((acc, item) => {
      if (item.SubSkills && item.SubSkills.length > 0) {
        return acc + item.SubSkills.length;
      }
      return acc;
    }, 0);

    const totalSelectedSubskills = selectedSubskills.length;

    setSelectedSkills(totalSelectedSubskills);
    setRemainingSkills(totalSubskills - totalSelectedSubskills);
    setTotalSkills(totalSubskills);
  }, [refSkills, selectedSubskills]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const apiUrlGroups = `https://api.starskills.fr/items/Group?filter[user_created]=${auth.id}`;
        const responseGroups = await authService.makeAuthenticatedRequest(
          apiUrlGroups,
          "GET"
        );
        console.log("groups", responseGroups.data);
        setGroups(responseGroups.data);
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);
  const nextGroup = () => {
    setCurrentGroupIndex((prevIndex) => (prevIndex + 1) % groupData.length);
  };

  const prevGroup = () => {
    setCurrentGroupIndex((prevIndex) =>
      prevIndex === 0 ? groupData.length - 1 : prevIndex - 1
    );
  };
  const handleSelectAllSubskills = () => {
    const allSubskills = refSkills.reduce((acc, item) => {
      if (item.SubSkills && item.SubSkills.length > 0) {
        return [...acc, ...item.SubSkills.map((subSkill) => subSkill.id)];
      }
      return acc;
    }, []);
    setSelectedSubskills(allSubskills);
  };
  const handleClearAllSubskills = () => {
    setSelectedSubskills([]);
  };

  const handleSubskillChange = (subskillId) => {
    if (selectedSubskills.includes(subskillId)) {
      setSelectedSubskills((prevState) =>
        prevState.filter((id) => id !== subskillId)
      );
    } else {
      const parentSkill = refSkills.find((skill) =>
        skill.SubSkills.some((subskill) => subskill.id === subskillId)
      );

      const parentSkillName = parentSkill ? parentSkill.Name : "";

      setSelectedSubskills((prevState) => [...prevState, subskillId]);
      setParentSkillName(parentSkillName);
    }
  };

  const handleAddGroup = () => {
    setShowCreateGroupModal(true);
  };
  const handleSelectOneStudent = (student) => {
    const isSelected = selectedStudents.some((s) => s.id === student.id);
    if (isSelected) {
      setSelectedStudents((prevSelectedStudents) =>
        prevSelectedStudents.filter((s) => s.id !== student.id)
      );
    } else {
      setSelectedStudents((prevSelectedStudents) => [
        ...prevSelectedStudents,
        student,
      ]);
    }
  };

  const isStudentSelected = (student) => {
    return selectedStudents.some((s) => s.id === student.id);
  };

  const HandleSelectAllStudents = () => {
    const selectedGroup = groupData[currentGroupIndex];
    const allStudents = selectedGroup.group_students.map((student) => ({
      ...student,
      selected: true,
    }));

    const allStudentsSelected = allStudents.every((student) =>
      selectedStudents.some((s) => s.id === student.id)
    );

    if (!allStudentsSelected) {
      setSelectedStudents((prevSelectedStudents) => [
        ...prevSelectedStudents,
        ...allStudents,
      ]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authService.accessToken || authService.isAccessTokenExpired()) {
          await authService.refreshAccessToken();
        }

        const apiUrlGroups = `https://api.starskills.fr/items/Group?filter[user_created]=${auth.id}`;
        const responseGroups = await authService.makeAuthenticatedRequest(
          apiUrlGroups,
          "GET"
        );
        const groups = responseGroups.data;
        const groupStudentDetails = await Promise.all(
          groups.map(async (group) => {
            const studentsList = group.Students.join(",");
            const apiUrlStudents = `https://api.starskills.fr/items/Group_student?filter={"id":{"_in":"${studentsList}"}}`;
            const responseStudents = await authService.makeAuthenticatedRequest(
              apiUrlStudents,
              "GET"
            );
            const students = responseStudents.data;
            const studentDetails = await Promise.all(
              students.map(async (student) => {
                const studentId = student.student_id;
                const studentInfoUrl = `https://api.starskills.fr/users/${studentId}`;
                const studentInfoResponse =
                  await authService.makeAuthenticatedRequest(
                    studentInfoUrl,
                    "GET"
                  );
                return {
                  id: studentInfoResponse.data.id,
                  first_name: studentInfoResponse.data.first_name,
                  last_name: studentInfoResponse.data.last_name,
                  email: studentInfoResponse.data.email,
                  relation_id: student.id,
                };
              })
            );
            return {
              group_id: group.id,
              group_name: group.Name,
              group_students: studentDetails,
            };
          })
        );

        const data = groupStudentDetails.map((group) => ({
          group_id: group.group_id,
          group_name: group.group_name,
          group_students: group.group_students.map((student) => ({
            id: student.id,
            first_name: student.first_name,
            last_name: student.last_name,
            email: student.email,
            relation_id: student.relation_id,
          })),
        }));
        setGroupData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [auth]);

  const handleNbEvalChange = (event) => {
    setNbEval(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleModeChange = (mode) => {
    setMode(mode);
  };

  const renderContent = () => {
    switch (mode) {
      case "Appercu":
        return (
          <>
            <h3 className="font-semibold text-center text-lg text-black my-2  ">
              Liste des Compétences
            </h3>
            <div>
              {refSkills && refSkills.length > 0 ? (
                refSkills.map((item) => (
                  <div key={item.id}>
                    <div className="flex items-center p-4 mb-3 border border-indigo-500 rounded-lg">
                      <div className="w-full">
                        <CompetenceItem
                          competenceTitle={item.Name}
                          key={item.id}
                        >
                          {item.SubSkills && item.SubSkills.length > 0 ? (
                            <ul className="max-h-30 w-full">
                              {item.SubSkills.map((subSkill) => (
                                <li
                                  key={subSkill.id}
                                  className="flex   items-center border-b border-gray-300 py-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className="mr-4 text-gray-400"
                                  />
                                  <span>{subSkill.Name}</span>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="text-center">
                              Aucune sous-compétence disponible
                            </p>
                          )}
                        </CompetenceItem>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-center">Aucune compétence disponible</p>
              )}
            </div>
          </>
        );
      case "Edition":
        return (
          <>
            <div>
              <div>
                <div className="flex justify-between items-center border-b border-gray-300 pb-2">
                  <p className=" font-semibold text-black  ">
                    nombre des Compétences - totales : {totalSkills} |
                    sélectionnées : {selectedSkills} | restantes :{" "}
                    {remainingSkills}
                  </p>

                  <Button onClick={() => setOpen(true)}>
                    Ajouter compétences
                  </Button>
                </div>
                <h3 className="font-semibold text-center text-lg text-black mt-2  ">
                  Choisir une Compétence
                </h3>
                <div className="flex justify-between items-center">
                  <Button
                    className="font-semibold bg-blue-700 my-2"
                    onClick={handleSelectAllSubskills}
                  >
                    Cocher tout
                  </Button>
                  <p
                    onClick={handleClearAllSubskills}
                    className="mr-2 text-red-600 cursor-pointer"
                  >
                    Effacer tout
                  </p>
                </div>

                <div>
                  {refSkills && refSkills.length > 0 ? (
                    refSkills.map((item) => (
                      <div key={item.id}>
                        <div className="flex items-center p-4 mb-2 border border-green-500 rounded-lg">
                          <div className="w-full ">
                            <CompetenceItem
                              competenceTitle={item.Name}
                              key={item.id}
                            >
                              {item.SubSkills && item.SubSkills.length > 0 ? (
                                <ul className="max-h-30 w-full">
                                  {item.SubSkills.map((subSkill) => (
                                    <li
                                      key={subSkill.id}
                                      className="flex justify-between  items-center border-b border-gray-300 py-2"
                                    >
                                      <label className="flex text-lg items-center">
                                        <input
                                          type="checkbox"
                                          className="mr-2"
                                          checked={selectedSubskills.includes(
                                            subSkill.id
                                          )}
                                          onChange={() =>
                                            handleSubskillChange(subSkill.id)
                                          }
                                        />
                                        {subSkill.Name}
                                      </label>
                                      <div className="flex items-center space-x-2">
                                        <button
                                          className="text-green-500 hover:text-green-700"
                                          onClick={() => console.log("Edit")}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </button>

                                        <button
                                          className="text-gray-500 hover:text-gray-700"
                                          onClick={() => console.log("Assign")}
                                        >
                                          <FontAwesomeIcon icon={faLink} />
                                        </button>
                                        <button
                                          className="text-red-500 hover:text-red-700"
                                          onClick={() => console.log("Delete")}
                                        >
                                          <FontAwesomeIcon
                                            icon={faHourglassHalf}
                                          />
                                        </button>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <p className="text-center">
                                  Aucune sous compétence disponible
                                </p>
                              )}
                            </CompetenceItem>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center">Aucune compétence disponible</p>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const selectedParentSkillNamesSet = new Set();

  selectedSubskills.forEach((subskillId) => {
    const parentSkill = refSkills.find((skill) =>
      skill.SubSkills.some((subskill) => subskill.id === subskillId)
    );
    if (parentSkill) {
      selectedParentSkillNamesSet.add(parentSkill.Name);
    }
  });

  const selectedParentSkillNames = Array.from(selectedParentSkillNamesSet);

  const selectedSubskillNames =
    Array.isArray(selectedSubskills) &&
    selectedSubskills.map((subskillId) => {
      const subskill = refSkills
        .flatMap((skill) => skill.SubSkills)
        .find((subskill) => subskill.id === subskillId);
      return subskill ? subskill.Name : "";
    });

  const handleSessionCreation = async () => {
    if (!sessionTitle.trim()) {
      setSessionTitleError("Le titre de la session est requis.");
      return;
    }
    setSessionTitleError("");
    const sessionData = {
      selectedSkills: selectedParentSkillNames.map((name) => ({
        id: refSkills.find((skill) => skill.Name === name).id,
        name: name,
      })),
      selectedSubskills: selectedSubskillNames.map((name) => ({
        id: refSkills
          .flatMap((skill) => skill.SubSkills)
          .find((subskill) => subskill.Name === name).id,
        name: name,
      })),
      numberOfEvaluations: nbEval,
      selectedStartDate: startDate,
      selectedEndDate: endDate,
      selectedDays: selectedDays,
      sessionTitle: sessionTitle,
      selectedStudents: selectedStudents.map((student) => ({
        id: student.id,
        first_name: student.first_name,
        last_name: student.last_name,
        email: student.email,
        relation_id: student.relation_id,
      })),
    };

    console.log("Session data:", sessionData);
    try {
      const Data = {
        Title: sessionTitle,
        Start_Date: startDate,
        End_Date: endDate,
        List_Days: selectedDays,
        Nbreval: nbEval,
      };
      const apiUrlsession = `https://api.starskills.fr/items/Sessions`;
      const response = await authService.makeAuthenticatedRequest(
        apiUrlsession,
        "POST",
        Data
      );

      const EvaluationData = {
        Session: response.data.id,
        Type: "Auto Evaluation",
        List_SubSkills: sessionData.selectedSubskills.map((skill) => skill.id),
      };
      const apiUrlEvaluation = `https://api.starskills.fr/items/Evaluation`;
      const responseEvaluation = await authService.makeAuthenticatedRequest(
        apiUrlEvaluation,
        "POST",
        EvaluationData
      );

      for (const student of sessionData.selectedStudents) {
        const studentData = {
          Student_id: student.id,
          Sessions_id: response.data.id,
        };
        const studentResponse = await authService.makeAuthenticatedRequest(
          "https://api.starskills.fr/items/Sessions_Students",
          "POST",
          studentData
        );

        for (const subSkill of sessionData.selectedSubskills) {
          const scoreData = {
            Student_id: student.id,
            Evaluation_id: responseEvaluation.data.id,
            Status: "Incomplete",
            SubSkill: subSkill.id,
          };
          const scoreResponse = await authService.makeAuthenticatedRequest(
            "https://api.starskills.fr/items/Evaluation_Student",
            "POST",
            scoreData
          );
        }
      }

      for (const subSkill of sessionData.selectedSubskills) {
        const subSkillData = {
          SubSkill_id: subSkill.id,
          Sessions_id: response.data.id,
        };

        const subSkillResponse = await authService.makeAuthenticatedRequest(
          "https://api.starskills.fr/items/Sessions_SubSkill",
          "POST",
          subSkillData
        );
      }
      handleModalClose();
      setSuccessModalOpen(true);
      setTimeout(() => {
        navigate("/teacher_dashboard/mes_campagnes", { replace: true });
      }, 2000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div>
      {showCreateSessionModal && (
        <Dialog
          open={showCreateSessionModal}
          handler={handleModalClose}
          style={{ width: "90%", maxWidth: "900px" }}
        >
          <DialogHeader>
            <p> Nom de Ref : </p>
            <span className="text-indigo-700 mx-2">
              {selectedItem && selectedItem.Name}
            </span>
          </DialogHeader>
          <DialogBody
            style={{
              height: "550px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            {/* AddSkillDialog component */}
            <AddSkillDialog
              open={open}
              handleClose={handleClose}
              newSkillName={newSkillName}
              setNewSkillName={setNewSkillName}
              selectedExistingSkill={selectedExistingSkill}
              setSelectedExistingSkill={setSelectedExistingSkill}
              refSkills={refSkills}
            />
            {currentStep === 1 && selectedItem && (
              <div>
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3
                      className={`font-semibold text-lg ${
                        mode === "Appercu"
                          ? "text-indigo-700"
                          : "text-green-500"
                      }`}
                    >
                      Mode {mode}
                    </h3>
                  </div>
                  <div className="flex">
                    <Button
                      className={
                        mode === "Appercu"
                          ? "bg-indigo-700 m-2"
                          : "bg-gray-600 m-2"
                      }
                      onClick={() => handleModeChange("Appercu")}
                    >
                      Mode Appercu
                    </Button>
                    <Button
                      className={
                        mode === "Edition"
                          ? "bg-green-500 m-2"
                          : "bg-gray-600 m-2"
                      }
                      onClick={() => handleModeChange("Edition")}
                    >
                      Mode Édition
                    </Button>
                  </div>
                </div>

                {renderContent()}
              </div>
            )}

            {currentStep === 2 && (
              <div className="p-4 bg-gray-200 rounded-md shadow-md">
                <div className="flex justify-between items-center mb-8">
                  <p className="text-center text-xl font-semibold mb-4 text-teal-700">
                    Sélectionner les étudiants
                  </p>
                  <Button color="blue" onClick={handleAddGroup}>
                    Ajouter le groupe manuellement
                  </Button>
                </div>
                <CreateGroupModal
                  open={showCreateGroupModal}
                  onClose={() => setShowCreateGroupModal(false)}
                  onRefreshPage={handleRefreshPage}
                />
                <div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {selectedStudents.map((student, index) => (
                      <div
                        key={index}
                        className="bg-orange-200 text-indigo-700"
                        style={{
                          padding: "5px",
                          margin: "5px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSelectOneStudent(student)}
                      >
                        {student.first_name} {student.last_name}
                      </div>
                    ))}
                  </div>
                  <div className="w-auto flex justify-between my-3">
                    <p className="text-xl font-semibold mb-2 text-teal-700">
                      Groupe : {groupData[currentGroupIndex].group_name}
                    </p>

                    <button
                      className="bg-white rounded-md text-green-700 inline-block px-4 py-2 rounded-md flex items-center ml-4"
                      onClick={HandleSelectAllStudents}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2 " />
                      Selelectinner tous le groupe{" "}
                      {groupData[currentGroupIndex].group_name}
                    </button>
                  </div>
                  {/* Display group students */}
                  <div className="overflow-x-auto w-auto">
                    <table className="w-full border border-gray-300 bg-white rounded-md shadow-md mb-8">
                      <thead>
                        <tr className="text-white bg-gray-500">
                          <th className="py-2 px-4 border">Nom et Prénom</th>
                          <th className="py-2 px-4 border">Email</th>
                          <th className="py-2 px-4 border">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupData[currentGroupIndex].group_students.length ===
                        0 ? (
                          <tr>
                            <td colSpan="3" className="text-center py-4">
                              Groupe vide
                            </td>
                          </tr>
                        ) : (
                          groupData[currentGroupIndex].group_students.map(
                            (student, index) => (
                              <tr key={index} className="hover:bg-gray-100">
                                <td className="py-2 px-4 border break-words">
                                  {student.first_name} {student.last_name}
                                </td>
                                <td className="py-2 px-4 border break-words">
                                  {student.email}
                                </td>
                                <td className="py-2 px-4 border break-words">
                                  <button
                                    className={`py-1 px-2 rounded ${
                                      isStudentSelected(student)
                                        ? "bg-red-400 text-white"
                                        : "bg-orange-700 hover:bg-blue-700 text-white"
                                    }`}
                                    onClick={() =>
                                      handleSelectOneStudent(student)
                                    }
                                  >
                                    {isStudentSelected(student)
                                      ? "Remove"
                                      : "Select"}
                                  </button>
                                </td>
                              </tr>
                            )
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  {/* Navigation buttons */}
                  <div className="flex justify-center">
                    <button
                      className="mr-4 bg-white hover:bg-indigo-700 hover:text-white text-orange-700 py-2 px-4 rounded-md shadow-md flex items-center"
                      onClick={prevGroup}
                    >
                      <svg
                        className="w-6 h-6 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 19l-7-7 7-7"
                        />
                      </svg>
                    </button>
                    <button
                      className="bg-white hover:bg-indigo-700 hover:text-white text-orange-700 border-gray-700 py-2 px-4 rounded-md shadow-md flex items-center"
                      onClick={nextGroup}
                    >
                      <svg
                        className="w-6 h-6 ml-2"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <div className="mb-6">
                <h3 className="text-center text-xl font-semibold mb-4 text-teal-700">
                  Details de Session
                </h3>
                <div className="mb-4">
                  <label
                    htmlFor="sessionTitle"
                    className=" text-base font-semibold"
                  >
                    Titre de la session
                  </label>
                  <input
                    type="text"
                    id="sessionTitle"
                    name="sessionTitle"
                    required
                    placeholder="Entrez le titre de la session"
                    value={sessionTitle}
                    onChange={(e) => setSessionTitle(e.target.value)}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {sessionTitleError && (
                    <p className="text-red-500">{sessionTitleError}</p>
                  )}
                </div>
                <div className="mb-4">
                  <h3 className="text-base font-semibold">
                    Sélectionner les jours
                  </h3>
                  <div className="flex flex-wrap">
                    {weekDays.map((day, index) => (
                      <label
                        key={index}
                        className="flex items-center mr-4 mb-2"
                      >
                        <input
                          type="checkbox"
                          checked={selectedDays.includes(day.value)}
                          onChange={() => handleDayChange(day.value)}
                          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                        />
                        <span className="ml-2 ">{day.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="text-base font-semibold">
                    Sélectionner la date de debut de fin de session
                  </h3>
                  <div className="mb-4 grid grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="startDate"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Start Date
                      </label>
                      <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="endDate"
                        className="block text-sm font-medium text-gray-700"
                      >
                        End Date
                      </label>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <h3 className="text-base font-semibold">
                    Entrées supplémentaires{" "}
                  </h3>
                  <div className="grid grid-cols-5 gap-4 mt-2">
                    <div>
                      <label
                        htmlFor="nbEval"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nb éval
                      </label>
                      <input
                        type="number"
                        id="nbEval"
                        name="nbEval"
                        className="mt-1 block w-20 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        value={nbEval}
                        onChange={handleNbEvalChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </DialogBody>
          {currentStep === 1 && (
            <DialogFooter>
              <Button
                className="mx-2 bg-gray-600"
                onClick={handlePrevStep}
                disabled={currentStep === 1}
              >
                Précédent
              </Button>
              <Button className=" bg-indigo-700" onClick={handleNextStep}>
                Suivant
              </Button>
            </DialogFooter>
          )}
          {currentStep === 2 && (
            <DialogFooter>
              <Button
                className="mx-2 bg-gray-600"
                onClick={handlePrevStep}
                disabled={currentStep === 1}
              >
                Précédent
              </Button>
              <Button
                className=" bg-indigo-700"
                onClick={handleNextStep}
                disabled={selectedStudents.length === 0}
              >
                Suivant
              </Button>
            </DialogFooter>
          )}
          {currentStep === 3 && (
            <DialogFooter>
              <Button
                className="mx-2 bg-gray-700"
                onClick={handlePrevStep}
                disabled={currentStep === 1}
              >
                Précédent
              </Button>
              <Button className=" bg-green-700" onClick={handleSessionCreation}>
                Soumettre
              </Button>
            </DialogFooter>
          )}
        </Dialog>
      )}
      {/* succes modal*/}

      <Dialog
        size="sm"
        open={successModalOpen}
        toggler={() => setSuccessModalOpen(false)}
      >
        <DialogHeader className="text-green-500 mr-2 flex justify-center text-center border-b border-green-600">
          <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 " />
          Succès
        </DialogHeader>
        <DialogBody>
          <div className="flex justify-center py-4 text-lg">
            Votre Session a été enregistrée avec succès.
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default CreateSessionModal;
