import React, { useState } from "react";
import logo from "../../assets/images/logo.png";

const AccountVerificationPage = () => {
  const [otp, setOtp] = useState("");
  const [resendMessage, setResendMessage] = useState("");

  const handleChange = (e) => {
    setOtp(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("OTP Submitted:", otp);
  };

  const handleResend = (e) => {
    e.preventDefault();

    console.log("OTP Resent");
    setResendMessage("Le code OTP a été renvoyé avec succès.");
    setTimeout(() => setResendMessage(""), 5000);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-100 to-orange-100">
      <div className="mb-8">
        <img src={logo} alt="Logo" className="h-16 rounded-full" />
      </div>

      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h1 className="text-2xl font-bold text-center text-blue-700 mb-4">
          Vérification De Compte{" "}
          <span className="text-orange-500">Starskills</span>
        </h1>
        <p className="text-center text-gray-600 mb-6">
          Entrez le code OTP que nous avons envoyé à votre adresse e-mail.
        </p>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="otp" className="block text-gray-700 mb-2">
              Code OTP
            </label>
            <input
              type="text"
              id="otp"
              value={otp}
              onChange={handleChange}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Entrez votre code OTP"
              maxLength="6"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-700 text-white py-2 rounded-md hover:bg-blue-800 transition duration-300"
          >
            Vérifier
          </button>
        </form>

        <p className="text-center text-gray-600 mt-4">
          Vous n'avez pas reçu le code ?{" "}
          <button
            onClick={handleResend}
            className="text-orange-500 hover:underline"
          >
            Renvoyer
          </button>
        </p>

        {resendMessage && (
          <p className="text-center text-green-500 mt-4">{resendMessage}</p>
        )}
      </div>
    </div>
  );
};

export default AccountVerificationPage;
