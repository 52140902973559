import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHistory,
  faRunning,
  faCalendarAlt,
  faTags,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import authService from "../../../../../context/authService";

const Compeval = () => {
  const [selectedBox, setSelectedBox] = useState(1);
  const [campaigns, setCampaigns] = useState([]);

  const boxColors = ["bg-blue-200", "bg-green-200", "bg-purple-200"];
  const selectedTextColors = [
    "text-blue-800",
    "text-green-800",
    "text-purple-800",
  ];
  const unselectedBgColor = "bg-gray-200";

  const handleBoxClick = (boxNumber) => {
    setSelectedBox(boxNumber);
  };

  const boxDescriptions = [
    "L'étudiant peut voir le jour, la date, la compétence ciblée ",
    "L'étudiant peut voir le jour, la date, la compétence ciblée et le professeur responsable",
    "L'étudiant peut voir le jour, la date, la compétence ciblée, le professeur responsable et valider son intégration à cette campagne",
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const sessionResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Sessions`,
        "GET"
      );
      const sessions = sessionResponse.data;
      const sessionsDetails = await Promise.all(
        sessions.map(async (session) => {
          const subSkillsList = session.List_SubSkills.join(",");
          const teacherInfoUrl = `https://api.starskills.fr/users/${session.user_created}`;
          const teacherInfoResponse =
            await authService.makeAuthenticatedRequest(teacherInfoUrl, "GET");
          const apiUrlSubSkills = `https://api.starskills.fr/items/Sessions_SubSkill?filter={"id":{"_in":"${subSkillsList}"}}`;
          const responseSubSkills = await authService.makeAuthenticatedRequest(
            apiUrlSubSkills,
            "GET"
          );
          const subSkills = responseSubSkills.data;
          const subSkillsDetails = await Promise.all(
            subSkills.map(async (subSkill) => {
              const subSkillId = subSkill.SubSkill_id;
              const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${subSkillId}`;
              const subSkillInfoResponse =
                await authService.makeAuthenticatedRequest(
                  subSkillInfoUrl,
                  "GET"
                );
              return {
                name: subSkillInfoResponse.data.Name,
              };
            })
          );
          return {
            id: session.id,
            Title: session.Title,
            teacher: `${teacherInfoResponse.data.first_name} ${teacherInfoResponse.data.last_name}`,
            session_subSkills: subSkillsDetails.map(
              (subSkill) => subSkill.name
            ),
            Start_Date: session.Start_Date,
            End_Date: session.End_Date,
          };
        })
      );
      setCampaigns(sessionsDetails);
      console.log("sesssionss", sessionsDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const currentDate = new Date();

  const pastCampaigns = campaigns.filter(
    (campaign) => new Date(campaign.End_Date) < currentDate
  );
  const currentCampaigns = campaigns.filter(
    (campaign) =>
      new Date(campaign.Start_Date) <= currentDate &&
      new Date(campaign.End_Date) >= currentDate
  );
  const futureCampaigns = campaigns.filter(
    (campaign) => new Date(campaign.Start_Date) > currentDate
  );

  const getCampaignsBySelectedBox = () => {
    switch (selectedBox) {
      case 1:
        return pastCampaigns;
      case 2:
        return currentCampaigns;
      case 3:
        return futureCampaigns;
      default:
        return [];
    }
  };

  const filteredCampaigns = getCampaignsBySelectedBox();

  return (
    <div className="container mx-auto mb-8 p-8 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">
        Visualiser et préparer ta prochaine campagne starkills 📚
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {[1, 2, 3].map((boxNumber) => (
          <div
            key={boxNumber}
            className={`p-6 border rounded-md cursor-pointer ${
              selectedBox === boxNumber
                ? boxColors[boxNumber - 1]
                : unselectedBgColor
            } ${selectedBox === boxNumber ? "shadow-lg" : "shadow-md"}`}
            onClick={() => handleBoxClick(boxNumber)}
          >
            <h3
              className={`text-xl font-semibold mb-2 ${
                selectedBox === boxNumber
                  ? selectedTextColors[boxNumber - 1]
                  : "text-gray-800"
              }`}
            >
              {boxNumber === 1 ? (
                <>
                  <FontAwesomeIcon icon={faHistory} className="mr-2" /> Campagne
                  précédente
                </>
              ) : boxNumber === 2 ? (
                <>
                  <FontAwesomeIcon icon={faRunning} className="mr-2" /> Campagne
                  en cours
                </>
              ) : boxNumber === 3 ? (
                <>
                  <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />{" "}
                  Campagne future
                </>
              ) : (
                ""
              )}
            </h3>
          </div>
        ))}
      </div>

      <div className="mt-4">
        {selectedBox && (
          <>
            <p className={`text-base ${selectedTextColors[selectedBox - 1]}`}>
              {boxDescriptions[selectedBox - 1]}
            </p>
            <ul className="mt-6">
              {filteredCampaigns.length === 0 ? (
                <p className="text-center text-gray-600 text-lg">
                  Aucune campagne disponible pour cette catégorie.
                </p>
              ) : (
                filteredCampaigns.map((campaign) => (
                  <div key={campaign.id}>
                    <h4
                      className={`text-lg font-semibold mt-4 mb-2 ${
                        selectedTextColors[selectedBox - 1]
                      }`}
                    >
                      <FontAwesomeIcon icon={faBook} className="mr-2" />
                      {campaign.Title}
                    </h4>
                    <li className="mb-4">
                      <div className="bg-white shadow-md p-4 rounded-lg">
                        <p>
                          <strong>Date de début :</strong>{" "}
                          {new Date(campaign.Start_Date).toLocaleDateString()} |{" "}
                          <strong>Date de fin :</strong>{" "}
                          {new Date(campaign.End_Date).toLocaleDateString()}
                        </p>
                        <p>
                          <strong>Compétences ciblées :</strong>{" "}
                          {campaign.session_subSkills.map((skill, index) => (
                            <span
                              key={index}
                              className={`inline-block text-sm font-semibold mr-2 mb-2 px-2.5 py-0.5 rounded-full ${
                                selectedTextColors[selectedBox - 1]
                              }`}
                            >
                              <FontAwesomeIcon icon={faTags} className="mr-1" />
                              {skill}
                            </span>
                          ))}
                        </p>
                        <p>
                          <strong>Enseignant :</strong> {campaign.teacher}
                        </p>
                      </div>
                    </li>
                  </div>
                ))
              )}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default Compeval;
