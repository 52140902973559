import React, { useState, useEffect } from "react";
import StepOne from "./stepOne";
import StepTwo from "./StepTwo";
import { useParams } from "react-router-dom";
import { useSession } from "../../../../../../context/SessionContext";

const CreateEvaluationPage = () => {
  const [step, setStep] = useState(1);
  const [selectedEvaluation, setSelectedEvaluation] = useState("");
  const [isAutoEvaluation, setIsAutoEvaluation] = useState(false);
  const [session, setSession] = useState(null);
  const { sessionId } = useParams();
  const { sessionDetails } = useSession();

  useEffect(() => {
    if (sessionId && sessionDetails) {
      const selectedSession = sessionDetails.find(
        (session) => session.id === sessionId
      );
      if (selectedSession) {
        setSession(selectedSession);
      }
    }
  }, [sessionId, sessionDetails]);

  const handleHasAutoEvaluation = (value) => {
    setIsAutoEvaluation(value);
  };

  return (
    <div className="p-4 bg-gray-200">
      <div className="text-center text-black text-xl text-gray-600 font-bold border-b border-gray-400 pb-4 my-4">
        {sessionId ? `Ajouter évaluation pour la session : ${sessionId}` : null}
      </div>
      {step === 1 && (
        <StepOne
          onNextStep={() => setStep((prevStep) => prevStep + 1)}
          onSelectedEvaluation={setSelectedEvaluation}
          onHasAutoEvaluation={handleHasAutoEvaluation}
        />
      )}

      {step === 2 && (
        <StepTwo
          sessionId={sessionId}
          onPrevStep={() => setStep((prevStep) => prevStep - 1)}
          selectedEvaluation={selectedEvaluation}
          onHasAutoEvaluation={isAutoEvaluation}
          sessionStudents={session ? session.session_students : []}
          sessionSubSkills={session ? session.session_subSkills : []}
        />
      )}
    </div>
  );
};

export default CreateEvaluationPage;
