import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authService from "../../../../../../context/authService";

const EvaluationLists = () => {
  const [activeTab, setActiveTab] = useState("Auto Evaluation");
  const [autoEvaluations, setAutoEvaluations] = useState([]);
  const [echangeEvaluations, setEchangeEvaluations] = useState([]);
  const [observationEvaluations, setObservationEvaluations] = useState([]);
  const [mesureEvaluations, setMesureEvaluations] = useState([]);
  const [displayedEvaluations, setDisplayedEvaluations] = useState([]);
  const [numToShow, setNumToShow] = useState(10);
  const [totalCounts, setTotalCounts] = useState({
    auto: 0,
    echange: 0,
    observation: 0,
    mesure: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAllEvaluations();
  }, []);

  const fetchAllEvaluations = async () => {
    setLoading(true);
    try {
      const autoResponse = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Evaluation?filter[Type]=Auto Evaluation",
        "GET"
      );
      const echangeResponse = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Evaluation?filter[Type]=echange",
        "GET"
      );
      const observationResponse = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Evaluation?filter[Type]=observation",
        "GET"
      );
      const mesureResponse = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Evaluation?filter[Type]=mesure",
        "GET"
      );

      const processEvaluations = async (evaluations) => {
        return Promise.all(
          evaluations.map(async (evaluation) => {
            const subSkills = await Promise.all(
              evaluation.List_SubSkills.map(async (skill) => {
                const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${skill}`;
                const subSkillInfoResponse =
                  await authService.makeAuthenticatedRequest(
                    subSkillInfoUrl,
                    "GET"
                  );
                return { name: subSkillInfoResponse.data.Name };
              })
            );
            return { ...evaluation, subSkills };
          })
        );
      };

      const autoData = await processEvaluations(autoResponse.data);
      const echangeData = await processEvaluations(echangeResponse.data);
      const observationData = await processEvaluations(
        observationResponse.data
      );
      const mesureData = await processEvaluations(mesureResponse.data);

      setAutoEvaluations(autoData);
      setEchangeEvaluations(echangeData);
      setObservationEvaluations(observationData);
      setMesureEvaluations(mesureData);

      setTotalCounts({
        auto: autoData.length,
        echange: echangeData.length,
        observation: observationData.length,
        mesure: mesureData.length,
      });

      setDisplayedEvaluations(autoData.slice(0, numToShow));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setLoading(true);
    switch (tab) {
      case "auto":
        setDisplayedEvaluations(autoEvaluations.slice(0, numToShow));
        break;
      case "echange":
        setDisplayedEvaluations(echangeEvaluations.slice(0, numToShow));
        break;
      case "observation":
        setDisplayedEvaluations(observationEvaluations.slice(0, numToShow));
        break;
      case "mesure":
        setDisplayedEvaluations(mesureEvaluations.slice(0, numToShow));
        break;
      default:
        setDisplayedEvaluations([]);
    }
    setLoading(false);
  };

  const loadMore = () => {
    setNumToShow(numToShow + 10);
    handleTabChange(activeTab);
  };

  const headerColor = {
    auto: "bg-teal-100 text-teal-600",
    echange: "bg-indigo-100 text-indigo-600",
    observation: "bg-orange-100 text-orange-600",
    mesure: "bg-green-100 text-green-600",
  };

  return (
    <div className="container mx-auto mt-4">
      <div className="flex justify-center border-b border-gray-200 mb-4">
        <button
          onClick={() => handleTabChange("auto")}
          className={`py-2 px-4 text-base font-semibold ${
            activeTab === "auto"
              ? "border-b-2 border-teal-600 text-teal-600"
              : "text-gray-500"
          }`}
        >
          Auto Évaluations ({totalCounts.auto})
        </button>
        <button
          onClick={() => handleTabChange("echange")}
          className={`py-2 px-4 text-base font-semibold ${
            activeTab === "echange"
              ? "border-b-2 border-indigo-600 text-indigo-600"
              : "text-gray-500"
          }`}
        >
          Évaluations par Échange ({totalCounts.echange})
        </button>
        <button
          onClick={() => handleTabChange("observation")}
          className={`py-2 px-4 text-base font-semibold ${
            activeTab === "observation"
              ? "border-b-2 border-orange-600 text-orange-600"
              : "text-gray-500"
          }`}
        >
          Évaluations par Observation ({totalCounts.observation})
        </button>
        <button
          onClick={() => handleTabChange("mesure")}
          className={`py-2 px-4 text-base font-semibold ${
            activeTab === "mesure"
              ? "border-b-2 border-green-600 text-green-600"
              : "text-gray-500"
          }`}
        >
          Évaluations par Mesure ({totalCounts.mesure})
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="loader">Chargement en Cours...</div>{" "}
        </div>
      ) : displayedEvaluations.length === 0 ? (
        <div className="flex justify-center items-center h-64">
          <p className="text-gray-500 text-xl">Aucune évaluation disponible</p>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-300 rounded-md shadow-md">
            <thead className={headerColor[activeTab]}>
              <tr className="border-b">
                <th className="py-2 px-4 text-left">Date d'évaluation</th>
                <th className="py-2 px-4 text-left">Compétences</th>
                <th className="py-2 px-4 text-left">Détails</th>
              </tr>
            </thead>
            <tbody>
              {displayedEvaluations.map((evaluation) => (
                <tr key={evaluation.id} className="border-b hover:bg-gray-100">
                  <td className="py-2 px-4">
                    {new Date(evaluation.date_created).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4">
                    {evaluation.subSkills.map((skill, index) => (
                      <span
                        key={index}
                        className="bg-blue-100 text-blue-800 px-2 py-1 rounded mr-2"
                      >
                        {skill.name}
                      </span>
                    ))}
                  </td>
                  <td className="py-2 px-4">
                    <Link
                      to={`/teacher_dashboard/${activeTab}_evaluation/${evaluation.id}`}
                      className="text-gray-600 hover:text-teal-800"
                    >
                      Voir Détails..
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {numToShow <
        (activeTab === "auto"
          ? autoEvaluations.length
          : activeTab === "echange"
          ? echangeEvaluations.length
          : activeTab === "observation"
          ? observationEvaluations.length
          : mesureEvaluations.length) && (
        <div className="mt-4 flex justify-center">
          <button
            className={`bg-${
              activeTab === "auto"
                ? "teal"
                : activeTab === "echange"
                ? "indigo"
                : activeTab === "observation"
                ? "orange"
                : "green"
            }-600 hover:bg-${
              activeTab === "auto"
                ? "teal"
                : activeTab === "echange"
                ? "indigo"
                : activeTab === "observation"
                ? "orange"
                : "green"
            }-700 text-white font-bold  py-2 px-4 rounded`}
            onClick={loadMore}
          >
            Charger plus d'éléments
          </button>
        </div>
      )}
    </div>
  );
};

export default EvaluationLists;
