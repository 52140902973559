import React, { useState, useEffect, useContext } from "react";
import {
  faCheck,
  faArrowLeft,
  faArrowRight,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "@mui/material/Slider";
import studentAvatar from "../../../../../../../assets/images/3.png";
import teacherAvatar from "../../../../../../../assets/images/4.png";
import AuthContext from "../../../../../../../context/AuthContext";

const EchangeContainer = ({ sessionSubSkills, sessionStudents, onChange }) => {
  const { auth } = useContext(AuthContext);
  const [currentStudentIndex, setCurrentStudentIndex] = useState(0);
  const [teacherName, setTeacherName] = useState("");
  const [students, setStudents] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(false);

  const [editMode] = useState(false);
  const [localEdit, setLocalEdit] = useState(editMode);

  const [echangeScores, setEchangeScores] = useState([]);
  const [avgScores, setAvgScores] = useState([]);

  useEffect(() => {
    if (auth) {
      setTeacherName(`${auth.first_name} ${auth.last_name}`);
    }
  }, [auth]);

  useEffect(() => {
    const initialStudents = sessionStudents.map((student) => ({
      id: student.id,
      name: `${student.first_name} ${student.last_name}`,
      avatar: studentAvatar,
      scores: sessionSubSkills.map(() => ({
        teacherScore: 0,
      })),
    }));

    const updatedEchangeScores = initialStudents.map((student) => {
      const studentScores = sessionSubSkills.map((skill) => ({
        skillId: skill.id,
        teacherScore: 0,
      }));

      const studentTotalScore = studentScores.reduce(
        (sum, score) => sum + score.teacherScore,
        0
      );
      const avgScore = studentTotalScore / studentScores.length;

      return {
        studentId: student.id,
        scores: studentScores,
        avgScore: avgScore,
      };
    });

    setStudents(initialStudents);
    setEchangeScores(updatedEchangeScores);
  }, [sessionStudents, sessionSubSkills]);

  const marks = Array.from({ length: 11 }, (_, index) => ({
    value: index * 10,
    label: `${index * 10}`,
  }));

  const valuetext = (value) => `${value}`;

  const handlePrevStudent = () => {
    setCurrentStudentIndex((prevIndex) =>
      prevIndex === 0 ? students.length - 1 : prevIndex - 1
    );
  };

  const handleNextStudent = () => {
    setCurrentStudentIndex((prevIndex) =>
      prevIndex === students.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleTeacherScoreChange = (studentIndex, skillIndex, value) => {
    const updatedEchangeScores = [...echangeScores];
    updatedEchangeScores[studentIndex].scores[skillIndex].teacherScore = value;

    // Recalculate the average score for the updated student
    const updatedStudentScores = updatedEchangeScores[studentIndex].scores;
    const totalScore = updatedStudentScores.reduce(
      (sum, score) => sum + score.teacherScore,
      0
    );
    const avgScore = totalScore / updatedStudentScores.length;

    updatedEchangeScores[studentIndex].avgScore = avgScore;

    setEchangeScores(updatedEchangeScores);

    const studentAvgScores = updatedEchangeScores.map((student) => {
      const totalScore = student.scores.reduce(
        (sum, score) => sum + score.teacherScore,
        0
      );
      return totalScore / student.scores.length;
    });

    setAvgScores(studentAvgScores);

    onChange(updatedEchangeScores);
  };

  const handleEditStudentScores = () => {
    setLocalEdit(!localEdit);
    setInputDisabled(false);
  };

  const handleEditTeacherScores = () => {
    setLocalEdit(!localEdit);
    setInputDisabled(false);
  };

  const handleCheckStudentScores = () => {
    setInputDisabled(true);
  };

  const handleCheckTeacherScores = () => {
    setInputDisabled(true);
  };

  return (
    <div className="container mx-auto text-center mt-4">
      <h1 className="text-2xl font-bold mb-2 text-white bg-purple-600 inline-block px-8 py-2 rounded-3xl">
        Evaluation par Echange
      </h1>
      <p className="text-sm mb-4">
        Sentiment- d’auto efficacité, diagnostic de compétences, en démarrage de
        processus pédagogique (plusieurs élèves)
      </p>
      <div className=" w-full flex mb-4 justify-between ">
        <button
          className="bg-orange-500 hover:bg-blue-700 text-white font-bold py-3 px-3 rounded-full mr-4 flex items-center"
          onClick={handlePrevStudent}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <span className="text-2xl font-semibold text-purple-500">
          {students[currentStudentIndex]?.name}
        </span>
        <button
          className="bg-orange-500 hover:bg-blue-700 text-white font-bold py-3 px-3 rounded-full mr-4 flex items-center"
          onClick={handleNextStudent}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>

      {students.map((student, studentIndex) => (
        <div
          key={studentIndex}
          style={{
            display: currentStudentIndex === studentIndex ? "block" : "none",
          }}
        >
          <div className="container mx-auto text-center flex">
            <div className="w-1/2 mr-4">
              <div className="border p-4 mb-4 bg-white p2 shadow">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src={student.avatar}
                    alt={student.name}
                    className="w-14 h-14 rounded-full bg-orange-200 border border-orange-300 mx-2"
                  />
                  <h2 className="text-lg font-semibold text-orange-500">
                    {student.name}
                  </h2>
                </div>

                {sessionSubSkills.map((skill, skillIndex) => (
                  <div key={skillIndex} className="flex items-center mb-4">
                    <p
                      className="mr-4 text-sm bg-orange-600 text-white p-2 py-3 my-3 rounded"
                      style={{ width: "220px" }}
                    >
                      {skill.name}
                    </p>
                    <Slider
                      className="mx-4"
                      defaultValue={student.scores[skillIndex].teacherScore}
                      getAriaValueText={valuetext}
                      aria-labelledby={`discrete-slider-${studentIndex}-${skillIndex}`}
                      valueLabelDisplay="on"
                      step={10}
                      marks={marks}
                      disabled={!localEdit || inputDisabled}
                      style={{ width: 250 }}
                    />

                    <button
                      className={`text-xl text-${
                        !localEdit || inputDisabled ? "green" : "orange"
                      }-500 font-bold rounded-full mx-4 flex items-center`}
                      onClick={() =>
                        !localEdit || inputDisabled
                          ? handleEditStudentScores()
                          : handleCheckStudentScores(studentIndex)
                      }
                    >
                      <FontAwesomeIcon
                        icon={!localEdit || inputDisabled ? faPen : faCheck}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="w-1/2 ml-4 flex justify-end">
              <div className="border bg-white shadow p-4 mb-4">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src={teacherAvatar}
                    alt="Teacher"
                    className="w-14 h-14 rounded-full bg-purple-200 border border-purple-300 mx-2"
                  />
                  <h2 className="text-lg font-semibold text-purple-500 mr-4">
                    {teacherName}
                  </h2>
                </div>

                {sessionSubSkills.map((skill, skillIndex) => (
                  <div key={skillIndex} className="flex items-center mb-4">
                    <button
                      className={`text-xl text-${
                        !localEdit || inputDisabled ? "green" : "purple"
                      }-500 font-bold rounded-full mx-4 flex items-center`}
                      onClick={() =>
                        !localEdit || inputDisabled
                          ? handleEditTeacherScores()
                          : handleCheckTeacherScores(studentIndex)
                      }
                    >
                      <FontAwesomeIcon
                        icon={!localEdit || inputDisabled ? faPen : faCheck}
                      />
                    </button>

                    <Slider
                      defaultValue={student.scores[skillIndex].teacherScore}
                      getAriaValueText={valuetext}
                      aria-labelledby={`discrete-slider-teacher-${skillIndex}`}
                      step={10}
                      valueLabelDisplay="on"
                      className="mx-4"
                      marks={marks}
                      onChange={(event, value) =>
                        handleTeacherScoreChange(
                          studentIndex,
                          skillIndex,
                          value
                        )
                      }
                      disabled={!localEdit || inputDisabled}
                      style={{ width: 250 }}
                    />
                    <p
                      className="ml-4 text-sm bg-purple-600 text-white p-2 py-3 my-3 rounded"
                      style={{ width: "220px" }}
                    >
                      {skill.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EchangeContainer;
