import React, { createContext, useState, useEffect, useContext } from "react";

const SessionContext = createContext({});

export const SessionProvider = ({ children }) => {
  // Load session details from localStorage on component mount
  const [sessionDetails, setSessionDetails] = useState(
    JSON.parse(localStorage.getItem("sessionDetails")) || []
  );

  // Update local storage whenever sessionDetails change
  useEffect(() => {
    localStorage.setItem("sessionDetails", JSON.stringify(sessionDetails));
  }, [sessionDetails]);

  return (
    <SessionContext.Provider value={{ sessionDetails, setSessionDetails }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
