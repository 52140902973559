import React from "react";
import { Route, Routes } from "react-router-dom";
import Message from "../Message";
import Competence from "../competence";
import StudentElavaluation from "../evaluation/StudentElavaluation";
import Progression from "../progression";
import Feedback from "../feedback";
import Quize from "../quize";

import RequireAuth from "../../../../Auth/RequireAuth";
import StudentProfile from "../profile/Profile";
import Compeval from "../campagneEval/compeval";

const StudentRoutes = ({ authId }) => {
  const ROLES = {
    Student: "Student",
  };
  return (
    <Routes>
      <Route element={<RequireAuth allowedRoles={[ROLES.Student]} />}>
        <Route path={`/${authId}`} element={<Message />} />
        <Route path={`/${authId}/competence`} element={<Competence />} />
        <Route
          path={`/${authId}/auto_evaluation`}
          element={<StudentElavaluation />}
        />
        <Route path={`/${authId}/progression`} element={<Progression />} />
        <Route path={`/${authId}/feedback`} element={<Feedback />} />
        <Route path={`/${authId}/quize`} element={<Quize />} />

        <Route path={`/${authId}/compeval`} element={<Compeval />} />
        <Route path={`/${authId}/mon_profil`} element={<StudentProfile />} />
      </Route>
    </Routes>
  );
};

export default StudentRoutes;
