import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
      
        <div className="footer-column">
          <h4 className="footer-title">Suivez-nous</h4>
          <div className="social-icons">
            <a href="#"><i className="fab fa-facebook"></i> Facebook</a>
            <a href="#"><i className="fab fa-instagram"></i> LinkdIn</a>
            <a href="#"><i className="fab fa-instagram"></i> Youtube</a>
          </div>
        </div>
        <div className="footer-column">
          <h4 className="footer-title">Conditions</h4>
          <ul className="footer-links">
            <li><a href="/policy/1">Politique de Confidentialité</a></li>
            <li><a href="/policy/2">Mentions legales</a></li>
            <li><a href="/policy/3">Conditions</a></li>
            <li><a href="/policy/4">Politique sur les Cookies</a></li>
            <li><a href="/policy/3">RGPD</a></li>

          </ul>
        </div>
      </div>
    
      <div className="footer-row">
       
        <div className="footer-column">
          <h4 className="footer-title">© 2024 Starskills</h4>
        </div>
      </div>
    </footer>
  );
};

export default Footer;