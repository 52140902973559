import React, { useState } from 'react';
import Link,{ useNavigate } from 'react-router-dom';
import Navbar from '../../../components/Home/Navbar/Navbar';

import edu from '../../../assets/images/edu.png';
import org from '../../../assets/images/org.svg';

const organizationOptions = [
  { value: 'Company', label: 'Company' },
  { value: 'LocalAuthorities', label: 'Local authorities' },
  { value: 'Associations', label: 'Associations' },
];

const educationOptions = [
  { value: 'Ecole_maternelle', label: 'Ecole maternelle' },
  { value: 'Colleges', label: 'Colleges' },
  { value: 'Lycées', label: 'Lycées' },
  { value: 'Université', label: 'Université' },
  { value: 'Centre_de_formation_professionnel', label: 'Centre de formation professionnel' },
  { value: 'Ecoles_de_commerce', label: 'Ecoles de commerce' },
  { value: 'Ecoles_d_ingénieurs', label: 'Ecoles d\'ingénieurs' },
  { value: 'Ecoles_spécialisés', label: 'Ecoles spécialisés' },
  { value: 'Autres', label: 'Autres' },
];

const Initial = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [position, setPosition] = useState('');

  const [onboardingData, setOnboardingData] = useState({
    selectedOption: null,
    organizationName: '',
    position: '',
    parcoursEtucatif: '',
    secteur: '',
  });

  const handleStepData = (stepData) => {
    setOnboardingData((prevData) => ({
      ...prevData,
      ...stepData,
    }));
  };

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = () => {
    console.log('Onboarding Form Data:', onboardingData);
    const userRole = onboardingData.selectedOption;
    if (userRole === 'education') {
      navigate('/student_dashboard');
    } else if (userRole === 'organization') {
      navigate('/teacher_dashboard');
    }
  };
  const handleChange = (field, value) => {
    setOnboardingData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setOnboardingData((prevData) => ({
      ...prevData,
      selectedOption: option,
    }));
  };

  const handleOrganizationClick = (organizationType) => {
    setSelectedOrganization(organizationType);
    setOnboardingData((prevData) => ({
      ...prevData,
      secteur: organizationType,
    }));
  };

  const renderOptions = selectedOption === 'organization' ? organizationOptions : educationOptions;

  const isButtonDisabled = !selectedOption;
  const isNextDisabled = !selectedOrganization;
//step3 
const [searchValue, setSearchValue] = useState('');
const [selectedParcours, setSelectedParcours] = useState(null);
const [selectedSecteur, setSelectedSecteur] = useState(null);

const parcoursOptions = [
  { value: 'mathematiques', label: 'Mathématiques' },
  { value: 'statistiques', label: 'Statistiques' },
  { value: 'sciences_biologiques', label: 'Sciences biologiques' },
  { value: 'sciences_education', label: 'Sciences de l’éducation' },
  { value: 'philosophie', label: 'Philosophie' },
  { value: 'linguistique', label: 'Linguistique' },
  { value: 'neurosciences', label: 'Neurosciences' },
  { value: 'psychologie', label: 'Psychologie' },
  { value: 'sociologie', label: 'Sociologie' },
  { value: 'culture', label: 'Culture' },
  { value: 'biochimie', label: 'Biochimie' },
  { value: 'mecanique', label: 'Mécanique' },
  { value: 'genie_mecanique', label: 'Génie mécanique' },
  { value: 'medecine', label: 'Médecine' },
  { value: 'astronomie', label: 'Astronomie' },
  { value: 'litterature', label: 'Littérature' },
  { value: 'droit', label: 'Droit' },
  { value: 'histoire', label: 'Histoire' },
  { value: 'sciences_economiques', label: 'Sciences économiques' },
  { value: 'sciences_gestion', label: 'Sciences de gestion' },
  { value: 'sciences_comptabilite', label: 'Sciences de la comptabilité' },
  { value: 'sciences_politique', label: 'Sciences politique' },
  { value: 'informatique', label: 'Informatique' },
  { value: 'geographie', label: 'Géographie' },
  { value: 'autres', label: 'Autres...' },
];


const secteurOptions = [
  { value: 'informatique', label: 'Informatique' },
  { value: 'numerique', label: 'Numérique' },
  { value: 'banque_assurance', label: 'Banque/Assurance' },
  { value: 'services_personne', label: 'Services à la personne' },
  { value: 'electronique_electricite', label: 'Électronique/Électricité' },
  { value: 'agroalimentaire', label: 'Agroalimentaire' },
  { value: 'btp', label: 'BTP' },
  { value: 'chimie', label: 'Chimie' },
  { value: 'parachimie', label: 'Parachimie' },
  { value: 'industrie', label: 'Industrie' },
  { value: 'commerce_distribution', label: 'Commerce/Distribution' },
  { value: 'comptabilite_finance', label: 'Comptabilité/Finance' },
  { value: 'etudes_conseils', label: 'Études et conseils' },
  { value: 'bois_papier_carton_imprimerie', label: 'Bois/Papier/Carton/Imprimerie' },
  { value: 'machines_equipement', label: 'Machines/Équipement' },
  { value: 'technologie_innovation', label: 'Technologie/Innovation' },
  { value: 'gestion_management', label: 'Gestion/Management' },
  { value: 'transport_logistique', label: 'Transport/Logistique' },
  { value: 'textile_habillement', label: 'Textile/Habillement' },
  { value: 'automobile', label: 'Automobile' },
  { value: 'services_entreprises', label: 'Services aux entreprises' },
  { value: 'metallurgie', label: 'Métallurgie' },
  { value: 'agriculture_peche', label: 'Agriculture/Pêche' },
  { value: 'services_particuliers', label: 'Services aux particuliers' },
  { value: 'edition_multimedia', label: 'Édition/Multimédia' },
  { value: 'communication', label: 'Communication' },
  { value: 'autres', label: 'Autres...' },
];


const filteredParcoursOptions = parcoursOptions.filter((option) =>
  option.label.toLowerCase().includes(searchValue.toLowerCase())
);

const filteredSecteurOptions = secteurOptions.filter((option) =>
  option.label.toLowerCase().includes(searchValue.toLowerCase())
);

const handleParcoursChange = (selectedOption) => {
  setSelectedParcours(selectedOption.value);
};

const handleSecteurChange = (selectedOption) => {
  setSelectedSecteur(selectedOption.value); 
};



  return (
    <>
      <Navbar />
      {step === 1 && (
        <div>
          <div className="flex flex-col mt-24 items-center justify-center mx-4 sm:mx-20">
            <h2 className="text-xl sm:text-3xl font-semibold text-center">
              Personnalisez votre expérience
            </h2>
            <h2 className="text-sm sm:text-xl text-gray-700 font-semibold mb-4 sm:mb-8 mt-2 text-center">
              Pour quoi allez-vous utiliser STARSKILLS
            </h2>
            <div className="flex flex-wrap gap-8 flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0">
              <div
                onClick={() => handleOptionSelect('education')}
                className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-blue-400 active:scale-95 ${
                  selectedOption === 'education' ? 'border-blue-500 bg-blue-400' : ' bg-white'
                }`}
                style={{ width: '350px', cursor: 'pointer' }}
              >
                <img
                  src={edu}
                  alt="Box 1"
                  className="w-30 h-30 sm:w-30 sm:h-30 mb-2 sm:mb-4 group-hover:opacity-80"
                />
                <p className="text-black text-center font-semibold mt-2 sm:mt-4">
                  Je veux utiliser STARSKILLS dans le domaine éducatif, la formation ou l’enseignement
                </p>
              </div>
              <div
                onClick={() => handleOptionSelect('organization')}
                className={`group flex flex-col items-center justify-center p-4 sm:p-8 ring-2 ring-gray-400 rounded-lg shadow-lg hover:bg-orange-400 active:scale-95 ${
                  selectedOption === 'organization' ? 'border-orange-400 bg-orange-400' : ' bg-white'
                }`}
                style={{ width: '350px', cursor: 'pointer' }}
              >
                <img
                  src={org}
                  alt="Box 2"
                  className="w-30 h-30 sm:w-30 sm:h-30 mb-2 sm:mb-4 group-hover:opacity-80"
                />
                <p className="text-black text-center font-semibold mt-2 sm:mt-4">
                  Je veux utiliser STARSKILLS pour la formation en entreprise, en collectivités ou autres organisations
                </p>
              </div>
            </div>
            <div className="my-10 flex justify-between">
              <button
                disabled={isButtonDisabled}
                onClick={handleNextStep}
                className={`block rounded-md px-3.5 py-2.5 text-center text-base font-semibold text-white px-8 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                  isButtonDisabled
                    ? 'bg-gray-400 cursor-not-allowed'
                    : 'bg-indigo-700 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                }`}
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex flex-col mt-24 items-center justify-center mx-4 sm:mx-20">
          <h2 className="text-xl sm:text-3xl font-semibold text-center">
            Personnalisez votre expérience
          </h2>
          <h2 className="text-sm sm:text-xl text-gray-700 font-semibold mb-4 sm:mb-8 mt-2 text-center">
            Pour quoi allez-vous utiliser STARSKILLS
          </h2>
          <div>
            <h2 className="text-3xl text-indigo-700 font-semibold mb-4 sm:mb-8 mt-4 text-center">
              {selectedOption === 'organization'
                ? 'Which type of organization?'
                : 'What type of establishment are you?'}
            </h2>
            <div className="grid grid-cols-1 gap-x-8 sm:grid-cols-3">
              {renderOptions.map((option) => (
                <div
                  key={option.value}
                  className={`border-indigo-700 text-gray-800 text-center border-2 text-xl py-6 hover:bg-indigo-700 hover:text-white p-4 rounded-md mt-4 cursor-pointer ${
                    selectedOrganization === option.value && 'bg-indigo-700 text-white'
                  }`}
                  onClick={() => handleOrganizationClick(option.value)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
          <div className="my-10 flex justify-between">
            <button
              type="button"
              onClick={handlePrevStep}
              className="block w-1/2 rounded-md bg-gray-300 px-3.5 py-2.5 text-center text-base font-semibold text-indigo-700 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            >
              Revenir
            </button>
            <button
              type="button"
              onClick={handleNextStep}
              disabled={isNextDisabled}
              className={`block w-1/2 rounded-md px-3.5 py-2.5 ml-4 text-center text-base font-semibold shadow-sm ${
                isNextDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 text-white'
              }`}
            >
              Suivant
            </button>
          </div>
        </div>
      )}

      {step === 3 && (
          <div className="flex flex-col mt-24 items-center justify-center mx-4 sm:mx-20">
          <h2 className="text-xl sm:text-3xl font-semibold text-center">
            Personnalisez votre expérience
          </h2>
          <h2 className="text-sm sm:text-xl text-gray-700 font-semibold mb-4 sm:mb-8 mt-2 text-center">
            Pour quoi allez-vous utiliser STARTSKILLS
          </h2>
          <h2 className="text-3xl text-indigo-700 font-semibold mb-2 mt-4 text-center">
          Which field are interested in?
            </h2>
  
            <input
            type="text"
            className="w-72 rounded-md border-0 px-8 py-4 text-gray-400 shadow-sm ring-2 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6 appearance-none"
            placeholder="Rechercher..."
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
          />
  
          {/* Options */}
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3 mt-4">
          {selectedOption === 'education' && filteredParcoursOptions.map(option => (
            <div key={option.value} className="border border-indigo-700  rounded-md">
              <label className={`flex items-center text-gray-800 justify-center  p-4 hover:text-white hover:bg-indigo-700 cursor-pointer transition-all ${selectedParcours === option.value ? 'text-white bg-indigo-700' : ''}`}>
                <input
                  type="radio"
                  name="parcours_etucatif"
                  value={option.value}
                  onChange={() => handleParcoursChange(option)}
                  className="sr-only"
                  checked={selectedParcours === option.value}
                />
                <div className="p-2 rounded-md">
                  {option.label}
                </div>
              </label>
            </div>
          ))}
          {selectedOption === 'organization' && filteredSecteurOptions.map(option => (
            <div key={option.value} className="border border-indigo-700  rounded-md">
              <label className={`flex items-center text-gray-800 justify-center p-4 hover:text-white hover:bg-indigo-700 cursor-pointer transition-all ${selectedSecteur === option.value ? 'text-white bg-indigo-700' : ''}`}>
                <input
                  type="radio"
                  name="secteur"
                  value={option.value}
                  onChange={() => handleSecteurChange(option)}
                  className="sr-only"
                  checked={selectedSecteur === option.value}
                />
                <div className="p-2 rounded-md">
                  {option.label}
                </div>
              </label>
            </div>
          ))}
        </div>
          <div className="my-10 flex justify-between">
          <button
              type="button"
              onClick={handlePrevStep}
              className="block w-1/2 rounded-md bg-gray-300 px-3.5 py-2.5 text-center text-base font-semibold text-indigo-700 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            >
              Revenir
            </button>
  
          <button
            type="button"
            onClick={handleNextStep}
            className="block w-1/2 rounded-md bg-indigo-600 px-3.5 py-2.5 ml-4 text-center text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Suivant
          </button>
        </div>
        </div>
      )}

      {step === 4 && (
          <div className="flex flex-col mt-24 items-center justify-center mx-4 sm:mx-20">
          <h2 className="text-xl sm:text-3xl font-semibold  text-center">
            Personnalisez votre expérience
          </h2>
          <h2 className="text-sm sm:text-xl text-gray-700 font-semibold mb-4 sm:mb-8 mt-2 text-center">
            Pour quoi allez-vous utiliser STARSKILLS
          </h2>
          <div className="flex flex-wrap gap-8 flex-col sm:flex-row space-y-4 sm:space-y-0 space-x-0"></div>
          <div className="flex flex-col-reverse sm:flex-row mx-auto mt-16 w-full max-w-screen-xl sm:mt-20">
            <div className="sm:w-1/2 sm:relative mt-2">
              <h1 className="text-6xl font-semibold py-19 leading-8 text-indigo-600">
                <div >Facile.</div>
                <div className="mt-8">très facile</div>
              </h1>
              <h3 className="mt-6 text-xl text-gray-800 leading-1  ">
                Lancez-vous avec l’un des plus de 10.000 Référentiels conçus par des professionnels.
              </h3>
            </div>
  
            {/* Form */}
            <form className="mx-auto sm:w-1/2 sm:ml-10 max-w-xl">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-2">
                  <label htmlFor="organization" className="block text-base font-semibold leading-6 text-gray-900">
                    Nom de votre organisation / école ? (facultative)
                  </label>
                  <div className="mt-2.5">
                    <input
                      type="text"
                      name="organization"
                      placeholder='Nom de votre organisation / école '
                      id="organization"
                      value={organizationName}
                      onChange={(e) => handleChange('organizationName', e.target.value)}
                      autoComplete="organization"
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="position" className="block text-base font-semibold leading-6 text-gray-900">
                    Votre poste, par exemple (Enseignant / CEO ..)
                  </label>
                  <div className="relative mt-2.5">
                    <div className="absolute inset-y-0 left-0 flex items-center"></div>
                    <input
                      type="text"
                      name="position"
                      id="position"
                      placeholder=' Votre poste (Enseignant / CEO ..)'
                      autoComplete="position"
                      value={position}
                    onChange={(e) => handleChange('position', e.target.value)}
                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
         <div className="my-10 flex justify-between">
         <button
              type="button"
              onClick={handlePrevStep}
              className="block w-1/2 rounded-md bg-gray-300 px-3.5 py-2.5 text-center text-base font-semibold text-indigo-700 shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
            >
              Revenir
            </button>
  
            <button
              type="button"
              onClick={handleSubmit}
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 ml-4 text-center text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
             Soumettre
            </button>
        </div>
        </div>
      )}
    </>
  );
};

export default Initial;
