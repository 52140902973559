import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ObservationContainer from "./components/ObservationContainer";
import EchangeContainer from "./components/EchangeContainer";
import MesureContainer from "./components/MesureContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import authService from "../../../../../../context/authService";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

const StepTwo = ({
  onPrevStep,
  selectedEvaluation,
  sessionStudents,
  sessionSubSkills,
  onHasAutoEvaluation,
  sessionId,
}) => {
  const [submittedScores, setSubmittedScores] = useState([]);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [questionList, setQuestionList] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {}, [submittedScores]);

  const handleCreateEvaluation = async () => {
    console.log("Session ID:", sessionId);
    console.log(
      " session SubSkills IDs:",
      sessionSubSkills.map((skill) => skill.id)
    );
    console.log(
      " session Students IDs:",
      sessionStudents.map((student) => student.id)
    );
    console.log("Selected Evaluation:", selectedEvaluation);
    console.log("Is Auto Evaluation:", onHasAutoEvaluation);
    console.log("Evaluation details:", submittedScores);
    console.log("Final Question List:", questionList);

    try {
      if (onHasAutoEvaluation) {
        const Data = {
          Session: sessionId,
          Type: "Auto Evaluation",
          List_SubSkills: sessionSubSkills.map((skill) => skill.id),
        };
        const apiUrlEvaluation = `https://api.starskills.fr/items/Evaluation`;
        const responseEvaluation = await authService.makeAuthenticatedRequest(
          apiUrlEvaluation,
          "POST",
          Data
        );

        for (const student of sessionStudents) {
          for (const subSkill of sessionSubSkills) {
            const scoreData = {
              Student_id: student.id,
              Evaluation_id: responseEvaluation.data.id,
              Status: "Incomplete",
              SubSkill: subSkill.id,
            };
            const scoreResponse = await authService.makeAuthenticatedRequest(
              "https://api.starskills.fr/items/Evaluation_Student",
              "POST",
              scoreData
            );
          }
        }
      }
      const Data = {
        Session: sessionId,
        Type: selectedEvaluation,
        List_SubSkills: sessionSubSkills.map((skill) => skill.id),
      };
      const apiUrlsession = `https://api.starskills.fr/items/Evaluation`;
      const response = await authService.makeAuthenticatedRequest(
        apiUrlsession,
        "POST",
        Data
      );

      if (selectedEvaluation == "mesure") {
        for (const quiz of questionList) {
          const quizData = {
            Evaluation: response.data.id,
            Question: quiz.question,
          };
          const apiUrlsession = `https://api.starskills.fr/items/Quiz`;
          const quizresponse = await authService.makeAuthenticatedRequest(
            apiUrlsession,
            "POST",
            quizData
          );
          for (const reponse of quiz.options) {
            const reponseData = {
              Question: quizresponse.data.id,
              Text: reponse,
            };
            const apiUrlsession = `https://api.starskills.fr/items/Answers`;
            const response2 = await authService.makeAuthenticatedRequest(
              apiUrlsession,
              "POST",
              reponseData
            );
          }
        }
      } else {
        for (const student of submittedScores) {
          for (const score of student.scores) {
            const studentData = {
              Student_id: student.studentId,
              Evaluation_id: response.data.id,
              Score: score.score,
              SubSkill: score.skillId,
            };
            await authService.makeAuthenticatedRequest(
              "https://api.starskills.fr/items/Evaluation_Student",
              "POST",
              studentData
            );
          }
        }
      }
      setSuccessModalOpen(true);

      setTimeout(() => {
        navigate("/teacher_dashboard/mes_campagnes", { replace: true });
      }, 2000);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="container mx-auto mt-4 text-center">
      {selectedEvaluation === "observation" && (
        <ObservationContainer
          sessionSubSkills={sessionSubSkills}
          sessionStudents={sessionStudents}
          onChange={setSubmittedScores}
        />
      )}
      {selectedEvaluation === "echange" && (
        <EchangeContainer
          sessionSubSkills={sessionSubSkills}
          sessionStudents={sessionStudents}
          onChange={setSubmittedScores}
        />
      )}
      {selectedEvaluation === "mesure" && (
        <MesureContainer
          selectedEvaluation={selectedEvaluation}
          sessionSubSkills={sessionSubSkills}
          sessionStudents={sessionStudents}
          onSubmit={setQuestionList}
        />
      )}

      <Dialog
        size="sm"
        open={successModalOpen}
        toggler={() => setSuccessModalOpen(false)}
      >
        <DialogHeader className="text-green-500 mr-2 flex justify-center text-center border-b border-green-600">
          <FontAwesomeIcon icon={faCheck} className="text-green-500 mr-2 " />
          Succès
        </DialogHeader>
        <DialogBody>
          <div className="flex justify-center py-4 text-lg">
            Votre évaluation a été enregistrée avec succès.
          </div>
        </DialogBody>
      </Dialog>

      <div className="flex justify-center mt-8">
        <button
          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-3 px-3 rounded-full mr-4 flex items-center"
          onClick={onPrevStep}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Précédent
        </button>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-3 rounded-full mr-4 flex items-center"
          onClick={handleCreateEvaluation}
        >
          Soumettre
          <FontAwesomeIcon icon={faCheck} className="ml-2" />
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
