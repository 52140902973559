import * as React from "react";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";
import authService from "../../../../../context/authService";

const initialValue = dayjs();

function ServerDay(props) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isHighlighted =
    !props.outsideCurrentMonth && highlightedDays.includes(day.date());

  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={isHighlighted ? "🌟" : undefined}
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        sx={{
          backgroundColor: isHighlighted ? "red" : "transparent",
          color: isHighlighted ? "white" : "inherit",
          "&:hover": {
            backgroundColor: isHighlighted ? "darkred" : "rgba(0, 0, 0, 0.08)",
          },
        }}
      />
    </Badge>
  );
}

export default function DateCalendarServerRequest() {
  const requestAbortController = React.useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [highlightedDays, setHighlightedDays] = React.useState([]);

  const fetchSessions = async () => {
    try {
      const sessionResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Sessions`,
        "GET"
      );
      const sessions = sessionResponse.data.map((session) => ({
        ...session,
        Start_Date: dayjs(new Date(session.Start_Date)),
      }));
      return sessions
        .filter(
          (session) => session.Start_Date.month() === initialValue.month()
        )
        .map((session) => session.Start_Date.date());
    } catch (error) {
      console.error("Error fetching sessions:", error);
      return [];
    }
  };

  const fetchHighlightedDays = async (date) => {
    setIsLoading(true);
    try {
      const daysToHighlight = await fetchSessions();
      setHighlightedDays(daysToHighlight);
    } catch (error) {
      console.error("Error fetching highlighted days:", error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchHighlightedDays(initialValue);
    return () => requestAbortController.current?.abort();
  }, []);

  const handleMonthChange = (date) => {
    fetchHighlightedDays(date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="bg-blue-300 p-4 rounded-lg">
        <h4 className="text-lg text-white font-semibold mb-2">Calendrier</h4>
        <DateCalendar
          defaultValue={initialValue}
          loading={isLoading}
          onMonthChange={handleMonthChange}
          renderLoading={() => <DayCalendarSkeleton />}
          slots={{
            day: ServerDay,
          }}
          slotProps={{
            day: {
              highlightedDays,
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
