import React, { useState, useEffect } from "react";
import avatar from "../../../../../../../assets/images/5.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Modal from "./quize/modal"; // Adjust the import path as needed
import sendEmail from "./quize/sendmail"; // Adjust the import path as needed

const MesureContainer = ({ sessionSubSkills, sessionStudents, onSubmit }) => {
  const [students, setStudents] = useState([]);
  const [studentEmails, setStudentEmails] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState(["", ""]);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const initialStudents = sessionStudents.map((student, index) => ({
      id: index + 1,
      name: `${student.first_name} ${student.last_name}`,
      avatar: avatar,
      scores: sessionSubSkills.map(() => ({
        quizType: null,
      })),
    }));
    setStudents(initialStudents);
    console.log("Initial students set:", initialStudents);

    const emails = sessionStudents.map(student => student.email);
    setStudentEmails(emails);
    console.log("Student emails set:", emails);
  }, [sessionStudents, sessionSubSkills]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newQuestion = {
      question: question,
      options: options,
      correctAnswer: options[correctAnswer],
    };

    setQuestionList([...questionList, newQuestion]);
    console.log("Question added:", newQuestion);
    console.log("Updated question list:", [...questionList, newQuestion]);

    setQuestion("");
    setOptions(["", ""]);
    setCorrectAnswer(null);
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
    console.log("Option changed:", newOptions);
  };

  const handleAddOption = () => {
    setOptions([...options, ""]);
    console.log("Option added:", [...options, ""]);
  };

  const handleRemoveOption = (index) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);
    console.log("Option removed:", newOptions);
  };

  const handleTerminate = () => {
    console.log("Final question list submitted:", questionList);
    onSubmit(questionList);
  };

  const handleSendEmail = async () => {
    const subject = "Quiz Question List";
    const text = `Here is the quiz question list: ${JSON.stringify(questionList, null, 2)}`;
    const html = `<h2>Quiz Question List</h2><pre>${JSON.stringify(questionList, null, 2)}</pre>`;

    try {
      const emailPromises = studentEmails.map(email => sendEmail(email, subject, text, html));
      await Promise.all(emailPromises);
      setMessage("Emails sent successfully!");
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while sending emails.");
    }
    setShowModal(false);
  };

  return (
    <div className="flex flex-col mt-14 items-center justify-center mx-4 sm:mx-20">
      <h1 className="text-2xl font-bold mb-2 text-white bg-orange-600 inline-block px-6 py-2 rounded-3xl">
        Mesure
      </h1>
      <h2 className="text-2xl text-indigo-700 font-semibold mb-2 mt-4 text-center">
        Start Quiz Question List
      </h2>
      <form onSubmit={handleSubmit} className="w-full max-w-lg">
        <div>
          <label htmlFor="question" className="block text-gray-700 font-semibold">
            Question
          </label>
          <textarea
            id="question"
            className="w-full border rounded-md px-4 py-2 focus:outline-none focus:ring focus:border-indigo-500"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold">Options</label>
          {options.map((option, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                className="w-full border rounded-md px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-indigo-500"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                required
              />
              <input
                type="radio"
                name="correctAnswer"
                checked={correctAnswer === index}
                onChange={() => setCorrectAnswer(index)}
              />
              <button
                type="button"
                className="bg-red-500 text-white px-3 py-1 rounded-md focus:outline-none ml-2"
                onClick={() => handleRemoveOption(index)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          ))}
          <button
            type="button"
            className="bg-green-500 text-white px-3 py-1 rounded-md focus:outline-none"
            onClick={handleAddOption}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="m-4 bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-green-400 focus:outline-none"
          >
            Add Question
          </button>
          <button
            type="button"
            className="m-4 bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 focus:outline-none"
            onClick={handleTerminate}
          >
            Validate
          </button>
          <button
            type="button"
            className="m-4 bg-purple-500 text-white px-6 py-3 rounded-md hover:bg-purple-600 focus:outline-none"
            onClick={() => setShowModal(true)}
          >
            Send Email
          </button>
        </div>
      </form>
      {message && <p>{message}</p>}
      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        teacherName="John Doe" // Replace with actual teacher name
        sessionDetails="Session 1" // Replace with actual session details
        evaluationType="Quiz" // Replace with actual evaluation type
        questionList={questionList}
        studentEmails={studentEmails}
        onSendEmail={handleSendEmail}
      />
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Final Question List:</h2>
        {questionList && questionList.length > 0 ? (
          <ul>
            {questionList.map((question, index) => (
              <li key={index}>
                <div>
                  <p className="font-semibold">Question: {question.question}</p>
                  <ul>
                    {question.options.map((option, i) => (
                      <li key={i}>{option}</li>
                    ))}
                  </ul>
                  <p className="font-semibold">Correct Answer: {question.correctAnswer}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No questions added yet.</p>
        )}
      </div>
    </div>
  );
};

export default MesureContainer;
