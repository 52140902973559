import React, { useContext, useEffect, useState, Fragment } from "react";
import { useNavigate, Route, Routes, Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassCircleIcon,
  MagnifyingGlassIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import CalendarToggle from "../components/calander_toggle";
import AuthContext from "../../../../../../context/AuthContext";
import TeacherOrgRoutes from "../../routes/Teacher_routes";
import authService from "../../../../../../context/authService";
import { FidgetSpinner, MagnifyingGlass } from "react-loader-spinner";

import TeacherSidebar from "../components/Teacher_sidebar";
import menuData from "../components/sidebar_menu_items";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TeacherMain = () => {
  const [open, setOpen] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const { auth, logout } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [niveau, setNiveau] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (auth) {
      setEmail(auth.email);
      setFirstName(auth.first_name);
      setLastName(auth.last_name);
    }
  }, [auth]);

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

  const isCompagneManager = () => {
    if (auth.role === "Teacher") {
      return false;
    }
    if (auth.role === "Compagne Manager") {
      return true;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authService.accessToken || authService.isAccessTokenExpired()) {
          await authService.refreshAccessToken();
        }
        const apiUrlniveau = `https://api.starskills.fr/items/Niveau`;
        const responseGroups = await authService.makeAuthenticatedRequest(
          apiUrlniveau,
          "GET"
        );
        console.log(responseGroups.data);
        setNiveau(responseGroups.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [auth]);

  const menus = menuData(niveau); // menuData

  return (
    <div>
      <div className="flex relativ">
        <TeacherSidebar
          open={open}
          setOpen={setOpen}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          openSubMenu={openSubMenu}
          setOpenSubMenu={setOpenSubMenu}
          niveau={niveau}
          auth={auth}
          isCompagneManager={isCompagneManager}
        />

        <div className="h-screen flex-1 p-7 bg-white">
          <div>
            <div className="flex items-center justify-between space-x-4">
              <h1 className="text-lg font-semibold">
                {menus.find((menu) => menu.id === selectedMenuItem)?.title ||
                  "Default Title"}
              </h1>

              {/* Calendar Toggle, Selects, and Search */}
              <div className="flex items-center space-x-4">
                <CalendarToggle />
                <select className="px-1 py-2 border rounded-3xl shadow-md focus:outline-none">
                  <option>Sort by Class</option>
                  <option>Class A</option>
                  <option>Class B</option>
                  <option>Class C</option>
                  <option>Alphabetical</option>
                </select>
                <select className="px-4 py-2 border rounded-3xl shadow-md focus:outline-none">
                  <option>Week View</option>
                  <option>Month View</option>
                  <option>Year View</option>
                </select>
                <div className="relative ">
                  <input
                    type="text"
                    placeholder="Search..."
                    className="py-2 pl-10 pr-4 border rounded-3xl shadow-md  w-40 focus:outline-none"
                  />
                  <MagnifyingGlassIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 text-gray-500" />
                </div>
              </div>

              {/* User Profile Menu */}
              <div>
                {auth ? (
                  <Menu as="div" className="relative inline-block text-left ">
                    <Link to="/teacher_dashboard/mon-profile">
                      <div>
                        <Menu.Button className="inline-flex items-center justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          <UserCircleIcon className="h-5 w-5 text-gray-800 mr-2" />
                          {firstName} {lastName}
                          <ChevronDownIcon className="-mr-1 h-5 w-5 text-blue-800" />
                        </Menu.Button>
                      </div>
                    </Link>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <div>
                                <Link
                                  to="/teacher_dashboard/mon-profile"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm hover:bg-indigo-600 hover:text-white"
                                  )}
                                >
                                  Mon Profile
                                </Link>
                                <a
                                  href="#settings"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm hover:bg-indigo-600 hover:text-white"
                                  )}
                                >
                                  Parametres
                                </a>
                                <a
                                  onClick={handleLogout}
                                  href="#logout"
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm hover:bg-indigo-600 hover:text-white cursor-pointer"
                                  )}
                                >
                                  Deconnecter
                                </a>
                              </div>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <p>No user found</p>
                )}
              </div>
            </div>

            {/* Divider */}
            <div>
              <hr className="my-4 border-t" />
            </div>
          </div>

          {loading ? (
            <div className="flex h-screen justify-center items-center">
              <FidgetSpinner
                visible={true}
                height="80"
                width="80"
                ariaLabel="fidget-spinner-loading"
                wrapperStyle={{}}
                ballColors={["#6A0DAD", "blue", "orange"]}
                backgroundColor="purple"
                wrapperClass="fidget-spinner-wrapper"
              />
            </div>
          ) : (
            <Routes>
              <Route path="/*" element={<TeacherOrgRoutes />} />
            </Routes>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherMain;
