import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import { pdf } from "@react-pdf/renderer";
import GenrateRapportPdf from "./components/generate_rapport_pdf";

const RapportView = () => {
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("rapport");
  const [currentSkillIndex, setCurrentSkillIndex] = useState(0);
  const { sessionId } = useParams();

  const fetchSession = async (sessionId) => {
    try {
      console.log("sessionId:", sessionId);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Erreur lors de la récupération de la session :", error);
    }
  };

  useEffect(() => {
    fetchSession(sessionId);
  }, [sessionId]);

  const students = [
    {
      first_name: "Mohammed ",
      last_name: "Ben Ali",
      skills: [
        { skill_name: "Compétence 1", score: 85 },
        { skill_name: "Compétence 2", score: 70 },
        { skill_name: "Compétence 3", score: 95 },
      ],
    },
    {
      first_name: "Jane",
      last_name: "Morgolis",
      skills: [
        { skill_name: "Compétence 1", score: 75 },
        { skill_name: "Compétence 2", score: 80 },
        { skill_name: "Compétence 3", score: 90 },
      ],
    },
    {
      first_name: "kevin",
      last_name: "Morich",
      skills: [
        { skill_name: "Compétence 1", score: 75 },
        { skill_name: "Compétence 2", score: 66 },
        { skill_name: "Compétence 3", score: 100 },
      ],
    },
  ];

  const renderCharts = (students) => {
    const currentStudent = students[0];
    return (
      <div className="mb-8">
        <h3 className="text-lg font-semibold mb-2">
          {currentStudent.skills[currentSkillIndex].skill_name}
        </h3>
        <div>
          <Bar
            data={{
              labels: students.map(
                (student) => `${student.first_name} ${student.last_name}`
              ),
              datasets: [
                {
                  label: "Scores",
                  data: students.map(
                    (student) => student.skills[currentSkillIndex].score
                  ),
                  backgroundColor: `rgba(${Math.floor(
                    Math.random() * 256
                  )},${Math.floor(Math.random() * 256)},${Math.floor(
                    Math.random() * 256
                  )},0.2)`,
                  borderColor: `rgb(${Math.floor(
                    Math.random() * 256
                  )},${Math.floor(Math.random() * 256)},${Math.floor(
                    Math.random() * 256
                  )})`,
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              scales: {
                y: {
                  beginAtZero: true,
                  max: 100,
                },
              },
              plugins: {
                title: {
                  display: true,
                  text: `Scores pour ${currentStudent.skills[currentSkillIndex].skill_name}`,
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  const handleNextSkill = () => {
    setCurrentSkillIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevSkill = () => {
    setCurrentSkillIndex((prevIndex) => prevIndex - 1);
  };

  const openPdfInNewWindow = async () => {
    const blob = await pdf(<GenrateRapportPdf />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex justify-center items-center h-full">
          <div className="text-center">
            <p>Chargement...</p>
          </div>
        </div>
      );
    }

    if (activeTab === "rapport") {
      const currentSkillName = students[0].skills[currentSkillIndex].skill_name;
      return (
        <div id="rapport-content">
          <h2 className="text-xl font-semibold mb-4">Mes Rapports</h2>
          <div className="flex justify-between items-center my-4">
            <button
              onClick={handlePrevSkill}
              disabled={currentSkillIndex === 0}
              className="py-2 px-4 bg-black text-white rounded-md focus:outline-none"
            >
              précédent
            </button>
            <h3 className="text-purple-500 font-semibold text-2xl">
              {currentSkillName}
            </h3>
            <button
              onClick={handleNextSkill}
              disabled={currentSkillIndex === students[0].skills.length - 1}
              className="py-2 px-4 bg-black text-white rounded-md focus:outline-none"
            >
              suivant
            </button>
          </div>
          <div className="p-4 bg-gray-100 rounded-md shadow-md">
            {renderCharts(students)}
          </div>
        </div>
      );
    } else if (activeTab === "export") {
      return (
        <div>
          <button
            onClick={openPdfInNewWindow}
            className="py-2 px-4 bg-blue-500 text-white rounded-md focus:outline-none"
          >
            Exporter PDF
          </button>
        </div>
      );
    }
  };

  return (
    <div className="container pb-4 mx-auto mt-2">
      <nav className="flex mb-4">
        <button
          className={`py-2 px-4 mr-4 text-lg font-semibold ${
            activeTab === "rapport"
              ? "text-green-600 border-b-2 border-green-600"
              : "text-gray-600 border-b-2 border-transparent"
          } focus:outline-none`}
          onClick={() => setActiveTab("rapport")}
        >
          Rapport
        </button>
        <button
          className={`py-2 px-4 text-lg font-semibold ${
            activeTab === "export"
              ? "text-red-600 border-b-2 border-red-600"
              : "text-gray-600 border-b-2 border-transparent"
          } focus:outline-none`}
          onClick={() => setActiveTab("export")}
        >
          Exporter
        </button>
      </nav>
      {renderContent()}
    </div>
  );
};

export default RapportView;
