import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../context/AuthContext";
import "./prof.css";

export default function Profile() {
  const { auth } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    if (auth) {
      setUserData({
        firstName: auth.first_name,
        lastName: auth.last_name,
        email: auth.email,
        phoneNumber: auth.phone,
      });
    }
  }, [auth]);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  return (
    <div>
      <nav className="flex space-x-3 justify-center mt-4">
        <button
          className={`py-4 px-4  flex items-center justify-center transition duration-300 font-semibold text-center  border-2 border-indigo-700 rounded-md shadow-md ${
            activeTab === "profile"
              ? "text-indigo-700"
              : "text-gray-500 hover:text-indigo-700"
          }`}
          onClick={() => setActiveTab("profile")}
        >
          Profile
        </button>
        <button
          className={`py-4 px-4  flex items-center justify-center transition duration-300 font-semibold text-center border-2 border-indigo-700 rounded-md shadow-md ${
            activeTab === "payment"
              ? "text-indigo-700"
              : "text-gray-500 hover:text-indigo-700"
          }`}
          onClick={() => setActiveTab("payment")}
        >
          Facturation et Paiement
        </button>
        <button
          className={`py-4 px-4  flex items-center justify-center transition duration-300 font-semibold text-center border-2 border-indigo-700 rounded-md shadow-md ${
            activeTab === "planner"
              ? "text-indigo-700"
              : "text-gray-500 hover:text-indigo-700"
          }`}
          onClick={() => setActiveTab("planner")}
        >
          Planner
        </button>
      </nav>

      <div className="mt-6">
        {activeTab === "profile" && <Profilecontent userData={userData} editMode={editMode} handleChange={handleChange} handleSubmit={handleSubmit} handleEdit={handleEdit} />}
        {activeTab === "payment" && <PaymentSection />}
        {activeTab === "planner" && <PlannerSection />}
      </div>
    </div>
  );
}

function Profilecontent({ userData, editMode, handleChange, handleSubmit, handleEdit }) {
  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-teal-500">Mon Profil</h1>
        {!editMode && (
          <div className="flex justify-start mb-4">
            <button
              type="button"
              onClick={handleEdit}
              className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Modifier info
            </button>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="firstName" className="block text-gray-700 font-medium mb-1">
            Prénom
          </label>
          <input
            type="text"
            id="firstName"
            placeholder="Prénom"
            name="firstName"
            value={userData.firstName}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="lastName" className="block text-gray-700 font-medium mb-1">
            Nom de Famille
          </label>
          <input
            type="text"
            id="lastName"
            placeholder="Nom de Famille"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-medium mb-1">
            Adresse Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phoneNumber" className="block text-gray-700 font-medium mb-1">
            Numéro de Téléphone
          </label>
          <input
            type="text"
            id="phoneNumber"
            placeholder="Numéro de Téléphone"
            name="phoneNumber"
            value={userData.phoneNumber}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="flex justify-end">
          {editMode && (
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
            >
              Enregistrer
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

function PaymentSection() {
  return (
    <div className="flex justify-around gap-4 p-8">
      <div className="box bg-white w-1/3 p-6 rounded-md shadow-md">
        <h3 className="text-lg font-semibold mb-4 border-b pb-2 text-indigo-600">Données de facturation</h3>
        <ul className="list-disc pl-4">
          <li className="mb-1">827970831</li>
          <li className="mb-1">Energie d’enteprendre</li>
          <li className="mb-1">Rue de corroyeurs</li>
          <li className="mb-1">France</li>
          <li className="mb-1">Djion</li>
          <li className="mb-1">Bourgogne-Franche-Comté</li>
          <li>21000</li>
        </ul>
      </div>

      <div className="box bg-white w-1/3 p-6 rounded-md shadow-md">
        <h3 className="text-lg font-semibold mb-4 border-b pb-2 text-green-600">Recent Transactions</h3>
        <ul className="list-disc pl-4">
          <li className="mb-1">Transaction 1</li>
          <li className="mb-1">Transaction 2</li>
          <li>Transaction 3</li>
        </ul>
      </div>

      <div className="box bg-white w-1/3 p-6 rounded-md shadow-md">
        <h3 className="text-lg font-semibold mb-4 border-b pb-2 text-orange-600">Upcoming Bills</h3>
        <ul className="list-disc pl-4">
          <li className="mb-1">Bill 1</li>
          <li className="mb-1">Bill 2</li>
          <li>Bill 3</li>
        </ul>
      </div>
    </div>
  );
}

function PlannerSection() {
  return (
    <div className="wrapper">
      <input type="radio" name="slider" id="tab-1" />
      <input type="radio" name="slider" id="tab-2" defaultChecked="" />
      <input type="radio" name="slider" id="tab-3" />
      <header>
        <label htmlFor="tab-1" className="tab-1">
          Basic
        </label>
        <label htmlFor="tab-2" className="tab-2">
          Standard
        </label>
        <label htmlFor="tab-3" className="tab-3">
          Team
        </label>
        <div className="slider" />
      </header>
      <div className="card-area">
        <div className="cards">
          <div className="row row-1">
            <div className="price-details">
              <span className="price">19</span>
              <p>For beginner use</p>
            </div>
            <ul className="features">
              <li>
                <i className="fas fa-check" />
                <span>100 GB Premium Bandwidth</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>FREE 50+ Installation Scripts WordPress Supported</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>One FREE Domain Registration .com and .np extensions only</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>Unlimited Email Accounts &amp; Databases</span>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="price-details">
              <span className="price">99</span>
              <p>For professional use</p>
            </div>
            <ul className="features">
              <li>
                <i className="fas fa-check" />
                <span>Unlimited GB Premium Bandwidth</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>FREE 200+ Installation Scripts WordPress Supported</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>Five FREE Domain Registration .com and .np extensions only</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>Unlimited Email Accounts &amp; Databases</span>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="price-details">
              <span className="price">49</span>
              <p>For team collaboration</p>
            </div>
            <ul className="features">
              <li>
                <i className="fas fa-check" />
                <span>200 GB Premium Bandwidth</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>FREE 100+ Installation Scripts WordPress Supported</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>Two FREE Domain Registration .com and .np extensions only</span>
              </li>
              <li>
                <i className="fas fa-check" />
                <span>Unlimited Email Accounts &amp; Databases</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button>Choose plan</button>
    </div>
  );
}
