import React, { useState, useEffect } from "react";
import authService from "../../../../context/authService";

const Competence = () => {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const value = "complete";
      const scoreResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation_Student?filter[Status]=${value}`,
        "GET"
      );

      if (scoreResponse.data.length === 0) {
        setSkills([]);
      } else {
        const fetchedSkills = await Promise.all(
          scoreResponse.data.map(async (score) => {
            const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${score.SubSkill}`;
            const subSkillInfoResponse =
              await authService.makeAuthenticatedRequest(
                subSkillInfoUrl,
                "GET"
              );
            return {
              SubSkill_id: score.SubSkill,
              SubSkill_name: subSkillInfoResponse.data.Name,
              score: score.Score,
              textColor: generateRandomDarkColor(),
            };
          })
        );
        setSkills(fetchedSkills);
        console.log("scores", fetchedSkills);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateRandomDarkColor = () => {
    const min = 20;
    const max = 120;
    const randomColor = () => Math.floor(Math.random() * (max - min + 1)) + min;
    const color = `rgb(${randomColor()}, ${randomColor()}, ${randomColor()})`;
    return color;
  };

  return (
    <div className="container mx-auto p-8 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">
        Voici les compétences que tu développes 🏆
      </h2>

      <div className="mt-8">
        <div className="overflow-x-auto">
          <table className="w-full table-fixed border border-gray-300 bg-white rounded-md shadow-md mb-8">
            <thead>
              <tr className="bg-purple-700 text-white">
                <th className="py-2 px-4 border break-words">
                  Compétences Travaillées
                </th>
                <th className="py-2 px-4 border break-words">Score</th>
              </tr>
            </thead>
            <tbody>
              {skills.map((skill, index) => (
                <tr key={index} className="bg-white">
                  <td
                    className="py-2 px-4 border break-words"
                    style={{ color: skill.textColor }}
                  >
                    {skill.SubSkill_name}
                  </td>
                  <td
                    className="py-2 px-4 border break-words"
                    style={{ color: skill.textColor }}
                  >
                    {skill.score}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Competence;
