import React, { useState, useEffect } from "react";
import avatar from "../../../../../../../assets/images/5.png";
import ObservationSlider from "./ObservationSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const ObservationContainer = ({
  sessionSubSkills,
  sessionStudents,
  onChange,
}) => {
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [students, setStudents] = useState([]);
  const [avgScores, setAvgScores] = useState([]);

  useEffect(() => {
    const initialStudents = sessionStudents.map((student) => ({
      studentId: student.id,
      avatar: avatar,
      name: `${student.first_name} ${student.last_name}`,
      scores: sessionSubSkills.map((subSkill) => ({
        skillId: subSkill.id,
        score: 0,
      })),
      avgScore: 0,
    }));
    setStudents(initialStudents);
  }, [sessionStudents, sessionSubSkills]);

  const skills =
    sessionSubSkills &&
    sessionSubSkills.map((subSkill) => ({
      skillId: subSkill.id,
      title: subSkill.name,
      marks: Array.from({ length: 11 }, (_, index) => ({
        value: index * 10,
        label: `${index * 10}`,
      })),
    }));

  const valuetext = (value) => `${value}`;

  const handlePrevSkill = () => {
    setSelectedSkillIndex((prevIndex) =>
      prevIndex === 0 ? skills.length - 1 : prevIndex - 1
    );
  };

  const handleNextSkill = () => {
    setSelectedSkillIndex((prevIndex) =>
      prevIndex === skills.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleScoreEdit = (studentId, skillId, newScore) => {
    setStudents((prevStudents) => {
      const updatedStudents = prevStudents.map((student) => {
        if (student.studentId === studentId) {
          const updatedScores = student.scores.map((score) => {
            if (score.skillId === skillId) {
              return { ...score, score: newScore };
            } else {
              return score;
            }
          });

          // Calculate the new average score for the student
          const totalScore = updatedScores.reduce(
            (sum, score) => sum + score.score,
            0
          );
          const avgScore = totalScore / updatedScores.length;

          return { ...student, scores: updatedScores, avgScore: avgScore };
        } else {
          return student;
        }
      });

      onChange(
        updatedStudents.map(({ avatar, ...student }) => ({
          ...student,
          avgScore: student.avgScore,
        }))
      );

      return updatedStudents;
    });
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-2 text-white bg-orange-600 inline-block px-6 py-2 rounded-3xl">
        Observation
      </h1>
      <p className="text-sm mb-4">
        Mise en situation, résolutions de problèmes, observations de
        comportements, d’attitudes ou de qualités individuelles et/ou
        collectives
      </p>
      <div className="mb-4 relative">
        <div className="absolute top-0 left-0 right-0 flex justify-between">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={handlePrevSkill}
          />
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={handleNextSkill}
          />
        </div>
        <h2 className="text-xl font-semibold mb-4">
          Compétence Ciblée :{" "}
          <span className="text-orange-500 font-bold mb-2">
            {skills[selectedSkillIndex].title}
          </span>
        </h2>

        {students &&
          students.map((student, index) => (
            <ObservationSlider
              key={index}
              student={student}
              skillId={skills[selectedSkillIndex].skillId}
              score={
                student.scores.find(
                  (score) =>
                    score.skillId === skills[selectedSkillIndex].skillId
                ).score
              }
              marks={skills[selectedSkillIndex].marks}
              valuetext={valuetext}
              edit={editMode}
              onEdit={handleEdit}
              onScoreEdit={handleScoreEdit}
            />
          ))}
      </div>
    </div>
  );
};

export default ObservationContainer;
