import React, { useState } from "react";

const Decouvrir = () => {
  const videos = [
    "https://www.youtube.com/embed/RKK7wGAYP6k",
    "https://www.youtube.com/embed/9iqn1HhFJ6c",
    "https://www.youtube.com/embed/MWyingJvxjg",
    "https://www.youtube.com/embed/RKK7wGAYP6k",
    "https://www.youtube.com/embed/9iqn1HhFJ6c",
  ];

  const faqData = [
    {
      question: "What is this?",
      answer: "This is the answer to the first question.",
    },
    { question: "How does it work?", answer: "Here is how it works." },
    {
      question: "Any additional information?",
      answer: "Yes, additional information goes here.",
    },
  ];

  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  return (
    <div className="px-4 py-4">
      <div className="container mx-auto px-4">
        {/* Video Slider */}
        <div className="mb-2 border rounded border-indigo-500">
          <iframe
            title="YouTube Video"
            width="100%"
            height="400"
            src={videos[selectedVideoIndex]}
            allowFullScreen
          ></iframe>
        </div>

        <div className="flex justify-center">
          {videos.map((_, index) => (
            <div
              key={index}
              onClick={() => setSelectedVideoIndex(index)}
              className={`w-16 h-16 flex items-center justify-center text-xl font-semibold rounded-full mx-2 cursor-pointer ${
                index === selectedVideoIndex
                  ? "bg-indigo-500 text-white"
                  : "border border-gray-300 text-gray-600 hover:bg-gray-200"
              }`}
            >
              {index + 1}
            </div>
          ))}
        </div>
        {/* FAQ Section */}
        <div className="max-w-auto mx-auto">
          <h2 className="text-3xl font-semibold mb-4">
            Frequently Asked Questions
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="border p-4 transition-all duration-300 ease-in-out hover:shadow-lg border-gray-300"
                style={{ borderRadius: "30px" }}
              >
                <h3 className="text-xl font-medium mb-2 text-indigo-500">
                  {faq.question}
                </h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decouvrir;
