import React, { useState, useEffect } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  PieController,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import authService from "../../../../context/authService";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineController,
  BarController,
  PieController,
  ChartDataLabels
);

const Progress = () => {
  const [currentGroup, setCurrentGroup] = useState(0);
  const [skills, setSkills] = useState([]);
  const [student, setStudent] = useState({ name: "", score: 0 });
  const [currentChartType, setCurrentChartType] = useState("bar");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const value = "complete";
      const scoreResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation_Student?filter[Status]=${value}`,
        "GET"
      );

      if (scoreResponse.data.length === 0) {
        setSkills([]);
      } else {
        const fetchedSkills = await Promise.all(
          scoreResponse.data.map(async (score) => {
            const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${score.SubSkill}`;
            const subSkillInfoResponse =
              await authService.makeAuthenticatedRequest(
                subSkillInfoUrl,
                "GET"
              );
            return {
              SubSkill_id: score.SubSkill,
              SubSkill_name: subSkillInfoResponse.data.Name,
              score: score.Score,
            };
          })
        );
        setSkills(fetchedSkills);
        setStudent((prevStudent) => ({
          ...prevStudent,
          score: fetchedSkills[0]?.score || 0,
        }));
        console.log("scores", fetchedSkills);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const nextGroup = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup < skills.length - 1 ? prevGroup + 1 : 0
    );
  };

  const prevGroup = () => {
    setCurrentGroup((prevGroup) =>
      prevGroup > 0 ? prevGroup - 1 : skills.length - 1
    );
  };

  const generateColors = (numColors) => {
    const colors = [];
    for (let i = 0; i < numColors; i++) {
      const r = Math.floor(Math.random() * 255);
      const g = Math.floor(Math.random() * 255);
      const b = Math.floor(Math.random() * 255);
      colors.push(`rgba(${r}, ${g}, ${b}, 0.6)`);
    }
    return colors;
  };

  const chartData = {
    labels: skills.map((skill) => skill.SubSkill_name),
    datasets: [
      {
        label: "Scores",
        data: skills.map((skill) => skill.score),
        backgroundColor: generateColors(skills.length),
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Compétences et Scores",
      },
      datalabels: {
        anchor: "end",
        align: "top",
        formatter: function (value, context) {
          return value;
        },
        font: {
          weight: "bold",
        },
      },
    },
  };

  return (
    <div className="container mx-auto p-8 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl text-gray-700 font-semibold mb-4">
        Voici les compétences que tu développes 🏆
      </h2>

      <div className="mb-8">
        <p className="text-xl font-semibold mb-2 text-orange-700">
          Total des Compétences: {skills.length}
        </p>
      </div>

      <div className="mt-8">
        <p className="text-xl text-center font-semibold mb-2 text-purple-700">
          {skills.length > 0
            ? skills[currentGroup].SubSkill_name
            : "Loading..."}
        </p>

        <div className="flex justify-center mb-4">
          <button
            className={`mr-4 bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-md shadow-md ${
              currentChartType === "bar" && "bg-purple-900"
            }`}
            onClick={() => setCurrentChartType("bar")}
          >
            Bar
          </button>
          <button
            className={`mr-4 bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-md shadow-md ${
              currentChartType === "pie" && "bg-purple-900"
            }`}
            onClick={() => setCurrentChartType("pie")}
          >
            Pie
          </button>
          <button
            className={`bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-md shadow-md ${
              currentChartType === "line" && "bg-purple-900"
            }`}
            onClick={() => setCurrentChartType("line")}
          >
            Line
          </button>
        </div>

        <div className="my-4">
          {currentChartType === "bar" && (
            <Bar data={chartData} options={chartOptions} />
          )}
          {currentChartType === "pie" && (
            <Pie data={chartData} options={chartOptions} />
          )}
          {currentChartType === "line" && (
            <Line data={chartData} options={chartOptions} />
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-center sm:justify-between">
          <button
            className="mr-0 sm:mr-4 mb-4 sm:mb-0 bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-md shadow-md flex items-center"
            onClick={prevGroup}
          >
            Précédent
          </button>
          <button
            className="bg-purple-700 hover:bg-purple-800 text-white py-2 px-4 rounded-md shadow-md flex items-center"
            onClick={nextGroup}
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default Progress;
