import React, { useContext, useEffect, useState, Fragment } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import DateCalendarServerRequest from "./dateCalander";
import { startOfDay, format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSchool, faUserTie } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../../../../context/AuthContext";
import authService from "../../../../../context/authService";
import svg1 from "../../../../../assets/svg/save.png";
import svg2 from "../../../../../assets/svg/world.png";
import svg3 from "../../../../../assets/svg/math.png";
import ech from "../../../../../assets/images/3_one-to-one-echange_assessment.jpg";
import obsrv from "../../../../../assets/images/2_Observation assessment.jpg";
import self from "../../../../../assets/images/1_self_assessment.jpg";
import mesur from "../../../../../assets/images/4_mesure_assessment.jpg";

const HorizontalScroll = () => {
  const items = [
    {
      title: "Autoevaluation",
      imgSrc1: svg3,
      imgSrc2: self,
      description:
        "assess perceived self-efficacy from the outset, ideal for diagnosis",
    },

    {
      title: "Observation",
      imgSrc1: svg1,
      imgSrc2: obsrv,
      description:
        "Ideal for case studies, role-playing, situational skills, validation, etc.",
    },
    {
      title: "Échange",
      imgSrc1: svg3,
      imgSrc2: ech,
      description:
        "Ideal for coaching, individual or small group support, advice, validation, diagnosis..",
    },
    {
      title: "Mesure",
      imgSrc1: svg2,
      imgSrc2: mesur,
      description:
        "deal for testing, measuring, exams, practical skills, validation, etc.",
    },
  ];

  return (
    <div className="relative w-full">
      <div
        className="flex space-x-4 overflow-x-hidden overflow-y-hidden justify-center mx-auto"
        style={{ height: "auto" }}
      >
        {items.map((item, index) => (
          <Tooltip key={index} title={item.description} placement="top" arrow>
            <Box
              className="bg-white shadow-lg rounded-lg p-4 flex-shrink-0 transition-transform transform hover:scale-105 hover:shadow-xl"
              sx={{
                cursor: "pointer",
              }}
            >
              <div className="flex w-full space-x-2 h-28">
                <img
                  src={item.imgSrc2}
                  alt={item.title}
                  className="h-24 object-cover rounded-lg mx-auto"
                />
              </div>
              <h3 className="mt-2 text-lg font-semibold text-center hover:text-xl">
                {item.title}
              </h3>
            </Box>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

const HomeContent = () => {
  const [sessions, setSessions] = useState([]);
  const [highlightedDays, setHighlightedDays] = useState([]);
  const { auth } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const isCompagneManager = () => {
    if (auth.role === "Teacher") {
      return false;
    }
    if (auth.role === "Compagne Manager") {
      return true;
    }
  };

  useEffect(() => {
    if (auth) {
      setEmail(auth.email);
      setFirstName(auth.first_name);
      setLastName(auth.last_name);
    }
  }, [auth]);

  useEffect(() => {
    fetchSessions();
  }, []);

  const [nextSession, setNextSession] = useState(null);

  useEffect(() => {
    if (sessions.length > 0) {
      const sortedSessions = [...sessions].sort(
        (a, b) => a.Start_Date.getTime() - b.Start_Date.getTime()
      );
      setNextSession(
        sortedSessions.find((session) => session.Start_Date > new Date())
      );
    }
  }, [sessions]);

  const fetchSessions = async () => {
    try {
      const sessionResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Sessions`,
        "GET"
      );
      const sessions = sessionResponse.data.map((session) => ({
        ...session,
        Start_Date: startOfDay(new Date(session.Start_Date)),
        End_Date: startOfDay(new Date(session.End_Date)),
      }));

      setSessions(sessions);
    } catch (error) {
      console.error("Error fetching sessions:", error);
    }
  };

  return (
    <div className="container pb-8 mx-auto px-4">
      <div className="flex flex-col sm:flex-row py-4 items-start">
        {!isCompagneManager ? (
          <Fragment>
            <FontAwesomeIcon
              icon={faSchool}
              className="h-6 w-6 sm:mr-2 mb-2 sm:mb-0 text-gray-900"
            />
            <h1 className="text-xl font-semibold text-gray-900 sm:text-left">
              L'organisation -{" "}
              <span className="text-blue-700">
                {firstName} {lastName}
              </span>
            </h1>
          </Fragment>
        ) : (
          <Fragment>
            <FontAwesomeIcon
              icon={faUserTie}
              className="h-6 w-6 sm:mr-2 mb-2 sm:mb-0 text-gray-900"
            />
            <h1 className="text-lg font-semibold text-gray-900 sm:text-left">
              Hello Professor :{" "}
              <span className="text-gray-900">
                {firstName} {lastName}
              </span>
            </h1>
          </Fragment>
        )}
      </div>
      <HorizontalScroll />

      <div className="flex justify-center my-4">
        <button className="bg-orange-500 text-white p-2 rounded-lg w-60 hover:bg-blue-600">
          Lancer Starskills
        </button>
      </div>

      <div className="flex justify-center items-center m-5">
        <div className="bg-blue-100 p-4 rounded-lg border border-gray-300 md:w-1/2">
          <h3 className="text-base text-gray-900 font-semibold mb-4">
            Compte Vérifé
          </h3>
          <p className="text-sm mb-4">
            Envoie dès aujourd'hui votre demande pour avoir votre profil vérifé!
          </p>
          <button className="bg-blue-600 text-sm text-white px-4 py-2 rounded-md">
            Ouvrir le formulaire de demande
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
        <div className="p-4 rounded-lg border bg-blue-600 border-blue-600">
          <DateCalendarServerRequest highlightedDays={highlightedDays} />
        </div>

        <div className="p-4 rounded-lg bg-orange-200 border-8 border-yellow-600">
          <h4 className="text-lg text-black font-semibold mb-2">
            Prochaine Session
          </h4>
          {nextSession && (
            <div>
              <p className="text-green-500 mb-1">
                Date: {format(nextSession.Start_Date, "dd MMMM yyyy")}
              </p>
              <p className="text-red-500 mb-4">
                Date de fin:{" "}
                {format(nextSession.End_Date, "dd MMMM yyyy") ||
                  "No details available"}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default HomeContent;
