import React, { useContext } from "react";
import { Link } from "react-router-dom";
import intro from "../../../assets/images/11.png";
import AuthContext from "../../../context/AuthContext";

export default function ImageSection() {
  const { auth } = useContext(AuthContext);

  const getDashboardLink = () => {
    if (auth.role === "Teacher" || auth.role === "Compagne Manager") {
      return "/teacher_dashboard";
    } else if (auth.role === "Student") {
      return `/student_dashboard/${auth.id}`;
    } else return "/signIn";
  };

  return (
    <div className="max-w-7xl grid grid-cols-1 gap-y-10 sm:gap-y-20">
      <div className=" flex flex-col lg:flex-row items-center text-center">
        <div className="mt-8 mx-6 w-full lg:w-1/2">
          <p
            className="mt-14 text-4xl lg:text-5xl  px-6 lg:px-0 font-semibold  uppercase"
            style={{
              background:
                "linear-gradient(98.55deg, #63508D 0.03%, #D801F5 122.61%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            DéVELOPPER CHAQUE COMPéTENCE AVEC FACILITé ET SéRéNITé
          </p>
          <div className="mt-10 text-center">
            <Link
              to={getDashboardLink()}
              className="inline-flex items-center px-12 py-4 border rounded-md shadow-sm text-base font-medium text-white hover:bg-indigo-600"
              style={{
                background:
                  "linear-gradient(93.64deg, #6943BC -26.03%, #5ADCFF 134.75%)",
                borderRadius: "32px",
                filter: "drop-shadow(0 0 0.1rem blue)",
              }}
            >
              Essayez Starskills, C'est Gratuit
            </Link>
          </div>
        </div>
        <div className="my-12 lg:mt-24 w-full  lg:mx-0 lg:w-1/2">
          <img
            src={intro}
            alt="introduction"
            className="px-10 lg:px-0"
            style={{ filter: "drop-shadow(0 0 0.2rem purple)" }}
          />
        </div>
      </div>
    </div>
  );
}
