import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import authService from "../../../../../../context/authService";
import AuthContext from "../../../../../../context/AuthContext";

const CreateGroupModal = ({ open, onClose, onRefreshPage }) => {
  const [groupName, setGroupName] = useState("");
  const [isGroupNameValid, setIsGroupNameValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [studentOptions, setStudentOptions] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const roleValue = "607323cc-d51f-4c21-8140-0d669b36ca6f";
        const studentUrl = `https://api.starskills.fr/users/?filter={"role":{"_eq":"${roleValue}"}}`;
        const studentSearch = await authService.makeAuthenticatedRequest(
          studentUrl,
          "GET"
        );
        const studentList = studentSearch.data.map((student) => ({
          value: student.id,
          label: student.email,
        }));
        setStudentOptions(studentList);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async () => {
    try {
      if (groupName.trim() === "") {
        setIsGroupNameValid(false);
        return;
      }

      const newGroupData = {
        Name: groupName,
        user_created: auth.id,
      };

      const response = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Group",
        "POST",
        newGroupData
      );

      for (const student of selectedStudents) {
        const groupStudentData = {
          Group_id: response.data.id,
          student_id: student.value,
        };
        const apiUrlGroupStudent =
          "https://api.starskills.fr/items/Group_student";
        await authService.makeAuthenticatedRequest(
          apiUrlGroupStudent,
          "POST",
          groupStudentData
        );
      }
      console.log("New group created");
      onRefreshPage();
      onClose();
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleStudentSearchChange = (selectedStudents) => {
    setSelectedStudents(selectedStudents);
  };

  const handleRemoveStudent = (index) => {
    setSelectedStudents((prevSelectedStudents) =>
      prevSelectedStudents.filter((_, i) => i !== index)
    );
  };

  return (
    <Dialog open={open} handler={onClose}>
      <DialogHeader>Créer Un Groupe</DialogHeader>
      <DialogBody>
        <div className="mb-4">
          <label htmlFor="groupName" className="block text-gray-700 mb-2">
            Nom de Groupe
          </label>
          <input
            type="text"
            required
            placeholder="Nom de Groupe"
            id="groupName"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            className={`border ${
              isGroupNameValid ? "border-gray-300" : "border-red-500"
            } rounded-md w-full py-2 px-3 focus:outline-none focus:border-indigo-500`}
          />
          {!isGroupNameValid && (
            <p className="text-red-500 text-sm mt-1">
              Le nom de groupe ne peut pas être vide
            </p>
          )}
        </div>

        <div className="mb-4">
          <label
            htmlFor="studentSearch"
            className="block text-sm font-medium text-gray-700"
          >
            Rechercher des étudiants (par email):
          </label>
          <Select
            id="studentSearch"
            placeholder="Rechercher par email"
            isClearable
            isMulti
            options={studentOptions}
            value={selectedStudents}
            onChange={handleStudentSearchChange}
          />
        </div>

        {/* Display selected students as tags */}
        {selectedStudents.length > 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Étudiants sélectionnés:
            </label>
            <div className="flex flex-wrap bg-gray-200 p-1">
              {selectedStudents.map((student, index) => (
                <div key={index} className="flex items-center mr-2 mb-2">
                  <span
                    className="bg-white border-indigo-600 px-3 py-1 rounded-md mr-2 flex items-center cursor-pointer"
                    onClick={() => handleRemoveStudent(index)}
                  >
                    {student.label}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedStudents.length === 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Aucun étudiant sélectionné
            </label>
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button className="mx-2" color="red" onClick={onClose}>
          Annuler
        </Button>
        <Button color="green" type="submit" onClick={handleSubmit}>
          Créer le groupe
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default CreateGroupModal;
