import React from 'react';
import Slider from '@mui/material/Slider'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPen, faShare } from '@fortawesome/free-solid-svg-icons';

const SliderView = ({ students, marks, valuetext }) => {
  return (
    <>
      {students.map((student) => (
        <div key={student.id} className="flex justify-center bg-gray-200 w-full pt-6 pb-2 px-2 my-0 border mb-4">
          <div className="flex items-center ">
            <div className="flex items-center mr-8    ">
              <img
                src={student.avatar}
                alt={student.name}
                className="w-20 h-20 rounded-full mr-4"
              />
              <span>{student.name}</span>
            </div>
            <div className="flex items-center">
              <Slider
                aria-label="Always visible"
                defaultValue={student.score}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                valueLabelDisplay="on" 
                disabled 
                sx={{ width: 300 }}
              />
              <div className='ml-4'>
                <FontAwesomeIcon icon={faCheck} className="ml-4 cursor-pointer text-teal-500 text-2xl" />
                <FontAwesomeIcon icon={faPen} className="ml-4 cursor-pointer text-blue-500 text-2xl" /> 
                <FontAwesomeIcon icon={faShare} className="ml-4 cursor-pointer text-purple-500 text-2xl" /> 
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SliderView;
