import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

const ObservationSlider = ({
  student,
  skillId,
  score,
  marks,
  valuetext,
  edit,
  onScoreEdit,
}) => {
  const [editedScore, setEditedScore] = useState(score);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [localEdit, setLocalEdit] = useState(edit);

  const handleInputChange = (event, newValue) => {
    setEditedScore(newValue);
    onScoreEdit(student.studentId, skillId, newValue);
  };

  useEffect(() => {
    setEditedScore(score);
  }, [score]);

  const handleEdit = () => {
    setLocalEdit(!localEdit);
    setInputDisabled(false);
  };

  return (
    <div className="flex flex-col items-center bg-gray-200 w-full pb-2 px-2 my-0 border mb-4">
      <div className="flex jus items-center bg-white p-4 border-yellow-300 w-full">
        <div className="flex flex-1 items-center justify-center">
          <div className="flex items-center mr-8">
            <img
              src={student.avatar}
              alt={student.name}
              className="w-14 h-14 rounded-full bg-yellow-200 border border-yellow-300 mx-2"
            />
            <span className="text-blue-500 font-bold">{student.name}</span>
          </div>
          <Slider
            aria-label={`Skill for ${student.name}`}
            value={editedScore}
            getAriaValueText={valuetext}
            step={1}
            marks={marks}
            valueLabelDisplay="on"
            onChange={handleInputChange}
            disabled={!localEdit || inputDisabled}
            sx={{ width: 400 }}
          />
          <div className="flex ml-6">
            <FontAwesomeIcon
              icon={faPen}
              className={`ml-4 cursor-pointer text-green-700 text-xl bg-green-100 border-2 p-2 ${
                localEdit ? "" : "opacity-50"
              }`}
              onClick={handleEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObservationSlider;
