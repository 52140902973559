import React, { useState, useEffect } from "react";
import authService from "../../../../context/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const SkillCard = ({ skill }) => {
  if (!skill) {
    return null;
  }

  let bgColorClass;
  let icon;
  let iconColor;
  switch (skill.feedback) {
    case "Poor":
      bgColorClass = "bg-red-100";
      icon = faTimesCircle;
      iconColor = "text-red-800";
      break;
    case "Average":
      bgColorClass = "bg-yellow-100";
      icon = faTimesCircle;
      iconColor = "text-yellow-800";
      break;
    case "Good":
      bgColorClass = "bg-green-100";
      icon = faCheckCircle;
      iconColor = "text-green-800";
      break;
    case "Excellent":
      bgColorClass = "bg-blue-100";
      icon = faCheckCircle;
      iconColor = "text-blue-800";
      break;
    default:
      bgColorClass = "bg-gray-200";
      icon = faCheckCircle;
      iconColor = "text-gray-600";
  }

  return (
    <div
      className={`max-w-md mx-auto rounded-md shadow-lg overflow-hidden mb-4 ${bgColorClass}`}
    >
      <div className="p-4 relative">
        <FontAwesomeIcon
          icon={icon}
          className={`absolute top-0 left-0 text-3xl ${iconColor}`}
        />
        <div className="mt-6">
          <h3 className="text-xl mb-2">
            Comptence : <span className="font-bold">{skill.SubSkill_name}</span>
          </h3>
          <p className="text-lg mb-2">
            Score: <span className="font-bold">{skill.score}</span>
          </p>
          <p className={`text-lg ${skill.textColor}`}>
            Feedback : <span className="font-bold">{skill.feedback}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const Competence = () => {
  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const value = "complete";
      const scoreResponse = await authService.makeAuthenticatedRequest(
        `https://api.starskills.fr/items/Evaluation_Student?filter[Status]=${value}`,
        "GET"
      );

      if (scoreResponse.data.length === 0) {
        setSkills([]);
      } else {
        const fetchedSkills = await Promise.all(
          scoreResponse.data.map(async (score) => {
            const subSkillInfoUrl = `https://api.starskills.fr/items/SubSkill/${score.SubSkill}`;
            const subSkillInfoResponse =
              await authService.makeAuthenticatedRequest(
                subSkillInfoUrl,
                "GET"
              );
            let feedback;
            let textColor;
            if (score.Score < 25) {
              feedback = "Poor";
              textColor = "text-red-800";
            } else if (score.Score < 50) {
              feedback = "Average";
              textColor = "text-orange-800";
            } else if (score.Score < 75) {
              feedback = "Good";
              textColor = "text-green-800";
            } else {
              feedback = "Excellent";
              textColor = "text-blue-800";
            }
            return {
              SubSkill_id: score.SubSkill,
              SubSkill_name: subSkillInfoResponse.data.Name,
              score: score.Score,
              feedback: feedback,
              textColor: textColor,
            };
          })
        );
        setSkills(fetchedSkills);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const nextSkill = () => {
    setCurrentSkill((prevSkill) =>
      prevSkill < skills.length - 1 ? prevSkill + 1 : 0
    );
  };

  const prevSkill = () => {
    setCurrentSkill((prevSkill) =>
      prevSkill > 0 ? prevSkill - 1 : skills.length - 1
    );
  };

  return (
    <div className="container mx-auto p-8 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4">
        Voici les compétences que tu développes 🏆
      </h2>
      <p className="mt-4 text-xl font-bold text-purple-600 flex justify-center">
        feedback : {currentSkill + 1} / {skills.length}
      </p>
      <div className="mt-4">
        {skills.length > 0 && (
          <div>
            <SkillCard skill={skills[currentSkill]} />
            <div className="flex justify-center mt-4">
              <button
                className="mr-4 bg-purple-700 hover:bg-purple-700 text-white py-2 px-4 rounded-md shadow-md flex items-center"
                onClick={prevSkill}
              >
                Précédent
              </button>
              <button
                className="bg-purple-700 hover:bg-purple-700 text-white py-2 px-4 rounded-md shadow-md flex items-center"
                onClick={nextSkill}
              >
                Suivant
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Competence;
