import React, { useState } from "react";
import Select from "react-select";

const AddSession = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedCompetence, setSelectedCompetence] = useState(null);
  const [selectedSubCompetence, setSelectedSubCompetence] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [sessionTitle, setSessionTitle] = useState("");
  const [endDate, setEndDate] = useState("");
  const [invitationLink, setInvitationLink] = useState("");
  const [studentData, setStudentData] = useState([
    { id: 1, name: "Alice Johnson", group: "Group A" },
    { id: 2, name: "Bob Smith", group: "Group B" },
    { id: 3, name: "Charlie Parker", group: "Group A" },
  ]);

  const handleNextStep = () => {
    setCurrentStep((prevStep) => Math.min(prevStep + 1, 3));
  };

  const handlePrevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const generateInvitationLink = () => {
    const tempLink = "https://stars-skills/session?id=12345";
    setInvitationLink(tempLink);
    console.log("hello from generate Invitation Link btn ");
  };
  const handleSubmitSession = (e) => {
    e.preventDefault();
    console.log(
      "hello Session Createad:",
      sessionTitle,
      startDate,
      endDate,
      invitationLink
    );
  };

  return (
    <div className="max-w-xxl  mx-auto bg-gray-100 p-8 rounded-lg shadow-lg">
      <form onSubmit={handleSubmitSession}>
        {currentStep === 1 && (
          <div>
            <h3>Choisir une Competence</h3>
            <Select
              options={[
                { label: "Competence 1", value: "competence1" },
                { label: "Competence 2", value: "competence2" },
              ]}
              isSearchable
              placeholder="Select Competence"
              onChange={(selectedOption) =>
                setSelectedCompetence(selectedOption.value)
              }
            />
            {selectedCompetence && (
              <div>
                <h3 className="mt-4">Choisir Sous-Competence (une/multi)</h3>
                <Select
                  options={[
                    { label: "Sub-Competence 1", value: "subcompetence1" },
                    { label: "Sub-Competence 2", value: "subcompetence2" },
                  ]}
                  isSearchable
                  isMulti
                  placeholder="Select Sub-Competence"
                  onChange={(selectedOption) =>
                    setSelectedSubCompetence(selectedOption.value)
                  }
                />
              </div>
            )}
          </div>
        )}
        {currentStep === 2 && (
          <div>
            <h2 className="text-lg font-semibold">Manage Group</h2>
            <div className="grid grid-cols-3 gap-4 mt-4">
              <button className="bg-blue-500 text-white p-2 rounded">
                Invite by Link, Mail or SMS
              </button>
              <button className="bg-blue-500 text-white p-2 rounded">
                Add Group Manually
              </button>
              <button className="bg-blue-500 text-white p-2 rounded">
                Import Group
              </button>
            </div>
            <div className="mt-6">
              <h3 className="text-base font-semibold">Current Students</h3>
              <ul className="list-disc list-inside mt-2">
                {studentData.map((student) => (
                  <li key={student.id}>
                    {student.name} (Group: {student.group})
                  </li>
                ))}
              </ul>
            </div>
            <div className="mb-6">
              <h2 className="text-lg font-semibold">Evaluation & Planning</h2>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <button className="bg-green-500 text-white p-2 rounded">
                  Send Initial Auto-Evaluation
                </button>
                <button className="bg-green-500 text-white p-2 rounded">
                  Schedule
                </button>
              </div>
            </div>
          </div>
        )}
        {currentStep === 3 && (
          <div>
            <form onSubmit={handleSubmitSession}>
              <div className="mb-4">
                <h3 className="text-base font-semibold">Select Days</h3>
                <div className="grid grid-cols-3 gap-4 mt-2">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Monday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Tuesday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Wednesday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Thursday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Friday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Saturday</span>
                  </label>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    />
                    <span className="ml-2">Sunday</span>
                  </label>
                </div>
              </div>
              <div className="mb-4 grid grid-cols-2 gap-4">
                <div>
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Start Date
                  </label>
                  <input
                    type="date"
                    id="startDate"
                    name="startDate"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium text-gray-700"
                  >
                    End Date
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    name="endDate"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="invitationLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Invitation Link
                </label>
                <input
                  type="text"
                  id="invitationLink"
                  name="invitationLink"
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={invitationLink}
                  readOnly
                />
                <div className="flex justify-start mb-4 mt-2 gap-2">
                  <button
                    className="bg-blue-500 text-white p-2 rounded"
                    type="button"
                    onClick={generateInvitationLink}
                  >
                    Generate Link
                  </button>
                  <button
                    className="bg-green-500 text-white p-2 rounded"
                    type="submit"
                  >
                    Create Session
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="flex justify-center space-x-4 mt-4">
          <button
            onClick={handlePrevStep}
            disabled={currentStep === 1}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400 disabled:text-gray-700"
          >
            Previous
          </button>
          <button
            onClick={handleNextStep}
            disabled={currentStep === 3}
            className="px-4 py-2 bg-blue-500 text-white rounded-md disabled:bg-gray-400 disabled:text-gray-700"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSession;
