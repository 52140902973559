import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Navbar from "../../components/Home/Navbar/Navbar";
import axios from "axios";
import auth_img from "../../assets/images/auth.png";
import authService from "../../context/authService";

export default function SignUp() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("teacher");

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      setError(`Format d'email invalide`);
      return;
    }

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    if (password.length < 8) {
      setError("Le mot de passe doit contenir au moins 8 caractères");
      return;
    }

    setLoading(true);
    try {
      let role_id;
      if (role === "teacher") {
        role_id = "544d198c-4e4d-4ff4-bf20-c49d1ac4b897";
      } else {
        role_id = "607323cc-d51f-4c21-8140-0d669b36ca6f";
      }

      const userData = {
        email: email,
        first_name: fullName,
        password: password,
        phone: phoneNumber,
        role: role_id,
      };

      const userResponse = await axios.post(
        "https://api.starskills.fr/users",
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const requestBody = {
        email: email,
        password: password,
      };

      const requestBodyJSON = JSON.stringify(requestBody);
      const apiUrl = "https://api.starskills.fr/auth/login";

      try {
        const response = await axios.post(apiUrl, requestBodyJSON, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const apiUrlinfo = "https://api.starskills.fr/users/me";
        const response2 = await axios.get(apiUrlinfo, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.data.access_token}`,
          },
        });
        const apiUrlrole = `https://api.starskills.fr/roles/${response2.data.data.role}`;
        const response3 = await axios.get(apiUrlrole, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${response.data.data.access_token}`,
          },
        });

        if (response3.status === 200) {
          setAuth({
            first_name: response2.data.data.first_name,
            last_name: response2.data.data.last_name,
            phone: response2.data.data.phone,
            email: response2.data.data.email,
            id: response2.data.data.id,
            access_token: response.data.data.access_token,
            refresh_token: response.data.data.refresh_token,
            expires: response.data.data.expires,
            role: response3.data.data.name,
          });
          authService.setAccessToken(response.data.data.access_token);
          authService.setTokenExpiration(
            Date.now() + response.data.data.expires
          );
          authService.setRefreshToken(response.data.data.refresh_token);
        }
      } catch (err) {
        console.error(
          "Erreur lors de l envoi des données, veuillez réessayer.",
          err
        );
      }

      navigate(`/account_verification`);

      setFullName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPhoneNumber("");
      setRole("");
      alert("Compte créé avec Succès , Veuillez Connecter maintenant !");
    } catch (error) {
      console.error("Error creating account:", error);
      setError("Une erreur s'est produite lors de la création du compte.");
    } finally {
      setLoading(false);
    }
  };

  const getBorderColor = () => {
    if (confirmPassword === "") return "border-gray-300";
    return password === confirmPassword ? "border-green-500" : "border-red-500";
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Real-time password length validation
    if (newPassword.length < 8) {
      setPasswordError("Le mot de passe doit contenir au moins 8 caractères");
    } else {
      setPasswordError("");
    }

    // Check if passwords match
    if (confirmPassword && newPassword !== confirmPassword) {
      setConfirmPasswordError("Les mots de passe ne correspondent pas");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    // Check if passwords match
    if (password !== newConfirmPassword) {
      setConfirmPasswordError("Les mots de passe ne correspondent pas");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex min-h-screen">
        <div className="w-full lg:w-1/2">
          <div className="flex justify-center items-center h-full px-8 lg:px-8">
            <div className="sm:w-full sm:max-w-md">
              <h2 className="mt-20 text-center mb-6 text-2xl font-bold leading-4 tracking-tight text-gray-900">
                Créer un compte
              </h2>
              <form onSubmit={handleSignup} className="mt-8 space-y-6">
                <div className="flex space-x-2">
                  <div className="flex-1">
                    <label
                      htmlFor="fullName"
                      className="block text-sm font-medium leading-1 text-gray-900"
                    >
                      Nom Complet
                    </label>
                    <input
                      placeholder="Nom Complet"
                      id="fullName"
                      name="fullName"
                      type="text"
                      autoComplete="name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      required
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="role"
                    className="block text-sm font-medium leading-1 text-gray-900"
                  >
                    Rôle
                  </label>
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="teacher">Enseignant</option>
                    <option value="student">Étudiant</option>
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-1 text-gray-900"
                  >
                    Adresse e-mail
                  </label>
                  <div className="mt-1">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      placeholder="Adresse e-mail"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-1 text-gray-900"
                  >
                    Mot de Passe
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      type="password"
                      placeholder="Mot De Passe (au moins 8 caractères)"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                      minLength="8"
                      pattern=".{8,}"
                      title="Le mot de passe doit contenir au moins 8 caractères"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    {/* Password length validation message */}
                    {passwordError && (
                      <p className="text-red-500 text-sm mt-1">
                        {passwordError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="confirmPassword"
                    className="block text-sm font-medium leading-1 text-gray-900"
                  >
                    Confirmer Mot de Passe
                  </label>
                  <div className="mt-1">
                    <input
                      id="confirmPassword"
                      type="password"
                      placeholder="Confirmer Mot De Passe"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                      required
                      className={`block w-full rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${getBorderColor()}`}
                    />
                    {/* Password match validation message */}
                    {confirmPasswordError && (
                      <p className="text-red-500 text-sm mt-1">
                        {confirmPasswordError}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={loading}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {loading ? "Chargement..." : "Créer un compte"}
                  </button>
                </div>
                {error && (
                  <div className="mt-4 text-red-500 text-sm">{error}</div>
                )}
              </form>
              <p className="mb-8 text-center text-sm text-gray-500 mt-4">
                Vous avez déjà un compte?{" "}
                <Link
                  to="/SignIn"
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  Se connecter
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex justify-center items-center w-full lg:w-1/2 text-center">
          <img
            style={{
              filter: "drop-shadow(0 0 0.2rem blue)",
            }}
            src={auth_img}
            alt="signIn_image"
            className="w-[20rem] sm:w-[20rem] lg:w-[25rem] max-w-none "
          />
        </div>
      </div>
    </>
  );
}
