import React from "react";
import logo_icon from "../../../assets/images/STARSKILLS 01111.png";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "./navbar.css";

const navigation = [
  { name: "Pourquoi Nous", href: "/#WhyUs", current: false },
  { name: "Catalogue", href: "/#models", current: false },
  { name: "Pour qui", href: "/#PourQui", current: false },
  { name: "Faq", link: "/faq", current: false },
  { name: "Contactez nous", link: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const getDashboardLink = () => {
    if (auth.role === "Teacher" || auth.role === "Compagne Manager") {
      return "/teacher_dashboard";
    } else if (auth.role === "Student") {
      return `/student_dashboard/${auth.id}`;
    } else return "/signIn";
  };

  useEffect(() => {
    if (auth) {
      setEmail(auth.email);
      setFirstName(auth.first_name);
      setLastName(auth.last_name);
    }
  }, [auth.id]);

  const handleLogout = () => {
    logout();
    navigate("/");
    window.location.reload();
  };

  return (
    <Disclosure
      as="nav"
      className="text-white fixed top-0 left-0 right-0 z-10"
      style={{
        background:
          "linear-gradient(90.25deg, #6943BC 11.51%, #1F64BF 59.56%, #6641B8 89.83%)",
      }}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <Link to="/">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="h-8 w-auto hidden-on-mobile"
                      src={logo_icon}
                      alt="Starskills"
                    />
                    <h2 className="text-xl text-white ml-2 ">Starskills</h2>
                  </div>
                </Link>
                <div className="hidden sm:flex sm:ml-6">
                  <div className="flex uppercase text-sm space-x-4 mx-auto">
                    {navigation.map((item) =>
                      item.href ? (
                        <a
                          key={item.name}
                          href={item.href} // Render anchor tag if href is defined
                          className={classNames(
                            item.current
                              ? "bg-orange-900 text-white"
                              : "text-white hover:bg-orange-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          key={item.name}
                          to={item.link} // Render Link component if link is defined
                          className={classNames(
                            item.current
                              ? "bg-orange-900 text-white"
                              : "text-white hover:bg-orange-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center">
                  <i className="fa-solid fa-arrow-up text-white" />
                  {auth.id ? (
                    // If user is logged in, display dropdown
                    <>
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Link to={getDashboardLink()}>
                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                              {firstName} {lastName}
                              <ChevronDownIcon
                                className="-mr-1 h-5 w-5 text-gray-800"
                                aria-hidden="true"
                              />
                            </Menu.Button>
                          </Link>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <>
                                    <Link
                                      to={getDashboardLink()}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-black",
                                        "block px-4 py-2 text-sm text-black hover:bg-indigo-700 hover:text-white cursor-pointer"
                                      )}
                                    >
                                      Mon Dashboard
                                    </Link>
                                    <a
                                      onClick={handleLogout}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-black",
                                        "block px-4 py-2 text-sm text-black hover:text-gray-700 cursor-pointer"
                                      )}
                                    >
                                      Deconnecter
                                    </a>
                                  </>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/SignIn"
                        className="text-sm text-white border border-2 border-white rounded-3xl px-3 py-2 text-sm font-medium leading-6"
                      >
                        Se Connecter
                      </Link>
                      <Link
                        to="/SignUp"
                        className="text-sm bg-white text-indigo-700 ml-2 border border-2 border-white rounded-3xl px-3 py-2 text-sm font-medium leading-6"
                      >
                        Inscrivez-vous
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* for mobile view */}
          <Disclosure.Panel className="sm:hidden">
            <div className=" space-y-1 px-2 pb-4 pt-4">
              <i className="fa-solid fa-arrow-up text-white" />
              {auth.id ? (
                // If user is logged in, display dropdown
                <>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-indigo-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {firstName} {lastName}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-800"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <>
                                {" "}
                                <Link
                                  to={getDashboardLink()}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm text-black hover:bg-indigo-700 hover:text-white cursor-pointer"
                                  )}
                                >
                                  Mon Dashboard
                                </Link>
                                <a
                                  onClick={handleLogout}
                                  className={classNames(
                                    active
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm text-black hover:text-gray-700 cursor-pointer"
                                  )}
                                >
                                  Deconnecter
                                </a>
                              </>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </>
              ) : (
                <>
                  <Link
                    to="/SignIn"
                    className="text-sm text-white border border-2 border-white rounded-3xl px-3 py-2 text-sm font-medium leading-6"
                  >
                    Se Connecter
                  </Link>
                  <Link
                    to="/SignUp"
                    className="text-sm bg-white text-indigo-700 ml-2 border border-2 border-white rounded-3xl px-3 py-2 text-sm font-medium leading-6"
                  >
                    Inscrivez-vous
                  </Link>
                </>
              )}
            </div>
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-orange-900 text-white"
                      : "text-white hover:bg-orange-400 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
