import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faShare, faPlus } from '@fortawesome/free-solid-svg-icons';

const Enseignants = () => {
  const [enseignants, setEnseignants] = useState([
    { id: 1, nom: 'Smith', prenom: 'John', email: 'john.smith@example.com' },
    { id: 2, nom: 'Doe', prenom: 'Jane', email: 'jane.doe@example.com' },
    { id: 3, nom: 'Johnson', prenom: 'Mark', email: 'mark.johnson@example.com' },
    { id: 4, nom: 'Williams', prenom: 'Anna', email: 'anna.williams@example.com' },
    { id: 5, nom: 'Brown', prenom: 'Michael', email: 'michael.brown@example.com' },
    { id: 6, nom: 'Davis', prenom: 'Emily', email: 'emily.davis@example.com' },
    { id: 7, nom: 'Jones', prenom: 'Christopher', email: 'christopher.jones@example.com' },
    { id: 8, nom: 'Taylor', prenom: 'Olivia', email: 'olivia.taylor@example.com' },
    { id: 9, nom: 'Anderson', prenom: 'Daniel', email: 'daniel.anderson@example.com' },
    { id: 10, nom: 'Miller', prenom: 'Sophia', email: 'sophia.miller@example.com' },

  ]);

  const handleModifier = (id) => {
 
    console.log(`Modifier Enseignant with ID: ${id}`);
  };

  const handleSupprimer = (id) => {

    console.log(`Supprimer Enseignant with ID: ${id}`);
  };

  const handlePartager = (id) => {
 
    console.log(`Partager Enseignant with ID: ${id}`);
  };

  const handleAjouter = () => {
  
    console.log('Ajouter Enseignant');
  };

  return (
    <div className="container mx-auto my-8">
      <h1 className="text-2xl font-bold mb-4">Liste des Enseignants</h1>
      <div className="mb-4">
        <button
          onClick={handleAjouter}
          className="bg-green-500 text-white px-4 py-2 rounded flex items-center"
        >
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Ajouter
        </button>
      </div>
      <table className="w-full border">
        <thead>
          <tr className="bg-gray-200">
            <th className="border p-2">Nom</th>
            <th className="border p-2">Prénom</th>
            <th className="border p-2">Email</th>
            <th className="border p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {enseignants.map((enseignant) => (
            <tr key={enseignant.id}>
              <td className="border p-2">{enseignant.nom}</td>
              <td className="border p-2">{enseignant.prenom}</td>
              <td className="border p-2">{enseignant.email}</td>
              <td className="border p-2">
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleModifier(enseignant.id)}
                    className="bg-blue-500 text-white px-2 py-1 rounded flex items-center"
                  >
                    <FontAwesomeIcon icon={faEdit} className="mr-2" />
                    Modifier
                  </button>
                  <button
                    onClick={() => handleSupprimer(enseignant.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded flex items-center"
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    Supprimer
                  </button>
                  <button
                    onClick={() => handlePartager(enseignant.id)}
                    className="bg-green-500 text-white px-2 py-1 rounded flex items-center"
                  >
                    <FontAwesomeIcon icon={faShare} className="mr-2" />
                    Partager
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Enseignants;
