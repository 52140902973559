import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import authService from "../../../../../../context/authService";

const AddRefModal = ({ showModal, handleCloseModal }) => {
  const { category, niveauId } = useParams();
  const [nomRef, setNomRef] = useState("");
  const [competencesList, setCompetencesList] = useState([]);
  const [skillsName, setSkillsName] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [competenceInput, setCompetenceInput] = useState("");
  const [subCompetenceInput, setSubCompetenceInput] = useState("");

  const [currentStep, setCurrentStep] = useState(1);
  const [description, setDescription] = useState("");

  const handleNextStep = () => {
    if (currentStep === 1 && nomRef.trim() === "") {
      setIsValid(false);
    } else {
      setIsValid(true);
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const closeModal = () => {
    handleCloseModal(false);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleNomRefChange = (e) => {
    setNomRef(e.target.value);
  };

  const handleAddCompetence = () => {
    if (competenceInput.trim() !== "") {
      setCompetencesList([
        ...competencesList,
        { skillsName: competenceInput, subCompetences: [] },
      ]);
      setCompetenceInput("");
      setSubCompetenceInput("");
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleAddSubCompetence = (competenceIndex) => {
    if (subCompetenceInput.trim() !== "") {
      const updatedCompetences = competencesList.map((competence, index) => {
        if (index === competenceIndex) {
          return {
            ...competence,
            subCompetences: [...competence.subCompetences, subCompetenceInput],
          };
        }
        return competence;
      });
      setCompetencesList(updatedCompetences);
      setSubCompetenceInput("");
    }
  };

  const handleRemoveSubCompetence = (competenceIndex, subCompetenceIndex) => {
    const updatedCompetences = [...competencesList];
    updatedCompetences[competenceIndex].subCompetences.splice(
      subCompetenceIndex,
      1
    );
    setCompetencesList(updatedCompetences);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const referentielData = {
        Niveau: niveauId,
        Description: description,
        Name: nomRef,
      };
      const referentielResponse = await authService.makeAuthenticatedRequest(
        "https://api.starskills.fr/items/Referentiel",
        "POST",
        referentielData
      );
      const referentielId = referentielResponse.data.id;

      for (const competence of competencesList) {
        const skillData = {
          Referentiel: referentielId,
          Name: competence.skillsName,
        };

        const skillResponse = await authService.makeAuthenticatedRequest(
          "https://api.starskills.fr/items/Skill",
          "POST",
          skillData
        );
        const skillId = skillResponse.data.id;

        for (const subCompetence of competence.subCompetences) {
          const subSkillData = {
            Name: subCompetence,
            Skill: skillId,
          };
          await authService.makeAuthenticatedRequest(
            "https://api.starskills.fr/items/SubSkill",
            "POST",
            subSkillData
          );
        }
      }
      setSkillsName("");
      setDescription("");
      setCompetencesList([]);
      handleCloseModal();
      window.location.reload();
    } catch (error) {
      console.error(
        "Error creating referentiel, skills, and sub-skills:",
        error
      );
    }
  };

  return (
    <Dialog
      open={showModal}
      handler={handleCloseModal}
      style={{ width: "90%", maxWidth: "700px" }}
    >
      <DialogHeader>Ajouter un Référentiel</DialogHeader>
      <DialogBody
        className="overflow-y-auto "
        style={{
          height: "450px",
          maxHeight: "70vh",
          overflowY: "auto",
        }}
      >
        {currentStep === 1 && (
          <>
            <div>
              <div>
                <div className="mb-4">
                  <label
                    htmlFor="nomRef"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom de Référentiel
                  </label>
                  <input
                    type="text"
                    id="nomRef"
                    name="nomRef"
                    className={`mt-1 block w-full border ${
                      isValid ? "border-gray-300" : "border-red-500"
                    } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                    value={nomRef}
                    onChange={(e) => setNomRef(e.target.value)}
                  />

                  {!isValid && nomRef.trim() === "" && (
                    <p className="text-red-500 text-xs mt-1">
                      Veuillez saisir le Nom de Référentiel.
                    </p>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Description
                  </label>
                  <textarea
                    id="description"
                    name="description"
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <div>
              <h3 className="text-lg  mb-4">
                Les compétences Référentiel:
                <span className="text-indigo-600 "> {nomRef}</span>
              </h3>
              <div>
                <div className="mb-4">
                  <label
                    htmlFor="competence"
                    className="block text-sm font-bold"
                  >
                    Ajouter une Nouvelle Compétence
                  </label>
                  <div className="flex items-center">
                    <input
                      type="text"
                      id="competence"
                      placeholder="Nom de Compétence"
                      name="competence"
                      className={`mt-1 block w-full border ${
                        isValid ? "border-gray-300" : "border-red-500"
                      } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                      value={competenceInput}
                      onChange={(e) => setCompetenceInput(e.target.value)}
                    />
                    <button
                      className={`ml-2 bg-green-500 hover:bg-green-500 text-white font-bold py-1 px-2 rounded ${
                        competenceInput.trim() === "" ? "border-red-500" : ""
                      }`}
                      onClick={handleAddCompetence}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                  {!isValid && competenceInput.trim() === "" && (
                    <p className="text-red-500 text-xs mt-1">
                      Veuillez saisir le nom de la compétence.
                    </p>
                  )}
                  {competencesList.map((competence, index) => (
                    <div className="mt-4 p-4 bg-gray-200" key={index}>
                      <div className="flex justify-between items-center">
                        <h4 className="text-base text-orange-700 font-semibold mb-2">
                          Compétence {index + 1}
                        </h4>
                        <button
                          className="ml-2 bg-red-500 hover:bg-red-700 text-white  py-1 px-2 rounded"
                          onClick={() => {
                            const updatedCompetences = [...competencesList];
                            updatedCompetences.splice(index, 1);
                            setCompetencesList(updatedCompetences);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                      <div className="mb-2">
                        <label
                          htmlFor={`competence-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nom de Compétence
                        </label>
                        <div className="flex items-center">
                          <input
                            type="text"
                            id={`competence-${index}`}
                            name={`competence-${index}`}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={competence.skillsName}
                            onChange={(e) => {
                              const updatedCompetences = [...competencesList];
                              updatedCompetences[index].skillsName =
                                e.target.value;
                              setCompetencesList(updatedCompetences);
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <label
                          htmlFor={`sub-competence-${index}`}
                          className="block text-sm font-medium text-gray-700"
                        >
                          Sous-Compétences
                        </label>
                        <div className="flex items-center">
                          <input
                            type="text"
                            id={`sub-competence-${competencesList.length}`}
                            name={`sub-competence-${competencesList.length}`}
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            value={subCompetenceInput}
                            onChange={(e) =>
                              setSubCompetenceInput(e.target.value)
                            }
                          />

                          <button
                            className="ml-2 bg-orange-700 hover:text-orange-500 text-white font-bold py-1 px-2 rounded"
                            onClick={() => handleAddSubCompetence(index)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                        <div className="flex flex-wrap">
                          {competence.subCompetences.map(
                            (subCompetence, subIndex) => (
                              <div
                                key={`${index}-${subIndex}`}
                                className="bg-white rounded-md px-2 py-1 m-2"
                              >
                                <div className="flex items-center">
                                  <span>{subCompetence}</span>
                                  <button
                                    className="ml-2 text-red-500 font-bold"
                                    onClick={() =>
                                      handleRemoveSubCompetence(index, subIndex)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} />
                                  </button>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
      </DialogBody>
      <DialogFooter>
        {currentStep === 1 && (
          <>
            <Button color="red" className="mx-2" onClick={closeModal}>
              Annuler
            </Button>
            <Button onClick={handleNextStep}>Suivant</Button>
          </>
        )}
        {currentStep === 2 && (
          <>
            <Button color="red" onClick={handlePrevStep} className="mr-2">
              Précédent
            </Button>
            <Button color="green" onClick={handleSubmit}>
              Valider
            </Button>
          </>
        )}
      </DialogFooter>
    </Dialog>
  );
};

export default AddRefModal;
