import React, { useState } from "react";
import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";

const QuizBox = ({ questionList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questionList.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Handle the end of the quiz
      setIsOpen(false);
      // Reset for next time the quiz is opened
      setCurrentQuestionIndex(0);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const currentQuestion = questionList[currentQuestionIndex];

  return (
    <div>
      <Button
        onClick={handleOpen}
        color="lightBlue"
        buttonType="filled"
        size="lg"
        block={true}
        ripple="light"
        className="mb-4"
      >
        Start Quiz
      </Button>

      <Dialog open={isOpen} onClose={handleClose}>
        <DialogHeader>Quiz</DialogHeader>
        <DialogBody>
          {questionList.length === 0 ? (
            <p className="text-gray-700">No questions available.</p>
          ) : (
            <div>
              <p className="text-lg font-semibold mb-4">{currentQuestion.question}</p>
              <ul>
                {currentQuestion.options.map((option, index) => (
                  <li key={index} className="mb-2">
                    <Button
                      onClick={() => {/* Handle answer selection here */}}
                      color="white"  // Changed to white for text color
                      buttonType="filled"
                      size="lg"
                      ripple="light"
                      className="w-full text-left"
                      style={{ backgroundColor: index === currentQuestion.correctAnswerIndex ? 'green' : 'white' }}
                    >
                      {option}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            onClick={handlePreviousQuestion}
            color="gray"
            buttonType="filled"
            size="lg"
            ripple="light"
            className="mr-2"
            disabled={currentQuestionIndex === 0}
          >
            Previous
          </Button>
          <Button
            onClick={handleClose}
            color="red"
            buttonType="filled"
            size="lg"
            ripple="light"
            className="mr-2"
          >
            Close
          </Button>
          {currentQuestionIndex < questionList.length - 1 && (
            <Button
              onClick={handleNextQuestion}
              color="green"
              buttonType="filled"
              size="lg"
              ripple="light"
            >
              Next Question
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default QuizBox;
