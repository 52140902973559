// src/data/sections.js
import image1 from "../../../assets/images/eleves.png";
import image2 from "../../../assets/images/university.jpg";
import image3 from "../../../assets/images/ecol-eng.jpg";
import image4 from "../../../assets/images/ecole-com.jpg";
import image5 from "../../../assets/images/centre-form.jpg";
import image6 from "../../../assets/images/collective.jpg";

const sections = [
  {
    id: 1,
    header: "Écoles, collèges, lycées",
    text: "Accompagnez chaque élève dans son parcours de développement des compétences. Transformez l'apprentissage avec des évaluations personnalisées et engageantes.",
    image: image1,
  },
  {
    id: 2,
    header: "Universités, iut, ..",
    text: "Utilisez nos outils avancés pour identifier les forces et les domaines à améliorer, tout en fournissant un accompagnement personnalisé",
    image: image2,
  },
  {
    id: 3,
    header: "Écoles d’ingénieurs",
    text: "Offrez un accompagnement personnalisé pour développer les soft skills et les compétences analytiques indispensables à leurs futures carrières",
    image: image3,
  },
  {
    id: 4,
    header: "Écoles de commerce",
    text: "Aidez vos étudiants à se démarquer dans le monde des affaires avec des évaluations précises et engageantes",
    image: image4,
  },
  {
    id: 5,
    header: "Centre de formation",
    text: "Développer les compétences professionnelles et augmenter les chances de succès. Évaluer les acquis, bénéficier de conseils personnalisés, et prépare efficacement les apprenants pour le monde du travail",
    image: image5,
  },
  {
    id: 6,
    header: "Entreprises, collectivités...",
    text: "Maximisez la performance de vos équipes avec un suivi personnalisé des compétences. Améliorez l'engagement et la productivité de vos salariés avec une solution avancée d’accompagnement à la montée en compétences",
    image: image6,
  },
];

export default sections;
