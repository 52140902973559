import React, { useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";

const AddSkillDialog = ({
  open,
  handleClose,
  newSkillName,
  setNewSkillName,
  selectedExistingSkill,
  setSelectedExistingSkill,
  refSkills,
}) => {
  const [selectedSubSkills, setSelectedSubSkills] = useState([]);
  const [newSubSkill, setNewSubSkill] = useState("");

  const handleToggleSubSkill = (subSkillName) => {
    const isSelected = selectedSubSkills.includes(subSkillName);
    if (isSelected) {
      setSelectedSubSkills(
        selectedSubSkills.filter((skill) => skill !== subSkillName)
      );
    } else {
      setSelectedSubSkills([...selectedSubSkills, subSkillName]);
    }
  };

  // Function to handle adding sub-skills
  const handleAddSubSkill = () => console.log("Subskill added:", newSubSkill);
  {
    if (newSubSkill.trim() !== "") {
      setSelectedSubSkills((prevSubSkills) => [
        ...prevSubSkills,
        newSubSkill.trim(),
      ]);
      setNewSubSkill("");
    }
  }

  // Function to handle submitting add skill
  const handleAddSkill = () => {
    console.log("New skill name:", newSkillName);
    console.log("Selected existing skill:", selectedExistingSkill);
    console.log("Selected sub-skills:", selectedSubSkills);
    setNewSkillName("");
    setSelectedExistingSkill("");
    setSelectedSubSkills([]);
    handleClose();
  };

  return (
    <Dialog
      size="xs"
      open={open}
      handler={handleClose}
      className="bg-white shadow-none"
    >
      <DialogHeader>
        <p className="text-lg font-bold">Ajouter block compétences </p>
      </DialogHeader>
      <DialogBody>
        <div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Nouvelle Compétence
            </label>
            <input
              type="text"
              value={newSkillName}
              onChange={(e) => setNewSkillName(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          {/* <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Compétence Existante
            </label>
            <select
              value={selectedExistingSkill}
              onChange={(e) => setSelectedExistingSkill(e.target.value)}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Sélectionner Compétence</option>
              {refSkills.map((skill) => (
                <option key={skill.id} value={skill.Name}>
                  {skill.Name}
                </option>
              ))}
            </select>
          </div> */}
          {selectedExistingSkill && (
            <div>
              <h3 className="text-lg font-medium text-gray-700">
                Liste des sous-compétences
              </h3>
              <ul className="mt-2">
                {refSkills
                  .find((skill) => skill.Name === selectedExistingSkill)
                  ?.SubSkills.map((subSkill) => (
                    <li key={subSkill.id}>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          className="mr-2"
                          checked={selectedSubSkills.includes(subSkill.Name)}
                          onChange={() => handleToggleSubSkill(subSkill.Name)}
                        />
                        {subSkill.Name}
                      </label>
                    </li>
                  ))}
              </ul>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Nouvelle Sous-Compétence
                </label>
                <input
                  type="text"
                  value={newSubSkill}
                  onChange={(e) => setNewSubSkill(e.target.value)}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <Button className="mt-2" onClick={handleAddSubSkill}>
                  Ajouter Sous-Compétence
                </Button>
              </div>
            </div>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" className="mx-2" onClick={handleClose}>
          Annuler
        </Button>
        <Button color="green" onClick={handleAddSkill}>
          Ajouter
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AddSkillDialog;
