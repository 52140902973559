import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../../../../context/AuthContext";
import "./prof.css";

export default function Profile() {
  const { auth } = useContext(AuthContext);
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [activeTab, setActiveTab] = useState("profile");

  useEffect(() => {
    if (auth) {
      setUserData({
        firstName: auth.first_name,
        lastName: auth.last_name,
        email: auth.email,
        phoneNumber: auth.phone,
      });
    }
  }, [auth]);

  const handleEdit = () => {
    setEditMode(!editMode);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  return (
    <div>
      <div className="mt-6">
        <Profilecontent
          userData={userData}
          editMode={editMode}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleEdit={handleEdit}
        />
      </div>
    </div>
  );
}

function Profilecontent({
  userData,
  editMode,
  handleChange,
  handleSubmit,
  handleEdit,
}) {
  return (
    <div className="max-w-xl mx-auto p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-orange-700">Mon Profil</h1>
        {!editMode && (
          <div className="flex justify-start mb-4">
            <button
              type="button"
              onClick={handleEdit}
              className="px-4 py-2 bg-orange-700 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Modifier info
            </button>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="firstName"
            className="block text-gray-700 font-medium mb-1"
          >
            Prénom
          </label>
          <input
            type="text"
            id="firstName"
            placeholder="Prénom"
            name="firstName"
            value={userData.firstName}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="lastName"
            className="block text-gray-700 font-medium mb-1"
          >
            Nom de Famille
          </label>
          <input
            type="text"
            id="lastName"
            placeholder="Nom de Famille"
            name="lastName"
            value={userData.lastName}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-700 font-medium mb-1"
          >
            Adresse Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            name="email"
            value={userData.email}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="phoneNumber"
            className="block text-gray-700 font-medium mb-1"
          >
            Numéro de Téléphone
          </label>
          <input
            type="text"
            id="phoneNumber"
            placeholder="Numéro de Téléphone"
            name="phoneNumber"
            value={userData.phoneNumber}
            onChange={handleChange}
            readOnly={!editMode}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="flex justify-end">
          {editMode && (
            <button
              type="submit"
              className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
            >
              Enregistrer
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
