import React, { useState } from "react";
import { useSession } from "../../../../../context/SessionContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Rapports_main = () => {
  const { sessionDetails } = useSession();
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSessions = sessionDetails
    .filter((session) =>
      session.Title.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => new Date(b.Created_Date) - new Date(a.Created_Date));

  return (
    <div>
      <h1 className="text-xl text-purple-600 font-bold mb-4">
        Mes Rapports par Sessions
      </h1>

      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Rechercher par titre..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 pl-10 border border-gray-300 shadow-md rounded-md focus:outline-none focus:border-blue-500"
        />
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
        </div>
      </div>

      {filteredSessions.length === 0 && (
        <p className="text-red-500 my-4">Aucune session trouvée.</p>
      )}
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-md shadow-md">
          <thead className="bg-purple-100 border-b border-gray-200">
            <tr>
              <th className="py-3 px-4 text-left ">Titre</th>
              <th className="py-3 px-4 text-left ">Créé</th>
              <th className="py-3 px-4 text-left ">Début</th>
              <th className="py-3 px-4 text-left ">Fin</th>
              <th className="py-3 px-4 text-left ">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredSessions.map((session) => (
              <tr
                key={session.id}
                className="border-b border-gray-200  text-purple-300 hover:bg-gray-50"
              >
                <td className="py-4 px-4">
                  <Link to={`/teacher_dashboard/rapport_view/${session.id}`}>
                    {session.Title || "N/A"}
                  </Link>
                </td>
                <td className="py-4 px-4 text-purple-300">
                  {session.Created_Date
                    ? new Date(session.Created_Date).toLocaleDateString("fr-FR")
                    : "N/A"}
                </td>
                <td className="py-4 px-4 ">
                  {session.Start_Date
                    ? new Date(session.Start_Date).toLocaleDateString("fr-FR")
                    : "N/A"}
                </td>
                <td className="py-4 px-4 ">
                  {session.End_Date
                    ? new Date(session.End_Date).toLocaleDateString("fr-FR")
                    : "N/A"}
                </td>

                <td className="py-4 px-4">
                  <Link
                    to={`/teacher_dashboard/rapport_view/${session.id}`}
                    className="text-purple-600 text-sm font-semibold hover:text-blue-800"
                  >
                    Voir Rapports
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Rapports_main;
