import React from "react";
import { useLocation, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./requireAuth.css";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const isRoleAllowed = allowedRoles.some((role) => auth?.role?.includes(role));

  if (isRoleAllowed) {
    // User has one of the allowed roles, allow access to the route
    return <Outlet />;
  } else if (auth?.user) {
    // User is logged in but doesn't have the allowed role
    navigate("/signIn", { state: { from: location } });
    return null;
  } else {
    // User is not logged in, redirect to the sign-in page
    navigate("/signIn", { state: { from: location } });
    return null;
  }
};

export default RequireAuth;
